// src/components/WeeklySchedule.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Text,
  VStack,
  HStack,
  useDisclosure,
  Select,
  Badge,
  useToast,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Textarea,
  Button,
  IconButton,
  ModalFooter,
  Input,
  InputGroup,
  InputLeftElement
} from '@chakra-ui/react';
import supabase from '../supabaseClient';
import { EditIcon, SearchIcon } from '@chakra-ui/icons';

const WeeklySchedule = ({ LoadingComponent }) => {
  const [therapists, setTherapists] = useState([]);
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [schedule, setSchedule] = useState({});
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [sessionType, setSessionType] = useState('');
  const [sessionNotes, setSessionNotes] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const toast = useToast();

  // Define the correct order of days
  const DAYS_ORDER = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];

  // Get working days in correct order
  const getOrderedWorkingDays = (scheduleObj) => {
    return DAYS_ORDER.filter(day => scheduleObj.hasOwnProperty(day));
  };

  // Sort times properly
  const sortTimes = (times) => {
    return times.sort((a, b) => {
      const hourA = parseInt(a.split(':')[0]);
      const hourB = parseInt(b.split(':')[0]);
      return hourA - hourB;
    });
  };

  // Add this new function to filter and sort clients
  const getFilteredClients = () => {
    return clients
      .filter(client => 
        client.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        client.diagnosis.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  };

  const getAllPossibleTimes = (scheduleObj) => {
    // Get all unique times across all days
    const allTimes = new Set();
    Object.values(scheduleObj).forEach(times => {
      times.forEach(time => allTimes.add(time));
    });
    
    // Return sorted array of only the times that are actually used
    return sortTimes([...allTimes]);
  };

  useEffect(() => {
    fetchTherapists();
    fetchClients(); 
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (selectedTherapist) {
      fetchTherapistDetails();
      fetchSessions();
    }
  }, [selectedTherapist]);

  const fetchTherapists = async () => {
    try {
      const { data, error } = await supabase
        .from('therapists')
        .select('*')
        .eq('status', 'active');

      if (error) throw error;
      setTherapists(data);
      if (data.length > 0) {
        setSelectedTherapist(data[0].id);
        setSchedule(data[0].schedule || {});
      }
    } catch (error) {
      console.error('Error fetching therapists:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch therapists',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchTherapistDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('therapists')
        .select('schedule')
        .eq('id', selectedTherapist)
        .single();

      if (error) throw error;
      setSchedule(data.schedule || {});
    } catch (error) {
      console.error('Error fetching therapist details:', error);
    }
  };

  // Add this new helper function at the beginning of your component:
  const getDyadSessionsForSlot = (day, time) => {
    return sessions.filter(session => 
        session.day === day && 
        session.time === time
    );
  };

  const fetchSessions = async () => {
    try {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowStr = tomorrow.toISOString().split('T')[0];
      
      const { data, error } = await supabase
        .from('sessions')
        .select('*')
        .eq('therapist_id', selectedTherapist)
        .or(`and(valid_from.gte.${tomorrowStr},valid_until.is.null),and(valid_from.lte.${tomorrowStr},or(valid_until.is.null,valid_until.gt.${tomorrowStr}))`)
        .order('valid_from', { ascending: true });
  
      if (error) throw error;
  
      console.log('Sessions: ', data);
      
      // Modified process to handle multiple sessions per time slot
      const latestSessions = data.reduce((acc, session) => {
        const timeSlotKey = `${session.day}-${session.time}`;
        
        if (!acc[timeSlotKey]) {
          // Initialize array for this time slot
          acc[timeSlotKey] = [];
        }
  
        // Check if we already have a session for this client in this time slot
        const existingClientSession = acc[timeSlotKey].find(
          s => s.client_id === session.client_id
        );
  
        if (existingClientSession) {
          // If we have an existing session for this client, update it if the new one is more recent
          if (new Date(session.valid_from) > new Date(existingClientSession.valid_from)) {
            acc[timeSlotKey] = acc[timeSlotKey].map(s =>
              s.client_id === session.client_id ? session : s
            );
          }
        } else {
          // If no existing session for this client, add it to the array
          acc[timeSlotKey].push(session);
        }
  
        return acc;
      }, {});
  
      console.log('Latest Sessions by Time Slot: ', latestSessions);
  
      // Flatten the sessions arrays into a single array
      const allSessions = Object.values(latestSessions).flat();
      console.log('All Sessions Flattened: ', allSessions);
  
      setSessions(allSessions);
    } catch (error) {
      console.error('Error fetching sessions:', error);
      toast({
        title: 'Error fetching sessions',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formatTime = (time) => {
    const hour = parseInt(time.split(':')[0]);
    const h = hour % 12 || 12;
    const ampm = hour < 12 ? 'AM' : 'PM';
    return `${h}:00 ${ampm}`;
  };

  const getSessionForSlot = (day, timeSlot) => {
    return sessions.find(session => {
      return session.day === day && session.time === timeSlot;
    });
  };

  const renderSession = (session) => {
    if (!session) return null;
    
    return (
      <Box
        p={2}
        bg={session.session_type === 'Regular' ? 'blue.100' : 'green.100'}
        borderRadius="md"
        width="100%"
        _hover={{ opacity: 0.8 }}
      >
        <Text fontSize="sm" fontWeight="bold">{session.client_name}</Text>
        <Badge size="sm">{session.session_type}</Badge>
        <Text fontSize="xs">{session.notes}</Text>
      </Box>
    );
  };

  const handleTherapistChange = (e) => {
    const therapistId = e.target.value;
    setSelectedTherapist(therapistId);
    const therapist = therapists.find(t => t.id === therapistId);
    setSchedule(therapist?.schedule || {});
  };

  const handleClientAssignment = async (clientId) => {
    try {
      // First, check if the slot is already occupied
      const existingSession = sessions.find(
        session => session.day === selectedSlot.day && 
                   session.time === selectedSlot.time
      );
  
      if (existingSession) {
        // If slot is occupied, ask for confirmation to replace
        const confirmReplace = window.confirm('This slot is already occupied. Replace the existing session?');
        if (!confirmReplace) return;
  
        // Delete existing session
        await supabase
          .from('sessions')
          .delete()
          .eq('id', existingSession.id);
      }
  
      // Get client details
      const { data: clientData, error: clientError } = await supabase
        .from('clients')
        .select('name')
        .eq('id', clientId)
        .single();
  
      if (clientError) throw clientError;
  
      // Insert new session
      const { error } = await supabase
        .from('sessions')
        .insert([{
          therapist_id: selectedTherapist,
          client_id: clientId,
          client_name: clientData.name,
          day: selectedSlot.day,
          time: selectedSlot.time,
          session_type: 'Therapy' // You might want to make this configurable
        }]);
  
      if (error) throw error;
  
      // Refresh sessions
      await fetchSessions();
  
      // Close modal
      onClose();
  
      toast({
        title: 'Session Assigned',
        description: `Assigned to ${clientData.name}`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error assigning client:', error);
      toast({
        title: 'Error',
        description: 'Failed to assign client',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleClientSelection = (client) => {
    setSelectedClient(client);
    // Reset previous session details
    setSessionType('');
    setSessionNotes('');
  };

  const handleEditSession = (session) => {
    // Set the selected slot based on the existing session
    setSelectedSlot({ day: session.day, time: session.time });
    
    // Pre-fill client details
    const clientToSelect = {
      id: session.client_id,
      name: session.client_name
    };
    setSelectedClient(clientToSelect);
    
    // Pre-fill session details
    setSessionType(session.session_type);
    setSessionNotes(session.notes || '');
    
    // Open the modal
    onOpen();
  };
  
  const handleDeleteSession = async () => {
    try {
      const currentDate = new Date();
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowStr = tomorrow.toISOString().split('T')[0];
      
      // Find the existing session
      const existingSession = sessions.find(
        session => session.day === selectedSlot.day && 
                   session.time === selectedSlot.time
      );
  
      if (!existingSession) {
        toast({
          title: 'Error',
          description: 'No session found to delete',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
  
      // Confirm deletion
      const confirmDelete = window.confirm(
        'Do you want to remove this session from the schedule? Historical attendance records will be preserved.'
      );
      if (!confirmDelete) return;
  
      // Set valid_until to today's date
      const { error } = await supabase
        .from('sessions')
        .update({ valid_until: currentDate.toISOString().split('T')[0] })
        .eq('id', existingSession.id);
  
      if (error) throw error;
  
      // Refresh sessions
      await fetchSessions();
      onClose();
      setSelectedClient(null);
      setSessionType('');
      setSessionNotes('');
  
      toast({
        title: 'Session Removed',
        description: 'The session has been removed from future schedules',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating session:', error);
      toast({
        title: 'Error',
        description: 'Failed to remove session',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const formatMorningTime = (timeStr) => {
    const [hours, minutes] = timeStr.split(':');
    const hourNum = parseInt(hours);
    
    // Check if time is between 01:00 and 09:00
    if (hourNum >= 1 && hourNum <= 9) {
      return `${hourNum}:${minutes}`; // This will remove leading zero
    }
    
    return timeStr;
  };
  
  const handleSessionAssignment = async () => {
    try {
      if (!selectedClient || !sessionType) {
        toast({
          title: 'Incomplete Information',
          description: 'Please select a session type',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
  
      const currentDate = new Date();
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowStr = tomorrow.toISOString().split('T')[0];

      // Format the time before checking for existing session
      const formattedTime = formatMorningTime(selectedSlot.time);
      
      // Check for existing session
      const existingSession = sessions.find(
        session => session.day === selectedSlot.day && 
                   session.time === formattedTime
      );
  
      if (existingSession) {
        const confirmReplace = window.confirm(
          'This will change the schedule going forward. Historical records will be preserved. Continue?'
        );
        if (!confirmReplace) return;
  
        // End the current session's validity
        const { error: updateError } = await supabase
          .from('sessions')
          .update({ valid_until: currentDate.toISOString().split('T')[0] })
          .eq('id', existingSession.id);
  
        if (updateError) throw updateError;
      }
  
      // Insert new session with validity starting tomorrow
      const { error: insertError } = await supabase
        .from('sessions')
        .insert([{
          therapist_id: selectedTherapist,
          client_id: selectedClient.id,
          client_name: selectedClient.name,
          day: selectedSlot.day,
          time: formattedTime,
          session_type: sessionType,
          notes: sessionNotes,
          valid_from: tomorrowStr,
          valid_until: null
        }]);
  
      if (insertError) throw insertError;
  
      await fetchSessions();
      onClose();
      setSelectedClient(null);
      setSessionType('');
      setSessionNotes('');
  
      toast({
        title: 'Schedule Updated',
        description: `Schedule updated for ${selectedClient.name} starting tomorrow`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating schedule:', error);
      toast({
        title: 'Error',
        description: 'Failed to update schedule',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  

  // Time range handling functions
  const getNextHour = (timeString) => {
    const hour = parseInt(timeString.split(':')[0]);
    return `${hour + 1}:00`;
  };
  
  const getTimeRanges = (times) => {
    const sortedTimes = sortTimes([...times]);
    const ranges = [];
    let currentRange = {
      start: sortedTimes[0],
      end: null
    };
  
    for (let i = 1; i < sortedTimes.length; i++) {
      const currentHour = parseInt(sortedTimes[i].split(':')[0]);
      const prevHour = parseInt(sortedTimes[i-1].split(':')[0]);
      
      if (currentHour - prevHour > 1) {
        // There's a gap, end the current range and start a new one
        currentRange.end = getNextHour(sortedTimes[i-1]);
        ranges.push({ ...currentRange });
        currentRange = {
          start: sortedTimes[i],
          end: null
        };
      }
    }
    
    // Add the last range with proper end time
    currentRange.end = getNextHour(sortedTimes[sortedTimes.length - 1]);
    ranges.push(currentRange);
    
    return ranges;
  };
  
  const renderScheduleSummary = () => {
    const orderedDays = getOrderedWorkingDays(schedule);
    return orderedDays.map(day => {
      const ranges = getTimeRanges(schedule[day]);
      const formattedRanges = ranges.map(range => 
        `${formatTime(range.start)} to ${formatTime(range.end)}`
      );
  
      return (
        <HStack key={day} fontSize="sm" color="gray.600" align="flex-start">
          <Text fontWeight="semibold" minWidth="80px">{day}:</Text>
          <Text>{formattedRanges.join(' & ')}</Text>
        </HStack>
      );
    });
  };

    // Add this utility function at the top of your component
  const getSpecializationAbbreviation = (specialization) => {
    if (!specialization) return '';
    return specialization
        .split(' ')
        .map(word => word[0])
        .join('')
        .toUpperCase();
  };
  
  const fetchClients = async () => {
    try {
      const { data, error } = await supabase
        .from('clients')
        .select('*')
        .eq('status', 'Active');
  
      if (error) throw error;
      setClients(data);
    } catch (error) {
      console.error('Error fetching clients:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch clients',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSlotClick = (day, time) => {
    setSelectedSlot({ day, time });
    setSelectedClient(null); // Reset selected client when opening modal
    onOpen();
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <Box overflowX="auto" overflowY="auto" maxHeight="80vh">
      <Box mb={4} bg="white" zIndex={1} p={2}>
        <HStack align="stretch" justify="space-between" spacing={3}>
          <Select
            width="250px"
            value={selectedTherapist || ''}
            onChange={handleTherapistChange}
          >
            {therapists.map(therapist => (
            <option key={therapist.id} value={therapist.id}>
                {therapist.name} ({getSpecializationAbbreviation(therapist.specialization)})
            </option>
            ))}
          </Select>
          <Box>
            <HStack align="baseline">
                <Text fontSize="sm" fontWeight="bold" mb={2}>Working Schedule:</Text>
                <VStack align="stretch" spacing={1}>
                {renderScheduleSummary()}
                </VStack>
            </HStack>
          </Box>
        </HStack>
      </Box>

      {Object.keys(schedule).length > 0 ? (
        <Grid 
            templateColumns={`auto repeat(${getOrderedWorkingDays(schedule).length}, 1fr)`}
            gap={1} 
            bg="gray.50" 
            p={4} 
            borderRadius="md" 
            minWidth={`${200 + (getOrderedWorkingDays(schedule).length * 200)}px`}
        >
            {/* Time slots column */}
            <VStack align="stretch" spacing={1}>
            <Box height="40px" />
            {getAllPossibleTimes(schedule).map(time => (
                <Box key={time} height="70px" p={2}>
                <Text fontSize="sm" color="gray.600">{formatTime(time)}</Text>
                </Box>
            ))}
            </VStack>

            {/* Days columns */}
            {getOrderedWorkingDays(schedule).map(day => (
            <VStack key={day} align="stretch" spacing={1}>
                <Box p={2} bg="blue.500" color="white" borderRadius="md">
                <Text fontWeight="bold" textAlign="center">{day}</Text>
                </Box>
                {getAllPossibleTimes(schedule).map(time => {
                    const session = getDyadSessionsForSlot(day, time);
                    const hasTimeSlot = schedule[day]?.includes(time);
                    
                    return (
                        <Box
                        key={`${day}-${time}`}
                        height="70px"
                        p={1}
                        bg={hasTimeSlot ? "white" : "gray.100"}
                        borderRadius="md"
                        border="1px"
                        borderColor={hasTimeSlot ? "gray.200" : "transparent"}
                        position="relative"
                        _hover={{ bg: hasTimeSlot ? 'gray.50' : 'gray.100', cursor: hasTimeSlot ? 'pointer' : 'default' }}
                        onClick={() => hasTimeSlot && handleSlotClick(day, time)}
                        >
                        {session.length > 0 && (
                            <HStack spacing={1} height="100%" width="100%">
                            {session.map((sess, index) => (
                                <Box
                                key={sess.id}
                                position="relative"
                                flex="1"
                                height="100%"
                                >
                                <IconButton
                                    icon={<EditIcon />}
                                    size="xs"
                                    position="absolute"
                                    top={1}
                                    right={1}
                                    zIndex={1}
                                    onClick={(e) => {
                                    e.stopPropagation();
                                    handleEditSession(sess);
                                    }}
                                    aria-label="Edit session"
                                />
                                <Box
                                    p={2}
                                    bg={sess.session_type === 'Regular' ? 'blue.100' : 'green.100'}
                                    borderRadius="md"
                                    width="100%"
                                    height="100%"
                                >
                                    <Text fontSize="sm" fontWeight="bold" noOfLines={1}>
                                    {sess.client_name}
                                    </Text>
                                    <Badge size="sm">
                                    {session.length > 1 ? 'Dyad' : sess.session_type}
                                    </Badge>
                                    {sess.notes && (
                                    <Text fontSize="xs" noOfLines={1}>
                                        {sess.notes}
                                    </Text>
                                    )}
                                </Box>
                                </Box>
                            ))}
                            </HStack>
                        )}
                        </Box>
                    );
                })}
            </VStack>
            ))}
        </Grid>
        ) : (
        <Box p={4} textAlign="center" color="gray.500">
            No schedule set for this therapist
        </Box>
      )}

        <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
            {!selectedClient ? (
            <>
                <ModalHeader>Assign Client</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <VStack spacing={4} align="stretch">
                  <InputGroup>
                    <InputLeftElement pointerEvents="none">
                      <SearchIcon color="gray.400" />
                    </InputLeftElement>
                    <Input
                      placeholder="Search clients by name or diagnosis..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      mb={4}
                    />
                  </InputGroup>
                  <Box overflowY="auto" maxHeight="400px">
                    {getFilteredClients().map(client => (
                      <Box 
                        key={client.id}
                        p={3}
                        borderWidth={1}
                        borderRadius="md"
                        cursor="pointer"
                        _hover={{ bg: 'gray.100' }}
                        onClick={() => handleClientSelection(client)}
                        mb={2}
                      >
                        <HStack justify="space-between">
                          <Text fontWeight="bold">{client.name}</Text>
                          <Badge>
                            {client.age}YO {client.gender}
                          </Badge>
                        </HStack>
                        <Text fontSize="sm" color="gray.600">
                          {client.diagnosis}
                        </Text>
                      </Box>
                    ))}
                  </Box>
                </VStack>
              </ModalBody>
            </>
          ) : (
            <>
              <ModalHeader>
                Session Details for {selectedClient.name}
                {selectedSlot && ` - ${selectedSlot.day} at ${formatTime(selectedSlot.time)}`}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <VStack spacing={4} align="stretch">
                  <FormControl isRequired>
                    <FormLabel>Session Type</FormLabel>
                    <Select
                      value={sessionType}
                      onChange={(e) => setSessionType(e.target.value)}
                      placeholder="Select session type"
                    >
                      <option value="IE">Initial Evaluation</option>
                      <option value="Endorsement">Endorsement</option>
                      <option value="Regular">Regular Session</option>
                    </Select>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Session Notes</FormLabel>
                    <Textarea
                      value={sessionNotes}
                      onChange={(e) => setSessionNotes(e.target.value)}
                      placeholder="Add session notes (optional)"
                    />
                  </FormControl>
                </VStack>
              </ModalBody>
              <ModalFooter>
                <Button 
                  colorScheme="blue" 
                  mr={3} 
                  onClick={handleSessionAssignment}
                >
                  {selectedClient.id ? 'Update Session' : 'Assign Session'}
                </Button>
                <Button 
                  colorScheme="red"
                  variant="outline"
                  mr={3}
                  onClick={handleDeleteSession}
                  isDisabled={!selectedClient.id}
                >
                  Remove Session
                </Button>
                <Button 
                  variant="ghost" 
                  onClick={() => {
                    setSelectedClient(null);
                    setSearchQuery(''); // Clear search when going back
                  }}
                >
                  Back to Clients
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default WeeklySchedule;