// src/components/TherapistFinanceSummary.js
import React, { useState, useEffect } from 'react';
import {
  VStack,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Spinner,
  Center,
  useToast
} from '@chakra-ui/react';
import supabase from '../supabaseClient';

const TherapistFinanceSummary = ({ therapistId, selectedYear }) => {
  const [summaryData, setSummaryData] = useState({
    yearRange: '',
    currentPeriodRange: '',
    paid: { totalSessions: 0, totalEarnings: 0, sessionTypes: {}, isEligibleForBenefits: false, totalHours: 0 },
    unpaid: { totalSessions: 0, totalEarnings: 0, sessionTypes: {}, isEligibleForBenefits: false, totalHours: 0 },
    currentPeriod: { totalSessions: 0, totalEarnings: 0, sessionTypes: {}, isEligibleForBenefits: false, totalHours: 0 }
  });
  const [isLoading, setIsLoading] = useState(true);
  // Add this to the existing state initialization
  const [longevityBonus, setLongevityBonus] = useState({
    currentPercentage: 0,
    accumulatedBonus: 0,
    nextPayoutDate: null,
    isPaid: false
  });  
  const toast = useToast();

  useEffect(() => {
    fetchSummaryData();
  }, [therapistId, selectedYear]);

    // Add these helper functions at the top of TherapistFinanceSummary
    const getAnnualMilestoneInfo = async (therapistId) => {
        try {
            // Get first session date
            const { data: firstSession } = await supabase
                .from('session_attendance')
                .select('attendance_date')
                .eq('therapist_id', therapistId)
                .eq('status', 'present')
                .order('attendance_date', { ascending: true })
                .limit(1)
                .single();

            if (!firstSession) return null;

            const firstSessionDate = new Date(firstSession.attendance_date);
            const today = new Date();

            // Calculate years of service
            const yearsOfService = Math.floor(
                (today - firstSessionDate) / (365.25 * 24 * 60 * 60 * 1000)
            );

            // Calculate current milestone period
            const currentMilestoneStart = new Date(firstSessionDate);
            currentMilestoneStart.setFullYear(currentMilestoneStart.getFullYear() + yearsOfService);
            
            const currentMilestoneEnd = new Date(currentMilestoneStart);
            currentMilestoneEnd.setFullYear(currentMilestoneEnd.getFullYear() + 1);
            currentMilestoneEnd.setDate(currentMilestoneEnd.getDate() - 1);

            // Calculate next payout date
            const nextPayoutDate = new Date(currentMilestoneEnd);
            nextPayoutDate.setDate(15); // Next payout after milestone

            // Get percentage based on years of service
            const getPercentage = (years) => {
                if (years <= 0) return 2;
                if (years === 1) return 2.5;
                if (years === 2) return 3;
                if (years === 3) return 3.5;
                if (years === 4) return 4;
                return 5; // 5% for 5 years and above
            };

            return {
                firstSessionDate,
                yearsOfService,
                currentPercentage: getPercentage(yearsOfService),
                nextPercentage: getPercentage(yearsOfService + 1),
                currentMilestoneStart,
                currentMilestoneEnd,
                nextPayoutDate
            };
        } catch (error) {
            console.error('Error getting milestone info:', error);
            return null;
        }
    };

    const calculateLongevityBonus = async (therapistId, milestoneStart, milestoneEnd, percentage) => {
        try {
            const { data: sessions } = await supabase
                .from('session_attendance')
                .select('amount_paid')
                .eq('therapist_id', therapistId)
                .eq('status', 'present')
                .gte('attendance_date', milestoneStart.toISOString())
                .lte('attendance_date', milestoneEnd.toISOString());

            if (!sessions) return 0;

            const totalRevenue = sessions.reduce((sum, session) => sum + (session.amount_paid || 0), 0);
            return (totalRevenue * percentage) / 100;
        } catch (error) {
            console.error('Error calculating bonus:', error);
            return 0;
        }
    };

  const formatCurrency = (amount) => {
    return `₱${amount.toLocaleString('en-PH', { minimumFractionDigits: 2 })}`;
  };

  const checkBenefitsEligibility = async (therapistId, month, year) => {
    try {
      let startYear = year;
      let startMonth = month - 1;
      
      // Handle December to January transition
      if (startMonth === 0) { // January
        startMonth = 12; // December
        startYear = year - 1;
      }
  
      const startDate = new Date(startYear, startMonth - 1, 27); // Previous month's 27th
      const endDate = new Date(year, month - 1, 26);  // Current month's 26th
  
      const { data: sessions, error } = await supabase
        .from('session_attendance')
        .select('*')
        .eq('therapist_id', therapistId)
        .eq('status', 'present')
        .gte('attendance_date', startDate.toISOString().split('T')[0])
        .lte('attendance_date', endDate.toISOString().split('T')[0]);
  
      if (error) throw error;
  
      const totalHours = sessions?.length || 0;
      return { isEligible: totalHours >= 72, totalHours };
    } catch (error) {
      console.error('Error checking benefits eligibility:', error);
      return { isEligible: false, totalHours: 0 };
    }
  };

  const fetchSummaryData = async () => {
    try {
      setIsLoading(true);
      
      // Adjust year range to be Dec 27 of previous year to Dec 26 of selected year
      const startDate = new Date(selectedYear - 1, 11, 27); // Dec 27 of previous year
      const endDate = new Date(selectedYear, 11, 26);  // Dec 26 of selected year

      const formatToLocalDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      const startDateStr = formatToLocalDate(startDate);
      const endDateStr = formatToLocalDate(endDate);

      // Get valid service fee for this period
      const { data: serviceFee } = await supabase
        .from('service_fees')
        .select('amount')
        .eq('therapist_id', therapistId)
        .lte('valid_from', endDateStr)
        .or(`valid_until.is.null,valid_until.gte.${startDateStr}`)
        .order('valid_from', { ascending: false })
        .limit(1)
        .single();

      // Get all sessions
      const { data: sessions, error } = await supabase
        .from('session_attendance')
        .select(`
          *,
          session:sessions (
            client:clients (
              name
            )
          )
        `)
        .eq('therapist_id', therapistId)
        .eq('status', 'present')
        .gte('attendance_date', startDateStr)
        .lte('attendance_date', endDateStr);

      if (error) throw error;

      // Get paid sessions (those in payroll_payments)
      const { data: paidPeriods } = await supabase
        .from('payroll_payments')
        .select('start_date, end_date')
        .eq('therapist_id', therapistId)
        .gte('start_date', startDateStr)
        .lte('end_date', endDateStr);

      // Get current period range (27th of previous month to 26th of current month)
      const today = new Date();
      const currentPeriodStart = new Date(today.getFullYear(), today.getMonth() - 1, 27);
      const currentPeriodEnd = new Date(today.getFullYear(), today.getMonth(), 26);

      // Initialize summary objects
      const paidSummary = { totalSessions: 0, totalEarnings: 0, sessionTypes: {} };
      const unpaidSummary = { totalSessions: 0, totalEarnings: 0, sessionTypes: {} };
      const currentPeriodSummary = { totalSessions: 0, totalEarnings: 0, sessionTypes: {} };

      // Group sessions by month cycle (27th to 26th)
      const monthCycles = {};
      sessions?.forEach(session => {
        const sessionDate = new Date(session.attendance_date);
        
        // Determine the cycle this session belongs to
        let cycleMonth = sessionDate.getDate() >= 27 ? sessionDate.getMonth() : sessionDate.getMonth() - 1;
        let cycleYear = sessionDate.getFullYear();
        if (cycleMonth < 0) {
          cycleMonth = 11;
          cycleYear--;
        }
        
        const cycleKey = `${cycleYear}-${cycleMonth}`;
        if (!monthCycles[cycleKey]) {
          monthCycles[cycleKey] = [];
        }
        monthCycles[cycleKey].push(session);
      });

      // Process each cycle
      for (const [cycleKey, cycleSessions] of Object.entries(monthCycles)) {
        const [cycleYear, cycleMonth] = cycleKey.split('-').map(Number);
        
        const cycleEligibility = await checkBenefitsEligibility(
          therapistId,
          parseInt(cycleMonth) + 2, // Add 2 because cycleMonth is 0-based and we want the ending month
          cycleYear
        );

        let cyclePaidSessions = 0;
        let cycleUnpaidSessions = 0;
        
        cycleSessions.forEach(session => {
          const sessionDate = session.attendance_date;
          const sessionFee = session.session_type === 'IE' ? 2200 : (serviceFee?.amount || 0);

          const isPaid = paidPeriods?.some(period => 
            sessionDate >= period.start_date && sessionDate <= period.end_date
          );

          const isCurrentPeriod = sessionDate >= formatToLocalDate(currentPeriodStart) && 
                                sessionDate <= formatToLocalDate(currentPeriodEnd);

          if (isPaid) {
            cyclePaidSessions++;
            paidSummary.totalSessions++;
            paidSummary.totalEarnings += sessionFee;
            paidSummary.sessionTypes[session.session_type] = 
              (paidSummary.sessionTypes[session.session_type] || 0) + 1;
          } else {
            cycleUnpaidSessions++;
            unpaidSummary.totalSessions++;
            unpaidSummary.totalEarnings += sessionFee;
            unpaidSummary.sessionTypes[session.session_type] = 
              (unpaidSummary.sessionTypes[session.session_type] || 0) + 1;
          }

          if (isCurrentPeriod) {
            currentPeriodSummary.totalSessions++;
            currentPeriodSummary.totalEarnings += sessionFee;
            currentPeriodSummary.sessionTypes[session.session_type] = 
              (currentPeriodSummary.sessionTypes[session.session_type] || 0) + 1;
          }
        });

        // Add benefits to the appropriate summary if eligible
        if (cycleEligibility.isEligible) {
          if (cyclePaidSessions > cycleUnpaidSessions) {
            paidSummary.totalEarnings += 2500;
            paidSummary.isEligibleForBenefits = true;
            paidSummary.totalHours = cycleEligibility.totalHours;
          } else if (cycleUnpaidSessions > 0) {
            unpaidSummary.totalEarnings += 2500;
            unpaidSummary.isEligibleForBenefits = true;
            unpaidSummary.totalHours = cycleEligibility.totalHours;
          }
        }
      }

      // Handle current period eligibility separately
      const currentPeriodBenefits = await checkBenefitsEligibility(
        therapistId,
        currentPeriodEnd.getMonth() + 1,
        currentPeriodEnd.getFullYear()
      );

      if (currentPeriodBenefits.isEligible) {
        currentPeriodSummary.totalEarnings += 2500;
        currentPeriodSummary.isEligibleForBenefits = true;
        currentPeriodSummary.totalHours = currentPeriodBenefits.totalHours;
      }

        // Get milestone info
        const milestoneInfo = await getAnnualMilestoneInfo(therapistId);
        
        if (milestoneInfo) {
            // Check if current period is paid
            const { data: payrollPayment } = await supabase
                .from('payroll_payments')
                .select('*')
                .eq('therapist_id', therapistId)
                .eq('start_date', milestoneInfo.currentMilestoneStart.toISOString().split('T')[0])
                .eq('end_date', milestoneInfo.currentMilestoneEnd.toISOString().split('T')[0])
                .maybeSingle();

            const bonus = await calculateLongevityBonus(
                therapistId,
                milestoneInfo.currentMilestoneStart,
                new Date(), // Calculate up to current date
                payrollPayment ? milestoneInfo.nextPercentage : milestoneInfo.currentPercentage
            );

            setLongevityBonus({
                currentPercentage: payrollPayment ? milestoneInfo.nextPercentage : milestoneInfo.currentPercentage,
                accumulatedBonus: bonus,
                nextPayoutDate: milestoneInfo.nextPayoutDate,
                isPaid: !!payrollPayment
            });
        }

      setSummaryData({
        yearRange: `${startDate.toLocaleDateString('en-US', { 
          month: 'short', 
          day: 'numeric', 
          year: 'numeric'
        })} - ${endDate.toLocaleDateString('en-US', { 
          month: 'short', 
          day: 'numeric', 
          year: 'numeric'
        })}`,
        currentPeriodRange: `${currentPeriodStart.toLocaleDateString('en-US', { 
          month: 'short', 
          day: 'numeric'
        })} - ${currentPeriodEnd.toLocaleDateString('en-US', { 
          month: 'short', 
          day: 'numeric'
        })}`,
        paid: paidSummary,
        unpaid: unpaidSummary,
        currentPeriod: currentPeriodSummary
      });
    } catch (error) {
      toast({
        title: 'Error fetching summary',
        description: error.message,
        status: 'error',
        duration: 5000
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Center py={8}>
        <Spinner />
      </Center>
    );
  }

  return (
    <VStack spacing={6} align="stretch">
      <Heading size="md">{summaryData.yearRange}</Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
        <Card>
          <CardHeader>
            <Heading size="md">Earnings Summary</Heading>
          </CardHeader>
          <CardBody>
            <StatGroup flexDirection={{ base: 'column', sm: 'row' }} gap={{ base: 4, sm: 0 }}>
              <Stat>
                <StatLabel>Paid Earnings</StatLabel>
                <StatNumber>{formatCurrency(summaryData.paid.totalEarnings)}</StatNumber>
                <Text fontSize="sm" color="gray.500">
                  {summaryData.paid.totalSessions} Sessions
                </Text>
              </Stat>
              <Stat>
                <StatLabel>Unpaid Earnings</StatLabel>
                <StatNumber>{formatCurrency(summaryData.unpaid.totalEarnings)}</StatNumber>
                <Text fontSize="sm" color="gray.500">
                  {summaryData.unpaid.totalSessions} Sessions
                </Text>
                {summaryData.unpaid.isEligibleForBenefits && (
                  <Text fontSize="sm" color="green.500">
                    Benefits Included ({summaryData.unpaid.totalHours} hours)
                  </Text>
                )}
              </Stat>
            </StatGroup>
          </CardBody>
        </Card>
        {selectedYear === new Date().getFullYear() && (
        <Card>
          <CardHeader>
            <Heading size="md">Current Month ({summaryData.currentPeriodRange})</Heading>
          </CardHeader>
          <CardBody>
            <VStack align="stretch" spacing={3}>
              <Text fontWeight="medium">
                Total: {formatCurrency(summaryData.currentPeriod.totalEarnings)}
              </Text>
              {Object.entries(summaryData.currentPeriod.sessionTypes).map(([type, count]) => (
                <Text key={type}>
                  {type}: {count} {type === 'Regular' ? 'Sessions' : ''}
                </Text>
              ))}
              {summaryData.currentPeriod.isEligibleForBenefits && (
                <Text fontSize="sm" color="green.500">
                  Benefits Included ({summaryData.currentPeriod.totalHours} hours)
                </Text>
              )}
            </VStack>
          </CardBody>
        </Card>
        )}
        <Card>
            <CardHeader>
                <Heading size="md">Longevity Bonus</Heading>
            </CardHeader>
            <CardBody>
                <VStack align="start" spacing={3}>
                    <Text>
                        Current Tier: {longevityBonus.currentPercentage}% of Annual Revenue
                    </Text>
                    <Text fontWeight="medium">
                        Accumulated Bonus: {formatCurrency(longevityBonus.accumulatedBonus)}
                    </Text>
                    {longevityBonus.nextPayoutDate && (
                        <Text fontSize="sm" color="gray.500">
                            To be paid on {longevityBonus.nextPayoutDate.toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric'
                            })}
                        </Text>
                    )}
                </VStack>
            </CardBody>
        </Card>
      </SimpleGrid>
    </VStack>
  );
};

export default TherapistFinanceSummary;