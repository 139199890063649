import React, { useState, useEffect } from 'react';
import {
  // ... existing imports
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Card,
  CardHeader,
  CardBody,
  Center,
  Spinner,
  Heading,
  useToast,
  Text,
  Box,
  Select,
  VStack,
  HStack
} from '@chakra-ui/react';
import supabase from '../supabaseClient';

const FinancialSummary = () => {
    const [timeframe, setTimeframe] = useState('monthly'); // monthly, bimonthly, yearly
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [summaryData, setSummaryData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [biMonthlyPeriod, setBiMonthlyPeriod] = useState('first'); // 'first' (13-26) or 'second' (27-12)
    const toast = useToast();
  
    useEffect(() => {
      fetchSummaryData();
    }, [timeframe, selectedYear, selectedMonth, biMonthlyPeriod]);

    // Helper function to get period dates
    const getPeriodDates = (year, month, type, biPeriod = 'first') => {
        let startDate, endDate;

        // Helper to set time to end of day (23:59:59.999)
        // Helper to set time to end of day (23:59:59.999) in local timezone
        const setEndOfDay = (date) => {
            // Create date with the specified year, month, day at 23:59:59.999 local time
            const localDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
            // Adjust for timezone offset
            const offset = localDate.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
            const adjustedDate = new Date(localDate.getTime() - offset);
            return adjustedDate;
        };

        // Helper to set time to start of day (00:00:00.000) in local timezone
        const setStartOfDay = (date) => {
            // Create date with the specified year, month, day at 00:00:00.000 local time
            const localDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
            // Adjust for timezone offset
            const offset = localDate.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
            const adjustedDate = new Date(localDate.getTime() - offset);
            return adjustedDate;
        };

        if (type === 'yearly') {
            // Yearly period starts from Dec 27 of previous year to Dec 26 of selected year
            startDate = setStartOfDay(new Date(year - 1, 11, 27)); // Dec 27 of previous year
            endDate = setEndOfDay(new Date(year, 11, 26)); // Dec 26 of selected year
        } else if (type === 'monthly') {
            // Monthly period starts from 27th of previous month to 26th of selected month
            startDate = setStartOfDay(new Date(year, month - 1, 27)); // 27th of previous month
            endDate = setEndOfDay(new Date(year, month, 26)); // 26th of current month
        } else if (type === 'bimonthly') {
            if (biPeriod === 'first') {
            startDate = setStartOfDay(new Date(year, month, 13));
            endDate = setEndOfDay(new Date(year, month, 26));
            } else {
            startDate = setStartOfDay(new Date(year, month, 27));
            // Handle month/year overflow for end date
            const nextMonth = new Date(year, month + 1, 12);
            endDate = setEndOfDay(nextMonth);
            }
        }

        return { startDate, endDate };
    };

    const getValidServiceFee = async (therapistId, date) => {
      
        try {
          // Then let's check our actual query
          const { data, error } = await supabase
            .from('service_fees')
            .select('*')  // Changed to select all for debugging
            .eq('therapist_id', therapistId)
            .lte('valid_from', date)
            .or('valid_until.is.null,valid_until.gte.' + date); 
      
          if (error) {
            console.error('Query error:', error);
            throw error;
          }
      
          // Get the most recent valid fee
          const validFee = data?.sort((a, b) => 
            new Date(b.valid_from) - new Date(a.valid_from)
          )[0];
      
          return validFee?.amount || 0;
        } catch (error) {
          console.error('Error getting service fee:', error);
          return 0;
        }
    };
  
    const fetchSummaryData = async () => {
        try {
          setIsLoading(true);
          
          let { startDate, endDate } = timeframe === 'lifetime' 
          ? { startDate: null, endDate: null }
          : getPeriodDates(selectedYear, selectedMonth, timeframe, biMonthlyPeriod);
      
          // Build the query
          let query = supabase
            .from('session_attendance')
            .select(`
              *,
              session:sessions (
                client_id,
                session_type,
                therapist:therapists (
                  id,
                  specialization
                )
              ),
              client:clients (
                client_services (
                  service_type,
                  pricing_valid_from,
                  pricing_valid_until
                )
              )
            `)
            .eq('status', 'present');
      
          // Add date filters only if not lifetime
          if (startDate && endDate) {
            query = query
              .gte('attendance_date', startDate.toISOString())
              .lte('attendance_date', endDate.toISOString());
          }
      
          const { data: attendanceData, error } = await query;
      
          if (error) throw error;

          console.log('End Date: ', endDate);



          const calculateNetRevenue = async (attendanceData, startDate, endDate) => {
            // Calculate gross revenue
            const grossRevenue = attendanceData.reduce((sum, record) => sum + (record.amount_paid || 0), 0);
            
            try {
              // Fetch expenses for the period
              let expenseQuery = supabase
                .from('expenses')
                .select('amount');
              
              // Add date filtering if we have a specific period
              if (startDate && endDate) {
                expenseQuery = expenseQuery
                  .gte('purchase_date', startDate.toISOString())
                  .lte('purchase_date', endDate.toISOString());
              }
        
              const { data: expenses, error } = await expenseQuery;

              console.log('EXPENSES: ', expenses);
              
              if (error) {
                console.error('Error fetching expenses:', error);
                throw error;
              }
        
              // Calculate total expenses
              const totalExpenses = expenses.reduce((sum, expense) => sum + (expense.amount || 0), 0);
              
              // Calculate net revenue
              const netRevenue = grossRevenue - totalExpenses;
              
              return netRevenue;
            } catch (error) {
              console.error('Error calculating net revenue:', error);
              toast({
                title: 'Error calculating net revenue',
                description: error.message,
                status: 'error',
                duration: 5000
              });
              return 0;
            }
          };
        
          const netRevenue = await calculateNetRevenue(attendanceData, startDate, endDate);

          // Calculate gross revenue with invoice
          const grossRevenue = attendanceData.reduce((sum, record) => sum + (record.amount_paid || 0), 0);
          const grossRevenueWithInvoice = attendanceData
            .filter(record => record.or_number)
            .reduce((sum, record) => sum + (record.amount_paid || 0), 0);

          // Fetch all expenses for the period
          const { data: expenses, error: expensesError } = await supabase
            .from('expenses')
            .select('*')
            .gte('purchase_date', startDate?.toISOString() || '')
            .lte('purchase_date', endDate?.toISOString() || '');

          if (expensesError) throw expensesError;

            // Add these logs before the expense query
            console.log('Start:', startDate?.toISOString());
            console.log('End:', endDate?.toISOString());

            // Add this log after getting the expenses
            console.log('Expenses:', expenses.map(e => ({
                amount: e.amount,
                date: e.purchase_date,
                type: e.expense_type
            })));

          // Calculate service fees and other expenses
          const serviceFees = expenses
            .filter(expense => expense.expense_type === 'Service Fee')
            .reduce((sum, expense) => sum + (expense.amount || 0), 0);

          const otherExpenses = expenses
            .filter(expense => expense.expense_type !== 'Service Fee')
            .reduce((sum, expense) => sum + (expense.amount || 0), 0);
      
          // Process data for summary
          const summary = {
            overall: {
              totalSessions: attendanceData.length,
              grossRevenue: grossRevenue,
              grossRevenueWithInvoice: grossRevenueWithInvoice,
              netRevenue: netRevenue,
              serviceFees: serviceFees,
              otherExpenses: otherExpenses
            },
            // ... rest of the summary object remains the same
            byService: {},
            bySessionType: {},
            byMonth: {}
          };
      
          // Process by service type
          attendanceData.forEach(record => {
            const validService = record.client?.client_services?.find(service => {
              const attendanceDate = new Date(record.attendance_date);
              const isValidFrom = !service.pricing_valid_from || new Date(service.pricing_valid_from) <= attendanceDate;
              const isValidUntil = !service.pricing_valid_until || new Date(service.pricing_valid_until) >= attendanceDate;
              return isValidFrom && isValidUntil;
            });
      
            const serviceType = validService?.service_type || 'Unknown';
            
            if (!summary.byService[serviceType]) {
              summary.byService[serviceType] = {
                count: 0,
                revenue: 0
              };
            }
            summary.byService[serviceType].count++;
            summary.byService[serviceType].revenue += (record.amount_paid || 0);
          });
      
          // Process by session type (get directly from session.session_type)
          attendanceData.forEach(record => {
            const sessionType = record.session_type; // Get from attendance record instead of session
            if (!summary.bySessionType[sessionType]) {
              summary.bySessionType[sessionType] = {
                count: 0,
                revenue: 0
              };
            }
            summary.bySessionType[sessionType].count++;
            summary.bySessionType[sessionType].revenue += (record.amount_paid || 0);
          });
      
          // Process by month
          attendanceData.forEach(record => {
            const month = new Date(record.attendance_date).toLocaleString('default', { month: 'long' });
            if (!summary.byMonth[month]) {
              summary.byMonth[month] = {
                count: 0,
                revenue: 0
              };
            }
            summary.byMonth[month].count++;
            summary.byMonth[month].revenue += (record.amount_paid || 0);
          });
      
          setSummaryData(summary);
        } catch (error) {
          toast({
            title: 'Error fetching summary',
            description: error.message,
            status: 'error',
            duration: 5000
          });
        } finally {
          setIsLoading(false);
        }
    };
  
    const formatCurrency = (amount) => {
      return new Intl.NumberFormat('en-PH', {
        style: 'currency',
        currency: 'PHP'
      }).format(amount);
    };

    const formatBiMonthlyPeriod = (year, month, period) => {
        if (period === 'first') {
          return `${new Date(year, month, 13).toLocaleDateString('en-US', { 
            month: 'short', 
            day: 'numeric' 
          })} - ${new Date(year, month, 26).toLocaleDateString('en-US', { 
            month: 'short', 
            day: 'numeric',
            //year: 'numeric'
          })}`;
        } else {
          const startDate = new Date(year, month, 27);
          const endDate = new Date(year, month + 1, 12);
          return `${startDate.toLocaleDateString('en-US', { 
            month: 'short', 
            day: 'numeric' 
          })} - ${endDate.toLocaleDateString('en-US', { 
            month: 'short', 
            day: 'numeric',
            //year: 'numeric'
          })}`;
        }
    };

    const formatDateRange = (startDate, endDate) => {
        if (!startDate || !endDate) return '';

        const formatDate = (date) => {
            // Create a new date object in local time to prevent timezone shifting
            const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
            return localDate.toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: startDate.getFullYear() !== endDate.getFullYear() ? 'numeric' : undefined
            });
        };

        return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    };

    const getYearDisplay = (year) => {
        const { startDate, endDate } = getPeriodDates(year, 11, 'yearly');
        return `${year} (${formatDateRange(startDate, endDate)})`;
    };

    const getMonthDisplay = (year, month) => {
        const { startDate, endDate } = getPeriodDates(year, month, 'monthly');
        console.log('Month Display Dates:', {
            year,
            month,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            formattedRange: formatDateRange(startDate, endDate)
        });
        const monthName = new Date(year, month, 1).toLocaleString('default', { month: 'long' });
        return `${monthName} (${formatDateRange(startDate, endDate)})`;
    };

    const getBiMonthlyDisplay = (year, month, period) => {
        const { startDate, endDate } = getPeriodDates(year, month, 'bimonthly', period);
        return formatDateRange(startDate, endDate);
    };
  
    return (
      <Box>
        <VStack spacing={6} align="stretch">
          {/* Time Frame Controls */}
          <HStack spacing={4} align="start">
          <Select
            value={timeframe}
            onChange={(e) => setTimeframe(e.target.value)}
            w="200px"
          >
            <option value="lifetime">Lifetime</option>
            <option value="yearly">Yearly</option>
            <option value="monthly">Monthly</option>
            <option value="bimonthly">Bi-Monthly</option>
          </Select>

          {timeframe === 'bimonthly' && (
            <Select
              value={biMonthlyPeriod}
              onChange={(e) => setBiMonthlyPeriod(e.target.value)}
              w="350px"
            >
              <option value="first">
                {getBiMonthlyDisplay(selectedYear, selectedMonth, 'first')}
              </option>
              <option value="second">
                {getBiMonthlyDisplay(selectedYear, selectedMonth, 'second')}
              </option>
            </Select>
          )}

          {timeframe !== 'lifetime' && (
            <>
              {timeframe !== 'yearly' && (
                <Select
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
                  w="350px"
                >
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i} value={i}>
                      {getMonthDisplay(selectedYear, i)}
                    </option>
                  ))}
                </Select>
              )}
            {timeframe !== 'monthly' && (
              <Select
                value={selectedYear}
                onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                w="350px"
              >
                {Array.from({ length: 5 }, (_, i) => {
                  const year = new Date().getFullYear() - i;
                  return (
                    <option key={i} value={year}>
                      {getYearDisplay(year)}
                    </option>
                  );
                })}
              </Select>
            )}
            </>
          )}
        </HStack>
  
          {isLoading ? (
            <Center py={8}>
              <Spinner />
            </Center>
          ) : summaryData && (
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
              {/* Overall Summary Card */}
              <Card>
                <CardHeader>
                  <Heading size="md">Overall Summary</Heading>
                </CardHeader>
                <CardBody>
                    <VStack align="start" spacing={3}>
                        <Stat>
                        <StatLabel>Total Sessions</StatLabel>
                        <StatNumber>{summaryData.overall.totalSessions}</StatNumber>
                        </Stat>
                        <Stat>
                        <StatLabel>Gross Revenue</StatLabel>
                        <StatNumber>
                            {formatCurrency(summaryData.overall.grossRevenue)}{' '}
                            <Text as="span" fontSize="md" color="gray.500">
                            ({formatCurrency(summaryData.overall.grossRevenueWithInvoice)})
                            </Text>
                        </StatNumber>
                        </Stat>
                        <Stat>
                        <StatLabel>Total Service Fees</StatLabel>
                        <StatNumber>{formatCurrency(summaryData.overall.serviceFees)}</StatNumber>
                        </Stat>
                        <Stat>
                        <StatLabel>Other Expenses</StatLabel>
                        <StatNumber>{formatCurrency(summaryData.overall.otherExpenses)}</StatNumber>
                        </Stat>
                        <Stat>
                        <StatLabel>Net Revenue</StatLabel>
                        <StatNumber>{formatCurrency(summaryData.overall.netRevenue)}</StatNumber>
                        </Stat>
                    </VStack>
                </CardBody>
              </Card>
  
              {/* Service Type Summary Card */}
              <Card>
                <CardHeader>
                  <Heading size="md">By Service Type</Heading>
                </CardHeader>
                <CardBody>
                  <VStack align="stretch" spacing={3}>
                    {Object.entries(summaryData.byService).map(([service, data]) => (
                      <Box key={service} p={3} borderWidth={1} borderRadius="md">
                        <Text fontWeight="bold">{service}</Text>
                        <Text>Sessions: {data.count}</Text>
                        <Text>Revenue: {formatCurrency(data.revenue)}</Text>
                      </Box>
                    ))}
                  </VStack>
                </CardBody>
              </Card>
  
              {/* Session Type Summary Card */}
              <Card>
                <CardHeader>
                  <Heading size="md">By Session Type</Heading>
                </CardHeader>
                <CardBody>
                  <VStack align="stretch" spacing={3}>
                    {Object.entries(summaryData.bySessionType).map(([type, data]) => (
                      <Box key={type} p={3} borderWidth={1} borderRadius="md">
                        <Text fontWeight="bold">{type}</Text>
                        <Text>Sessions: {data.count}</Text>
                        <Text>Revenue: {formatCurrency(data.revenue)}</Text>
                      </Box>
                    ))}
                  </VStack>
                </CardBody>
              </Card>
            </SimpleGrid>
          )}
        </VStack>
      </Box>
    );
  };

  export default FinancialSummary;