import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  HStack,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  NumberInput,
  NumberInputField,
  IconButton,
  useToast,
  useDisclosure,
  Text,
  Badge,
  Checkbox,
  Tooltip,
  Divider,
  Switch,
  FormHelperText
} from '@chakra-ui/react';
import { EditIcon, CloseIcon } from '@chakra-ui/icons';
import supabase from '../supabaseClient';

const calculateAge = (birthday) => {
  if (!birthday) return '';
  const birthDate = new Date(birthday);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  
  return age;
};

// Schedule component for the form
const ScheduleRow = ({ day, slots = [], onUpdate, onDelete }) => {
    // Generate time options from 7 AM to 7 PM without leading zeros
    const timeOptions = Array.from({ length: 13 }, (_, i) => `${i + 7}:00`);
    
    const formatTime = (time) => {
        const hour = parseInt(time.split(':')[0]);
        const h = hour % 12 || 12;
        const ampm = hour < 12 ? 'AM' : 'PM';
        return `${h}:00 ${ampm}`;
    };

    // Find continuous ranges in slots
    const getTimeRange = (slots) => {
        if (!slots || slots.length === 0) return { startTime: '', endTime: '', hasBreak: false };
        
        // Sort slots and convert to numbers for processing
        const sortedSlots = [...slots].sort((a, b) => {
        const aHour = parseInt(a.split(':')[0]);
        const bHour = parseInt(b.split(':')[0]);
        return aHour - bHour;
        });

        // Check if 12:00 is excluded (has break)
        const hasBreak = !sortedSlots.includes('12:00');
        
        // Get start and end times
        const startHour = parseInt(sortedSlots[0].split(':')[0]);
        const lastSlotHour = parseInt(sortedSlots[sortedSlots.length - 1].split(':')[0]);
        const endHour = lastSlotHour + 1;
        
        return {
        startTime: `${startHour}:00`,
        endTime: `${endHour}:00`,
        hasBreak
        };
    };

    const generateTimeSlots = (start, end, includeBreak) => {
        if (!start || !end) return [];
        
        const startHour = parseInt(start.split(':')[0]);
        const endHour = parseInt(end.split(':')[0]);
        const slots = [];
        
        for (let hour = startHour; hour < endHour; hour++) {
        const timeSlot = `${hour}:00`;
        if (!includeBreak && hour === 12) continue; // Skip lunch break
        slots.push(timeSlot);
        }
        
        return slots.sort();
    };

    // Get the current time range and break status
    const { startTime, endTime, hasBreak } = getTimeRange(slots);

    const handleTimeRangeChange = (type, newValue, includeBreak = !hasBreak) => {
        let newStartTime = type === 'start' ? newValue : startTime;
        let newEndTime = type === 'end' ? newValue : endTime;
        
        // If we're setting start time and no end time is set, default to 7 PM
        if (type === 'start' && !endTime) {
        newEndTime = '19:00';
        }
        // If we're setting end time and no start time is set, default to 7 AM
        if (type === 'end' && !startTime) {
        newStartTime = '7:00';
        }

        if (newStartTime && newEndTime) {
        const newSlots = generateTimeSlots(newStartTime, newEndTime, includeBreak);
        onUpdate({ day, slots: newSlots });
        }
    };

  return (
    <VStack spacing={4} align="stretch" width="100%" p={4} borderWidth={1} borderRadius="md">
      <HStack justify="space-between">
        <FormControl isRequired flex={1}>
          <FormLabel>Day</FormLabel>
          <Select 
            value={day || ""}
            onChange={(e) => onUpdate({ day: e.target.value, slots })}
          >
            <option value="">Select Day</option>
            <option value="Monday">Monday</option>
            <option value="Tuesday">Tuesday</option>
            <option value="Wednesday">Wednesday</option>
            <option value="Thursday">Thursday</option>
            <option value="Friday">Friday</option>
            <option value="Saturday">Saturday</option>
            <option value="Sunday">Sunday</option>
          </Select>
        </FormControl>
        <IconButton
          icon={<CloseIcon />}
          onClick={onDelete}
          colorScheme="red"
          variant="ghost"
          size="sm"
          alignSelf="flex-start"
          mt={8}
        />
      </HStack>

      <HStack spacing={4} align="flex-start">
        <FormControl isRequired>
          <FormLabel>Start Time</FormLabel>
          <Select
            value={startTime}
            onChange={(e) => handleTimeRangeChange('start', e.target.value)}
          >
            <option value="">Select Time</option>
            {timeOptions.slice(0, -1).map((time) => (
              <option key={time} value={time}>{formatTime(time)}</option>
            ))}
          </Select>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>End Time</FormLabel>
          <Select
            value={endTime}
            onChange={(e) => handleTimeRangeChange('end', e.target.value)}
          >
            <option value="">Select Time</option>
            {timeOptions.slice(1).map((time) => (
              <option key={time} value={time}>{formatTime(time)}</option>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>Break Time</FormLabel>
          <Switch
            isChecked={hasBreak}
            onChange={(e) => {
              const includeBreak = !e.target.checked;
              handleTimeRangeChange('break', null, includeBreak);
            }}
          />
          <FormHelperText>12:00 PM break</FormHelperText>
        </FormControl>
      </HStack>

      <Text fontSize="sm" color="gray.600">
        Selected slots: {slots.map(formatTime).join(', ')}
      </Text>
    </VStack>
  );
};

const TherapistForm = ({ isOpen, onClose, initialData = null, onSave }) => {
  const [formData, setFormData] = useState({
    name: '',
    nickname: '',
    email: '', // New field
    password: '', // New field
    age: '',
    gender: '',
    specialization: '',
    service_fee: '',
    contact_number: '',
    schedule: [],
    bir_cor: false,
    nbi_clearance: false,
    diploma: false,
    prc_license: false,
    prc_cor: false,
    notes: '',
    status: 'active'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const initialFormState = {
    name: '',
    nickname: '',
    email: '',
    password: '',
    birthday: '', // Changed from age to birthday
    gender: '',
    specialization: '',
    service_fee: '',
    contact_number: '',
    schedule: [],
    bir_cor: false,
    nbi_clearance: false,
    diploma: false,
    prc_license: false,
    prc_cor: false,
    notes: '',
    status: 'active'
  };

  useEffect(() => {
    if (initialData) {
      // Define day order for sorting
      const dayOrder = {
        'Monday': 1,
        'Tuesday': 2,
        'Wednesday': 3,
        'Thursday': 4,
        'Friday': 5,
        'Saturday': 6,
        'Sunday': 7
      };

      // Convert schedule from JSONB format to form format and sort by day
      const scheduleArray = initialData.schedule ? 
        Object.entries(initialData.schedule)
          .map(([day, slots]) => ({
            day,
            slots: Array.isArray(slots) ? slots.sort() : []
          }))
          .sort((a, b) => dayOrder[a.day] - dayOrder[b.day]) : [];

      // Get current service fee from service_fees array
      const currentFee = initialData.service_fees?.find(fee => !fee.valid_until)?.amount || '';

      console.log('Initial Data: ', initialData);

      setFormData({
        ...initialData,
        email: initialData.users?.[0]?.email || '', // Get email from users relation
        birthday: initialData.birthday ? new Date(initialData.birthday).toISOString().split('T')[0] : '', // Format birthday for date input
        schedule: scheduleArray,
        service_fee: currentFee,
        status: initialData.status || 'active', // Ensure status is lowercase
        password: '' // Clear password when editing
      });
    } else {
      setFormData(initialFormState);
    }
  }, [initialData, isOpen]);

  const handleAddSchedule = () => {
    setFormData(prev => ({
      ...prev,
      schedule: [...prev.schedule, { day: '', slots: [] }]
    }));
  };

  const handleScheduleUpdate = (index, updatedSchedule) => {
    console.log('Updating schedule:', index, updatedSchedule);  // Debug log
    setFormData(prev => ({
      ...prev,
      schedule: prev.schedule.map((s, i) => 
        i === index ? updatedSchedule : s
      )
    }));
  };

  const handleScheduleDelete = (index) => {
    setFormData(prev => ({
      ...prev,
      schedule: prev.schedule.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Convert schedule array to JSONB format
      const scheduleObject = formData.schedule.reduce((acc, { day, slots }) => {
        if (day && slots.length > 0) {
          acc[day] = slots;
        }
        return acc;
      }, {});

      let userId = initialData?.user_id;

      // If this is a new therapist or we're updating email/password
      if (!initialData || formData.password) {
        // Create or update user in Auth
        if (initialData) {
          // Update existing user
          const { error: updateUserError } = await supabase.auth.updateUser({
            email: formData.email,
            password: formData.password
          });
          if (updateUserError) throw updateUserError;
        } else {
          // Create new user
          const { data: userData, error: createUserError } = await supabase.auth.signUp({
            email: formData.email,
            password: formData.password,
            email_confirm: true, // This automatically confirms the email
            options: {
              data: {
                role: 'therapist'
              }
            }
          });
          if (createUserError) throw createUserError;
          userId = userData.user.id;

          // Create record in users table
          const { error: createUserRecordError } = await supabase
            .from('users')
            .insert([{
              id: userId,
              email: formData.email,
              role: 'therapist'
            }]);
          if (createUserRecordError) throw createUserRecordError;
        }
      }

      const therapistData = {
        name: formData.name,
        nickname: formData.nickname,
        user_id: userId, // Link to auth user
        birthday: formData.birthday, // Add birthday
        age: calculateAge(formData.birthday), // Compute and store age
        gender: formData.gender,
        specialization: formData.specialization,
        contact_number: formData.contact_number,
        schedule: scheduleObject,
        bir_cor: formData.bir_cor,
        nbi_clearance: formData.nbi_clearance,
        diploma: formData.diploma,
        prc_license: formData.prc_license,
        prc_cor: formData.prc_cor,
        notes: formData.notes,
        status: formData.status
      };

      let therapistId;
      
      if (initialData) {
        const { error } = await supabase
          .from('therapists')
          .update(therapistData)
          .eq('id', initialData.id);
        if (error) throw error;
        therapistId = initialData.id;
      } else {
        const { data, error } = await supabase
          .from('therapists')
          .insert([therapistData])
          .select()
          .single();
        if (error) throw error;
        therapistId = data.id;
      }

      // Update service fee only if it changed
      if (formData.service_fee) {
        const currentFee = initialData?.service_fees?.find(fee => !fee.valid_until)?.amount;
        
        // Only update if the fee actually changed
        if (currentFee !== formData.service_fee) {
          // First, set valid_until for the current active fee if it exists
          await supabase
            .from('service_fees')
            .update({ valid_until: new Date().toISOString() })
            .eq('therapist_id', therapistId)
            .is('valid_until', null);

          // Then insert the new fee
          const { error: feeError } = await supabase
            .from('service_fees')
            .insert([{
              therapist_id: therapistId,
              amount: formData.service_fee,
              valid_from: new Date().toISOString(),
              valid_until: null
            }]);
          if (feeError) throw feeError;
        }
      }

      toast({
        title: initialData ? 'Therapist Updated' : 'Therapist Added',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setFormData(initialFormState); // Reset form to initial state
      onSave();
      onClose();
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>
            {initialData ? 'Edit Therapist' : 'Add New Therapist'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={6}>
              {/* Authentication Information */}
              <Box width="100%">
                <VStack spacing={4} align="stretch">
                  <FormControl isRequired={!initialData}>
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="email"
                      value={formData.email}
                      onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                      isReadOnly={!!initialData} // Make email readonly when editing
                    />
                  </FormControl>

                  <FormControl isRequired={!initialData}>
                    <FormLabel>{initialData ? 'New Password (leave blank to keep current)' : 'Password'}</FormLabel>
                    <Input
                      type="password"
                      value={formData.password}
                      onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                      placeholder={initialData ? 'Enter new password or leave blank' : 'Enter password'}
                    />
                  </FormControl>
                </VStack>
              </Box>

              <Divider />

              {/* Basic Information */}
              <Box width="100%">
                <VStack spacing={4} align="stretch">
                  <HStack spacing={4}>
                    <FormControl isRequired>
                      <FormLabel>Therapist Name</FormLabel>
                      <Input
                        value={formData.name}
                        onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Nickname</FormLabel>
                      <Input
                        value={formData.nickname}
                        onChange={(e) => setFormData(prev => ({ ...prev, nickname: e.target.value }))}
                        placeholder="Optional"
                      />
                    </FormControl>
                  </HStack>

                  <HStack spacing={4}>
                    <FormControl>
                        <FormLabel>Birthday</FormLabel>
                        <Input
                        type="date"
                        value={formData.birthday}
                        onChange={(e) => setFormData(prev => ({ 
                            ...prev, 
                            birthday: e.target.value 
                        }))}
                        />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Gender</FormLabel>
                      <Select
                        value={formData.gender}
                        onChange={(e) => setFormData(prev => ({ ...prev, gender: e.target.value }))}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Select>
                    </FormControl>
                  </HStack>

                  <HStack spacing={4}>
                    <FormControl isRequired>
                      <FormLabel>Specialization</FormLabel>
                      <Select
                        value={formData.specialization}
                        onChange={(e) => setFormData(prev => ({ ...prev, specialization: e.target.value }))}
                      >
                        <option value="">Select Specialization</option>
                        <option value="Occupational Therapy">Occupational Therapy</option>
                        <option value="Speech Therapy">Speech Therapy</option>
                        <option value="Physical Therapy">Physical Therapy</option>
                      </Select>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel>Service Fee</FormLabel>
                      <NumberInput
                        value={formData.service_fee || ''}
                        onChange={(valueString) => setFormData(prev => ({ 
                          ...prev, 
                          service_fee: valueString === '' ? '' : parseInt(valueString, 10)
                        }))}
                        min={0}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </FormControl>
                  </HStack>
                </VStack>
              </Box>

              <Divider />

              {/* Schedule */}
              <Box width="100%">
                <VStack spacing={4} align="stretch">
                  <HStack justify="space-between">
                    <FormLabel>Schedule</FormLabel>
                    <Button
                      colorScheme="blue"
                      size="sm"
                      onClick={handleAddSchedule}
                    >
                      Add Schedule
                    </Button>
                  </HStack>

                  {formData.schedule.map((schedule, index) => (
                    <ScheduleRow
                        key={index}
                        day={schedule.day}
                        slots={schedule.slots}
                        onUpdate={(updatedSchedule) => handleScheduleUpdate(index, updatedSchedule)}
                        onDelete={() => handleScheduleDelete(index)}
                    />
                  ))}
                </VStack>
              </Box>

              <Divider />

              {/* Contact Information & Documents */}
              <Box width="100%">
                <VStack spacing={4} align="stretch">
                  <FormControl>
                    <FormLabel>Contact Number</FormLabel>
                    <Input
                      value={formData.contact_number}
                      onChange={(e) => setFormData(prev => ({ 
                        ...prev, 
                        contact_number: e.target.value 
                      }))}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Documents Submitted</FormLabel>
                    <VStack align="start" spacing={2}>
                      <Checkbox
                        isChecked={formData.bir_cor}
                        onChange={(e) => setFormData(prev => ({ 
                          ...prev, 
                          bir_cor: e.target.checked 
                        }))}
                      >
                        BIR COR
                      </Checkbox>
                      <Checkbox
                        isChecked={formData.nbi_clearance}
                        onChange={(e) => setFormData(prev => ({ 
                          ...prev, 
                          nbi_clearance: e.target.checked 
                        }))}
                      >
                        NBI Clearance
                      </Checkbox>
                      <Checkbox
                        isChecked={formData.diploma}
                        onChange={(e) => setFormData(prev => ({ 
                          ...prev, 
                          diploma: e.target.checked 
                        }))}
                      >
                        DIPLOMA
                      </Checkbox>
                      <Checkbox
                        isChecked={formData.prc_license}
                        onChange={(e) => setFormData(prev => ({ 
                          ...prev, 
                          prc_license: e.target.checked 
                        }))}
                      >
                        PRC License
                      </Checkbox>
                      <Checkbox
                        isChecked={formData.prc_cor}
                        onChange={(e) => setFormData(prev => ({ 
                          ...prev, 
                          prc_cor: e.target.checked 
                        }))}
                      >
                        PRC COR
                      </Checkbox>
                    </VStack>
                  </FormControl>
                </VStack>
              </Box>

              <Divider />

              {/* Additional Information */}
              <Box width="100%">
                <VStack spacing={4} align="stretch">
                  <FormControl>
                    <FormLabel>Notes</FormLabel>
                    <Textarea
                      value={formData.notes}
                      onChange={(e) => setFormData(prev => ({ 
                        ...prev, 
                        notes: e.target.value 
                      }))}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Status</FormLabel>
                    <Select
                      value={formData.status}
                      onChange={(e) => setFormData(prev => ({ 
                        ...prev, 
                        status: e.target.value 
                      }))}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </Select>
                  </FormControl>
                </VStack>
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button type="submit" colorScheme="blue" mr={3} isLoading={isSubmitting}>
              {initialData ? 'Update Therapist' : 'Add Therapist'}
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const TherapistManagement = ({ LoadingComponent }) => {
  const [therapists, setTherapists] = useState([]);
  const [filteredTherapists, setFilteredTherapists] = useState([]);
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  // Search and filter states
  const [searchTerm, setSearchTerm] = useState('');
  const [specializationFilter, setSpecializationFilter] = useState('');

  useEffect(() => {
    fetchTherapists();
  }, []);

  // Update filtered therapists whenever search or filters change
  useEffect(() => {
    let result = therapists;

    // Name search filter
    if (searchTerm) {
      result = result.filter(therapist => 
        therapist.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Specialization filter
    if (specializationFilter) {
      result = result.filter(therapist => 
        therapist.specialization === specializationFilter
      );
    }

    setFilteredTherapists(result);
  }, [therapists, searchTerm, specializationFilter]);

  /*
  const verifyUser = async () => {
    try {
      const { data, error } = await supabase.auth.admin.updateUserById(
        '7a4f654f-4904-4fce-a0bd-494d832237b5',
        { email_confirm: true }
      );
      
      if (error) throw error;
      
      console.log('User verified successfully:', data);
      toast({
        title: 'User verified successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error verifying user:', error);
      toast({
        title: 'Error verifying user',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  */

  const fetchTherapists = async () => {
    try {
      const { data: therapistsData, error } = await supabase
        .from('therapists')
        .select(`
          *,
          sessions (
            id,
            day,
            time,
            valid_until,
            client:clients (
              id,
              name
            )
          ),
          service_fees (
            amount,
            valid_until
          )
        `)
        .order('name');

      if (error) throw error;

      // Define day order for sorting
      const dayOrder = {
        'Monday': 1,
        'Tuesday': 2,
        'Wednesday': 3,
        'Thursday': 4,
        'Friday': 5,
        'Saturday': 6,
        'Sunday': 7
      };

      // Process the therapists data
      const processedTherapists = therapistsData.map(therapist => {
        // Process schedule display
        const scheduleEntries = therapist.schedule ? Object.entries(therapist.schedule) : [];
        
        // Sort schedule entries by day order
        const sortedSchedule = scheduleEntries.sort((a, b) => dayOrder[a[0]] - dayOrder[b[0]]);
        
        const scheduleDisplay = sortedSchedule
          .map(([day, slots]) => `${day} (${slots.length} slots)`)
          .join(' | ');

        // Calculate total available slots
        const totalSlots = scheduleEntries.reduce((sum, [_, slots]) => sum + slots.length, 0);

        // Count active sessions
        const activeSessions = (therapist.sessions || [])
          .filter(session => session.client && !session.valid_until);

        // Calculate deck percentage
        const deckPercentage = totalSlots > 0 ? 
          (activeSessions.length / totalSlots) * 100 : 0;

        // Get current service fee
        const currentFee = therapist.service_fees?.find(fee => !fee.valid_until)?.amount || null;

        return {
          ...therapist,
          scheduleDisplay,
          activeSessionCount: activeSessions.length,
          totalSlots,
          deckPercentage,
          service_fee: currentFee
        };
      });

      setTherapists(processedTherapists);
      setFilteredTherapists(processedTherapists);
    } catch (error) {
      console.error('Error fetching therapists:', error);
      toast({
        title: 'Error fetching therapists',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditTherapist = (therapist) => {
    setSelectedTherapist(therapist);
    onOpen();
  };

  const handleCloseForm = () => {
    setSelectedTherapist(null);
    onClose();
  };

  const handleTherapistSaved = () => {
    fetchTherapists();
    handleCloseForm();
  };

  // Helper function to format time
  const formatTime = (time) => {
    if (!time) return '';
    const hour = parseInt(time.split(':')[0]);
    const h = hour % 12 || 12;
    const ampm = hour < 12 ? 'AM' : 'PM';
    return `${h}:00 ${ampm}`;
  };

  // Generate time options without leading zeros
  const generateTimeOptions = () => {
    return Array.from({ length: 13 }, (_, i) => {
        const hour = i + 7; // Start from 7 AM
        return `${hour}:00`; // No padStart
    });
  };

    // Format time for internal storage (needs leading zeros for consistency)
  const formatTimeForStorage = (hour) => {
    return `${String(hour).padStart(2, '0')}:00`;
  };

    // Parse time string to get hour
  const parseTimeToHour = (timeString) => {
    return parseInt(timeString.split(':')[0]);
  };

  if (isLoading) {
    return <LoadingComponent />;
  }

  return (
    <Box>
      <VStack spacing={4} mb={4}>
        <HStack width="100%">
          <Input
            placeholder="Search therapist by name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Select
            placeholder="Filter by Specialization"
            value={specializationFilter}
            onChange={(e) => setSpecializationFilter(e.target.value)}
          >
            <option value="Occupational Therapy">Occupational Therapy</option>
            <option value="Speech Therapy">Speech Therapy</option>
            <option value="Physical Therapy">Physical Therapy</option>
          </Select>
        </HStack>
      </VStack>

      <Button
        colorScheme="blue"
        onClick={() => {
          setSelectedTherapist(null);
          onOpen();
        }}
        mb={4}
      >
        Add New Therapist
      </Button>
{/*
      <Button
        colorScheme="green"
        onClick={verifyUser}
        mb={4}
        ml={4}
        >
        Verify Test User
      </Button>
*/}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Therapist's Name</Th>
            <Th>Age/Gender</Th>
            <Th>Specialization</Th>
            <Th>Deck</Th>
            <Th>Service Fee</Th>
            <Th>Contact Number</Th>
            <Th>Docs</Th>
            <Th>Notes</Th>
            <Th>Status</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredTherapists.map(therapist => (
            <Tr key={therapist.id}>
              <Td>
                <VStack align="start" spacing={0}>
                  <Text>{therapist.name}</Text>
                  {therapist.nickname && (
                    <Text fontSize="sm" color="gray.600">"{therapist.nickname}"</Text>
                  )}
                </VStack>
              </Td>
              <Td>{`${therapist.age}/${therapist.gender?.[0]}`}</Td>
              <Td>
                <VStack align="start" spacing={1}>
                  <Text fontWeight="medium">{therapist.specialization}</Text>
                </VStack>
              </Td>
              <Td>
                <VStack align="start" spacing={1}>
                  <Box w="100%" h="20px" bg="gray.100" borderRadius="full" overflow="hidden">
                    <Box
                      h="100%"
                      w={`${therapist.deckPercentage}%`}
                      bg={therapist.deckPercentage > 80 ? "green.500" : 
                         therapist.deckPercentage > 50 ? "yellow.500" : "red.500"}
                      transition="width 0.5s ease-in-out"
                    />
                  </Box>
                  <Text fontSize="sm">
                    {Math.round(therapist.deckPercentage)}% ({therapist.activeSessionCount}/{therapist.totalSlots})
                  </Text>
                </VStack>
              </Td>
              <Td>₱{therapist.service_fee?.toLocaleString()}</Td>
              <Td>{therapist.contact_number}</Td>
              <Td>
                <VStack>
                    <HStack spacing={2}>
                    <Tooltip label="BIR COR" placement="top">
                        <Box fontSize="xs">
                        {therapist.bir_cor ? '📑' : '❌'}
                        </Box>
                    </Tooltip>
                    <Tooltip label="NBI Clearance" placement="top">
                        <Box fontSize="xs">
                        {therapist.nbi_clearance ? '🔍' : '❌'}
                        </Box>
                    </Tooltip>
                    <Tooltip label="Diploma" placement="top">
                        <Box fontSize="xs"> 
                        {therapist.diploma ? '🎓' : '❌'}
                        </Box>
                    </Tooltip>
                    </HStack>
                    <HStack>
                    <Tooltip label="PRC License" placement="top">
                        <Box fontSize="xs">
                        {therapist.prc_license ? '🆔' : '❌'}
                        </Box>
                    </Tooltip>
                    <Tooltip label="PRC COR" placement="top">
                        <Box fontSize="xs">
                        {therapist.prc_cor ? '📋' : '❌'}
                        </Box>
                    </Tooltip>
                    </HStack>
                </VStack>
              </Td>
              <Td>
                <Text noOfLines={2}>{therapist.notes}</Text>
              </Td>
              <Td>
                <Badge
                  colorScheme={therapist.status === 'active' ? 'green' : 'red'}
                >
                  {therapist.status}
                </Badge>
              </Td>
              <Td>
                <IconButton
                  icon={<EditIcon />}
                  onClick={() => handleEditTherapist(therapist)}
                  aria-label="Edit therapist"
                  size="sm"
                  colorScheme="blue"
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <TherapistForm
        isOpen={isOpen}
        onClose={handleCloseForm}
        initialData={selectedTherapist}
        onSave={handleTherapistSaved}
      />
    </Box>
  );
};

export default TherapistManagement;