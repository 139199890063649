import React, { useState, useEffect } from 'react';
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Heading,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  Switch,
  VStack,
  HStack
} from '@chakra-ui/react';
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import supabase from '../supabaseClient';
import FinancialSummary from './FinancialSummary';
import ExpensesManagement from './ExpensesManagement';
import PayrollManagement from './PayrollManagement';
import IncomeManagement from './IncomeManagement';

const RateManagement = () => {
  const [rates, setRates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRate, setSelectedRate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  const [formData, setFormData] = useState({
    service_type: '',
    session_type: '',
    is_package: false,
    base_rate: '',
    vat_inclusive: true,
    valid_from: new Date().toISOString().split('T')[0],
    valid_until: null
  });

  useEffect(() => {
    fetchRates();
  }, []);

  const fetchRates = async () => {
    try {
      const { data, error } = await supabase
        .from('service_rates')
        .select('*')
        .order('service_type')
        .order('session_type')
        .order('valid_from', { ascending: false });

      if (error) throw error;
      setRates(data);
    } catch (error) {
      toast({
        title: 'Error fetching rates',
        description: error.message,
        status: 'error',
        duration: 5000
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      let { valid_until, ...rateData } = formData;
      if (!valid_until) valid_until = null;

      const { error } = await supabase
        .from('service_rates')
        .insert([{ ...rateData, valid_until }]);

      if (error) throw error;

      toast({
        title: 'Rate saved successfully',
        status: 'success',
        duration: 3000
      });

      setIsModalOpen(false);
      fetchRates();
      setFormData({
        service_type: '',
        session_type: '',
        is_package: false,
        base_rate: '',
        vat_inclusive: true,
        valid_from: new Date().toISOString().split('T')[0],
        valid_until: null
      });
    } catch (error) {
      toast({
        title: 'Error saving rate',
        description: error.message,
        status: 'error',
        duration: 5000
      });
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-PH', {
      style: 'currency',
      currency: 'PHP'
    }).format(amount);
  };

  return (
    <Box>
      <HStack justify="space-between" mb={6}>
        <Heading size="md">Service Rates</Heading>
        <Button leftIcon={<AddIcon />} colorScheme="blue" onClick={() => setIsModalOpen(true)}>
          Add New Rate
        </Button>
      </HStack>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Service Type</Th>
            <Th>Session Type</Th>
            <Th>Rate Type</Th>
            <Th>Base Rate</Th>
            <Th>VAT Inclusive</Th>
            <Th>Valid From</Th>
            <Th>Valid Until</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rates.map(rate => (
            <Tr key={rate.id}>
              <Td>{rate.service_type}</Td>
              <Td>{rate.session_type}</Td>
              <Td>{rate.is_package ? 'Package' : 'Single'}</Td>
              <Td>{formatCurrency(rate.base_rate)}</Td>
              <Td>{rate.vat_inclusive ? 'Yes' : 'No'}</Td>
              <Td>{new Date(rate.valid_from).toLocaleDateString()}</Td>
              <Td>{rate.valid_until ? new Date(rate.valid_until).toLocaleDateString() : 'Ongoing'}</Td>
              <Td>
                <Badge colorScheme={!rate.valid_until || new Date(rate.valid_until) >= new Date() ? 'green' : 'red'}>
                  {!rate.valid_until || new Date(rate.valid_until) >= new Date() ? 'Active' : 'Expired'}
                </Badge>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Rate</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Service Type</FormLabel>
                <Select
                  value={formData.service_type}
                  onChange={(e) => setFormData({ ...formData, service_type: e.target.value })}
                >
                  <option value="">Select Service Type</option>
                  <option value="ST">Speech Therapy</option>
                  <option value="OT">Occupational Therapy</option>
                  <option value="PT">Physical Therapy</option>
                  <option value="PG">Play Group</option>
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Session Type</FormLabel>
                <Select
                  value={formData.session_type}
                  onChange={(e) => setFormData({ ...formData, session_type: e.target.value })}
                >
                  <option value="">Select Session Type</option>
                  <option value="IE">Initial Evaluation</option>
                  <option value="Endorsement">Endorsement</option>
                  <option value="Regular">Regular</option>
                </Select>
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel mb="0">Package Rate?</FormLabel>
                <Switch
                  isChecked={formData.is_package}
                  onChange={(e) => setFormData({ ...formData, is_package: e.target.checked })}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Base Rate</FormLabel>
                <Input
                  type="number"
                  value={formData.base_rate}
                  onChange={(e) => setFormData({ ...formData, base_rate: e.target.value })}
                />
              </FormControl>

              <FormControl display="flex" alignItems="center">
                <FormLabel mb="0">VAT Inclusive?</FormLabel>
                <Switch
                  isChecked={formData.vat_inclusive}
                  onChange={(e) => setFormData({ ...formData, vat_inclusive: e.target.checked })}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Valid From</FormLabel>
                <Input
                  type="date"
                  value={formData.valid_from}
                  onChange={(e) => setFormData({ ...formData, valid_from: e.target.value })}
                />
              </FormControl>

              <FormControl>
                <FormLabel>Valid Until</FormLabel>
                <Input
                  type="date"
                  value={formData.valid_until || ''}
                  onChange={(e) => setFormData({ ...formData, valid_until: e.target.value })}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Save Rate
            </Button>
            <Button variant="ghost" onClick={() => setIsModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const FinancialManagement = () => {
  return (
    <Tabs>
      <TabList>
        <Tab>Summary</Tab>
        <Tab>Rates</Tab>
        <Tab>Income</Tab>
        <Tab>Expenses</Tab>
        <Tab>Payroll</Tab>
        {/* Add more financial management tabs here */}
      </TabList>

      <TabPanels>
        <TabPanel>
          <FinancialSummary />
        </TabPanel>
        <TabPanel>
          <RateManagement />
        </TabPanel>
        <TabPanel>
          <IncomeManagement />
        </TabPanel>
        <TabPanel>
          <ExpensesManagement />
        </TabPanel>
        <TabPanel>
          <PayrollManagement />
        </TabPanel>
        {/* Add corresponding TabPanels for other tabs */}
      </TabPanels>
    </Tabs>
  );
};

export default FinancialManagement;