import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Button,
  Badge,
  Card,
  CardBody,
  Heading,
  useToast,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  Textarea,
  Center,
  Spinner,
  Tooltip
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon, EditIcon, CalendarIcon } from '@chakra-ui/icons';
import supabase from '../supabaseClient';

const BulkCancelModal = React.memo(({ isOpen, onClose, onConfirm, selectedDate }) => {
  const [reason, setReason] = useState('');

  // Reset reason when modal opens/closes
  useEffect(() => {
    if (!isOpen) {
      setReason('');
    }
  }, [isOpen]);

  const handleConfirm = () => {
    onConfirm(reason);
    setReason('');
  };

  // Helper functions for date and time formatting
  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Bulk Cancel Sessions</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <Text>
              This will cancel all sessions scheduled for {formatDate(selectedDate)}.
            </Text>
            <FormControl isRequired>
              <FormLabel>Reason for Cancellation</FormLabel>
              <Textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="Enter reason for cancellation"
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={handleConfirm}
            isDisabled={!reason}
          >
            Cancel All Sessions
          </Button>
          <Button variant="ghost" onClick={onClose}>Back</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

const CurrentDayDeck = ({ therapistId }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [temporaryChanges, setTemporaryChanges] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeckConfirmed, setIsDeckConfirmed] = useState(false);
  const [isAttendanceConfirmed, setIsAttendanceConfirmed] = useState(false);
  const [editSession, setEditSession] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [makeupDate, setMakeupDate] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [nextSessionDate, setNextSessionDate] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isBulkCancelModalOpen, setIsBulkCancelModalOpen] = useState(false);
  const [bulkCancelReason, setBulkCancelReason] = useState('');
  const toast = useToast();

  const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const [canBulkAction, setCanBulkAction] = useState(false);

  // Add this useEffect to check bulk action permissions
  useEffect(() => {
    const checkBulkActionPermissions = async () => {
      try {
        const selectedDateStr = selectedDate.toISOString().split('T')[0];
        console.log('Checking bulk action permissions for:', selectedDateStr);
        
        // Check if this is an auto-confirmed date due to leave
        const { data: deckConfirmations, error } = await supabase
          .from('deck_confirmations')
          .select('auto_confirmed')
          .eq('therapist_id', therapistId)
          .eq('date', selectedDateStr);
  
        if (error) {
          console.error('Error checking deck confirmation:', error);
          setCanBulkAction(false);
          return;
        }
  
        // If there's an auto-confirmed record, can't perform bulk actions
        if (deckConfirmations?.length > 0 && deckConfirmations[0].auto_confirmed) {
          console.log('Date is auto-confirmed due to leave');
          setCanBulkAction(false);
          return;
        }
  
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const sessionDate = new Date(selectedDate);
        sessionDate.setHours(0, 0, 0, 0);
  
        // If it's current day, check if any sessions have attendance
        if (sessionDate.getTime() === today.getTime()) {
          const hasNoAttendance = !sessions.some(session => session.attendance);
          console.log('Current day check - Has no attendance:', hasNoAttendance);
          setCanBulkAction(hasNoAttendance);
          return;
        }
  
        // For future dates, always allow
        const isFutureDate = sessionDate > today;
        console.log('Is future date:', isFutureDate);
        setCanBulkAction(isFutureDate);
        
      } catch (error) {
        console.error('Error checking bulk action permissions:', error);
        setCanBulkAction(false);
      }
    };
  
    checkBulkActionPermissions();
  }, [selectedDate, sessions, therapistId]);

  useEffect(() => {
    const initializeDate = async () => {
      try {
        const latestConfirmedDate = await findLatestConfirmedDate();
        if (latestConfirmedDate) {
          // Find the next session date after the latest confirmed date
          const nextDate = await findNextSessionDate(latestConfirmedDate);
          if (nextDate) {
            setSelectedDate(nextDate);
          }
        }
      } catch (error) {
        console.error('Error initializing date:', error);
      }
    };
  
    initializeDate();
  }, [therapistId]); // Only run on mount and therapistId change
  
  // Update the date change effect to reset states
  useEffect(() => {
    const resetStatesAndFetch = async () => {
      setIsLoading(true);
      setIsDeckConfirmed(false);
      setIsAttendanceConfirmed(false);
      await fetchSessions();
      await checkDeckConfirmation();
      await checkAttendanceConfirmation();
    };
  
    resetStatesAndFetch();
  }, [selectedDate, therapistId]);

  const findLatestConfirmedDate = async () => {
    try {
      const { data, error } = await supabase
        .from('attendance_confirmations')
        .select('date')
        .eq('therapist_id', therapistId)
        .order('date', { ascending: false })
        .limit(1);
  
      if (error) throw error;
      return data?.[0]?.date ? new Date(data[0].date) : null;
    } catch (error) {
      console.error('Error finding latest confirmed date:', error);
      return null;
    }
  };

  const canConfirmAttendance = () => {
    // If no sessions, don't allow confirmation
    if (sessions.length === 0) return false;
  
    // Can't confirm future dates
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const sessionDate = new Date(selectedDate);
    sessionDate.setHours(0, 0, 0, 0);
    if (sessionDate > today) return false;
  
    // All sessions must have attendance marked
    return sessions.every(session => session.attendance !== undefined);
  };

  const canPerformBulkActions = async () => {
    try {
      const selectedDateStr = selectedDate.toISOString().split('T')[0];
      
      // Check if this is an auto-confirmed date due to leave
      const { data: deckConfirmation } = await supabase
        .from('deck_confirmations')
        .select('auto_confirmed')
        .eq('therapist_id', therapistId)
        .eq('date', selectedDateStr)
        .single();
  
      // If auto-confirmed due to leave, can't perform bulk actions
      if (deckConfirmation?.auto_confirmed) return false;
  
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const sessionDate = new Date(selectedDate);
      sessionDate.setHours(0, 0, 0, 0);
  
      // If it's current day, check if any sessions have attendance
      if (sessionDate.getTime() === today.getTime()) {
        return !sessions.some(session => session.attendance);
      }
  
      // For future dates, always allow
      return sessionDate >= today;
    } catch (error) {
      console.error('Error checking bulk action permissions:', error);
      return false;
    }
  };
  
  // Add function to handle bulk cancellation
  const handleBulkCancel = async (reason) => {
    try {
      const selectedDateStr = selectedDate.toISOString().split('T')[0];
  
      // Create attendance records for all sessions
      const attendanceRecords = sessions.map(session => ({
        session_id: session.id,
        client_id: session.client_id,
        therapist_id: therapistId,
        attendance_date: selectedDateStr,
        status: 'cancelled',
        notes: `Bulk cancelled: ${reason}`,
        base_rate: 0,
        final_amount: 0
      }));
  
      const { error } = await supabase
        .from('session_attendance')
        .insert(attendanceRecords);
  
      if (error) throw error;
  
      // Close modal and refresh
      setIsBulkCancelModalOpen(false);
      await fetchSessions();
  
      toast({
        title: 'Sessions Cancelled',
        description: `Successfully cancelled ${sessions.length} session(s)`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error bulk cancelling sessions:', error);
      toast({
        title: 'Error',
        description: 'Failed to cancel sessions',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  // Add function to handle bulk uncancellation
  const handleBulkUncancel = async () => {
    try {
      const selectedDateStr = selectedDate.toISOString().split('T')[0];
  
      // Delete all cancellation records for this date
      const { error } = await supabase
        .from('session_attendance')
        .delete()
        .eq('therapist_id', therapistId)
        .eq('attendance_date', selectedDateStr)
        .eq('status', 'cancelled')
        .like('notes', 'Bulk cancelled:%');
  
      if (error) throw error;
  
      await fetchSessions();
  
      toast({
        title: 'Sessions Uncancelled',
        description: 'Successfully uncancelled all sessions',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error uncancelling sessions:', error);
      toast({
        title: 'Error',
        description: 'Failed to uncancel sessions',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchSessions = async () => {
    console.log('Therapist ID: ', therapistId);
    try {
      setIsLoading(true);
      const dayOfWeek = DAYS[selectedDate.getDay()];
      const selectedDateStr = selectedDate.toISOString().split('T')[0];

      // Fetch therapist's working hours
      const { data: therapistData } = await supabase
        .from('therapists')
        .select('schedule')
        .eq('id', therapistId)
        .single();

      // Fetch sessions and attendance
      const { data: sessionsData, error: sessionsError } = await supabase
        .from('sessions')
        .select(`
          *,
          client:clients (*),
          session_attendance:session_attendance(*)
        `)
        .eq('therapist_id', therapistId)
        .eq('day', dayOfWeek)
        .lte('valid_from', selectedDateStr)
        .or(`valid_until.is.null,valid_until.gte.${selectedDateStr}`);

      if (sessionsError) throw sessionsError;

      // Process and sort sessions
      const processedSessions = sessionsData
        .map(session => ({
          ...session,
          attendance: session.session_attendance.find(
            a => a.attendance_date === selectedDateStr
          )
        }))
        .sort((a, b) => {
          const timeA = parseInt(a.time.split(':')[0]);
          const timeB = parseInt(b.time.split(':')[0]);
          return timeA - timeB;
        });

      setSessions(processedSessions);
    } catch (error) {
      console.error('Error fetching sessions:', error);
      toast({
        title: 'Error fetching sessions',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isWithinOneDayOfSession = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const sessionDate = new Date(date);
    sessionDate.setHours(0, 0, 0, 0);
  
    const diffTime = sessionDate.getTime() - today.getTime();
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
  
    return diffDays <= 1;
  };
  
  const checkAllSessionsMarked = (sessions) => {
    // Return true only if all sessions have attendance records
    return sessions.every(session => session.attendance !== undefined);
  };

  const sortTimes = (times) => {
    return times.sort((a, b) => {
      const hourA = parseInt(a.split(':')[0]);
      const hourB = parseInt(b.split(':')[0]);
      return hourA - hourB;
    });
  };

  const getSessionSummary = () => {
    const summary = {
      IE: 0,
      Endorsement: 0,
      Regular: 0
    };
  
    sessions.forEach(session => {
      if (session.attendance?.status === 'present') {
        summary[session.session_type] = (summary[session.session_type] || 0) + 1;
      }
    });
  
    return summary;
  };

  const SessionSummaryModal = ({ isOpen, onClose, onConfirm }) => {
    const summary = getSessionSummary();
  
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Daily Sessions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack align="start" spacing={4}>
              <Text fontWeight="bold">Today's Session Summary:</Text>
              <VStack align="start" pl={4} spacing={2}>
                {summary.IE > 0 && (
                  <Text>{summary.IE} Initial Evaluation{summary.IE > 1 ? 's' : ''} (IE)</Text>
                )}
                {summary.Endorsement > 0 && (
                  <Text>{summary.Endorsement} Endorsement{summary.Endorsement > 1 ? 's' : ''}</Text>
                )}
                {summary.Regular > 0 && (
                  <Text>{summary.Regular} Regular Session{summary.Regular > 1 ? 's' : ''}</Text>
                )}
              </VStack>
              {Object.values(summary).every(val => val === 0) && (
                <Text color="orange.500">No sessions marked as present today.</Text>
              )}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onConfirm}>
              Confirm Attendance
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const checkDeckConfirmation = async () => {
    try {
      const selectedDateStr = selectedDate.toISOString().split('T')[0];
      const { data, error } = await supabase
        .from('deck_confirmations')
        .select('*')
        .eq('therapist_id', therapistId)
        .eq('date', selectedDateStr)
        .single();

      if (error && error.code !== 'PGNF') throw error;
      setIsDeckConfirmed(!!data);
    } catch (error) {
      console.error('Error checking deck confirmation:', error);
    }
  };

  const checkAttendanceConfirmation = async () => {
    try {
      const selectedDateStr = selectedDate.toISOString().split('T')[0];
      const { data, error } = await supabase
        .from('attendance_confirmations')
        .select('*')
        .eq('therapist_id', therapistId)
        .eq('date', selectedDateStr)
        .single();
  
      if (error && error.code !== 'PGNF') throw error;
  
      // Simply set the confirmation status without navigating
      setIsAttendanceConfirmed(!!data);
    } catch (error) {
      console.error('Error checking attendance confirmation:', error);
    }
  };

  const handleEditSession = (session) => {
    setEditSession(session);
    setIsEditModalOpen(true);
  };

// Update handleSaveEdit function
const handleSaveEdit = async () => {
    try {
      const selectedDateStr = selectedDate.toISOString().split('T')[0];
  
      console.log('Date: ', selectedDateStr);
      console.log('Edit Session ID: ', editSession.id);
      
      // Check for existing attendance record
      const { data: existingAttendance, error: fetchError } = await supabase
        .from('session_attendance')
        .select('*')
        .eq('session_id', editSession.id)
        .eq('attendance_date', selectedDateStr)
        .maybeSingle(); // Use maybeSingle instead of single to avoid error
  
      // Only throw error if it's not a "not found" error
      if (fetchError && fetchError.code !== 'PGNF') throw fetchError;
  
      console.log('EXISTING ATTENDANCE: ', existingAttendance);
  
      // Handle Re-Opening session (removing attendance)
      if (!editSession.temporaryStatus) {
        console.log('Re-Opening Session');
        if (existingAttendance) {
          // Delete existing attendance record
          const { error: deleteError } = await supabase
            .from('session_attendance')
            .delete()
            .eq('id', existingAttendance.id);
  
          if (deleteError) throw deleteError;

          console.log('DELETED');
        }
        // Reset states and refresh
        setTemporaryChanges(prev => {
          const newChanges = { ...prev };
          delete newChanges[editSession.id];
          return newChanges;
        });
      } 
      // Handle makeup session creation/update
      else if (editSession.temporaryStatus === 'makeup' && makeupDate) {
        console.log('Creating/Updating Makeup Session');
        const makeupNote = editSession.temporaryNotes || 
          `${formatDate(new Date(makeupDate))} makeup session for ${formatDate(selectedDate)}`;
  
        // Create new makeup session
        const { error: sessionError } = await supabase
          .from('sessions')
          .insert([{
            therapist_id: therapistId,
            client_id: editSession.client_id,
            client_name: editSession.client.name,
            day: DAYS[new Date(makeupDate).getDay()],
            time: editSession.temporaryTime,
            session_type: editSession.session_type,
            notes: makeupNote,
            valid_from: makeupDate,
            valid_until: makeupDate
          }]);
  
        if (sessionError) throw sessionError;
  
        // Update or insert attendance record
        const attendanceData = {
          session_id: editSession.id,
          client_id: editSession.client_id,
          therapist_id: therapistId,
          attendance_date: selectedDateStr,
          status: 'cancelled',
          notes: makeupNote,
          base_rate: 0,
          final_amount: 0
        };
  
        if (existingAttendance) {
          const { error: updateError } = await supabase
            .from('session_attendance')
            .update(attendanceData)
            .eq('id', existingAttendance.id);
          if (updateError) throw updateError;
        } else {
          const { error: insertError } = await supabase
            .from('session_attendance')
            .insert([attendanceData]);
          if (insertError) throw insertError;
        }
  
        setTemporaryChanges(prev => ({
          ...prev,
          [editSession.id]: {
            new_status: 'cancelled',
            notes: makeupNote,
            makeup_date: makeupDate,
            makeup_time: editSession.temporaryTime
          }
        }));
      } 
      // Handle other status changes (absent, cancelled without makeup)
      else {
        console.log('Updating Session Status');
        const attendanceData = {
          session_id: editSession.id,
          client_id: editSession.client_id,
          therapist_id: therapistId,
          attendance_date: selectedDateStr,
          status: editSession.temporaryStatus,
          notes: editSession.temporaryNotes,
          base_rate: 0,
          final_amount: 0
        };
  
        console.log('Attendance Data: ', attendanceData);
  
        if (existingAttendance) {
          const { error: updateError } = await supabase
            .from('session_attendance')
            .update(attendanceData)
            .eq('id', existingAttendance.id);
          if (updateError) throw updateError;
        } else {
          const { error: insertError } = await supabase
            .from('session_attendance')
            .insert([attendanceData]);
          if (insertError) throw insertError;
        }
  
        setTemporaryChanges(prev => ({
          ...prev,
          [editSession.id]: {
            new_status: editSession.temporaryStatus,
            notes: editSession.temporaryNotes
          }
        }));
      }
  
      // Clear modal state
      setIsEditModalOpen(false);
      setMakeupDate(null);
      setEditSession(null);
      
      // Fetch updated sessions to refresh the view
      await fetchSessions();
  
      toast({
        title: 'Changes saved',
        description: editSession.temporaryStatus === 'makeup' ? 
          'Makeup session scheduled and original session marked as cancelled' : 
          editSession.temporaryStatus ? 
            'Session status updated' : 
            'Session re-opened',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving changes:', error);
      toast({
        title: 'Error saving changes',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleConfirmDeck = async () => {
    try {
      const selectedDateStr = selectedDate.toISOString().split('T')[0];
      
      const { error } = await supabase
        .from('deck_confirmations')
        .insert({
          therapist_id: therapistId,
          date: selectedDateStr,
          confirmed_at: new Date().toISOString()
        });

      if (error) throw error;

      setIsDeckConfirmed(true);
      toast({
        title: 'Deck confirmed',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error confirming deck:', error);
      toast({
        title: 'Error confirming deck',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Add this function to get the next session date
  const findNextSessionDate = async (startDate) => {
    try {
      const startDateStr = startDate.toISOString().split('T')[0];
      
      // Fetch all valid sessions
      const { data: sessionsData, error } = await supabase
        .from('sessions')
        .select('*')
        .eq('therapist_id', therapistId)
        .order('valid_from', { ascending: true });
  
      if (error) throw error;
      if (!sessionsData?.length) return null;
  
      // Start checking from the day after current date
      let checkDate = new Date(startDate);
      checkDate.setDate(checkDate.getDate() + 1);
  
      while (checkDate.getTime() < startDate.getTime() + (365 * 24 * 60 * 60 * 1000)) { // Look up to 1 year ahead
        const checkDateStr = checkDate.toISOString().split('T')[0];
        const dayOfWeek = DAYS[checkDate.getDay()];
  
        // Check if there's a valid session on this day
        const validSession = sessionsData.find(session => {
          const isValidDay = session.day === dayOfWeek;
          const isAfterValidFrom = new Date(session.valid_from) <= checkDate;
          const isBeforeValidUntil = !session.valid_until || new Date(session.valid_until) >= checkDate;
          
          return isValidDay && isAfterValidFrom && isBeforeValidUntil;
        });
  
        if (validSession) {
          console.log('Found next session date:', checkDate);
          return checkDate;
        }
  
        checkDate.setDate(checkDate.getDate() + 1);
      }
  
      return null;
    } catch (error) {
      console.error('Error finding next session date:', error);
      return null;
    }
  };
  
  // Add a success modal component
  const SuccessModal = ({ isOpen, onClose, currentDate, nextDate }) => (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={6}>
          <VStack spacing={4}>
            <Box textAlign="center">
              <Text color="green.500" fontWeight="bold" mb={2}>
                ✓ Attendance Confirmed
              </Text>
              <Text>
                Attendance confirmed for {formatDate(currentDate)}
              </Text>
              {nextDate ? (
                <Text mt={4}>
                  Navigating to next session date:
                  <Text fontWeight="bold" mt={1}>
                    {formatDate(nextDate)}
                  </Text>
                </Text>
              ) : (
                <Text mt={4} color="orange.500">
                  No future sessions found
                </Text>
              )}
            </Box>
            <Spinner color="blue.500" />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
  
  // Update the handleConfirmAttendance function
  const handleConfirmAttendance = async () => {
    try {
      const selectedDateStr = selectedDate.toISOString().split('T')[0];
      
      const { error } = await supabase
        .from('attendance_confirmations')
        .insert({
          therapist_id: therapistId,
          date: selectedDateStr,
          confirmed_at: new Date().toISOString()
        });
  
      if (error) throw error;
  
      setIsAttendanceConfirmed(true);
      setIsConfirmModalOpen(false);
      
      // Find next session date
      const nextDate = await findNextSessionDate(selectedDate);
      setNextSessionDate(nextDate);
      
      // Show success modal
      setIsSuccessModalOpen(true);
  
      // After modal display, update states and navigate
      setTimeout(() => {
        setIsSuccessModalOpen(false);
        if (nextDate) {
          setIsDeckConfirmed(false);
          setIsAttendanceConfirmed(false);
          setSelectedDate(nextDate);
        }
      }, 2000);
  
    } catch (error) {
      console.error('Error confirming attendance:', error);
      toast({
        title: 'Error confirming attendance',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Rest of the component implementation...
  // Include date navigation, session cards rendering, etc.

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };
  
  const formatTime = (time) => {
    const hour = parseInt(time.split(':')[0]);
    const h = hour % 12 || 12;
    const ampm = hour < 12 ? 'AM' : 'PM';
    return `${h}:00 ${ampm}`;
  };

  const SessionCard = ({ session, temporaryChanges, onEdit, isDeckConfirmed }) => {
    const hasTemporaryChange = temporaryChanges[session.id];
    const status = session.attendance?.status || 'pending';
  
    return (
        <Card 
          size="sm" 
          variant="outline"
          bg={status === 'present' ? 'green.50' : status === 'pending' ? 'white' : 'red.50'}
          borderColor={hasTemporaryChange ? 'yellow.400' : 'gray.200'}
          width="100%"
        >
          <CardBody py={2}>
            <VStack align="stretch" spacing={2}>
              {/* Client name and badges */}
              <VStack align="start" spacing={1}>
                <Text 
                  fontWeight="bold" 
                  fontSize={{ base: "sm", md: "md" }}
                  noOfLines={1}
                >
                  {session.client.name}
                </Text>
                
                {session.client.nickname && (
                  <Text 
                    fontSize="sm" 
                    color="gray.500"
                    mt={0}
                  >
                    "{session.client.nickname}"
                  </Text>
                )}
                
                {/* Badges in vertical stack on mobile */}
                <HStack 
                  spacing={2} 
                  flexWrap="wrap"
                  mt={1}
                >
                  <Badge 
                    colorScheme={
                      session.attendance?.session_type === 'Regular' || 
                      (!session.attendance && session.session_type === 'Regular')
                        ? 'blue' 
                        : 'green'
                    }
                  >
                    {session.attendance?.session_type || session.session_type}
                  </Badge>
                  
                  {hasTemporaryChange && (
                    <Badge colorScheme="yellow">Modified</Badge>
                  )}
                  
                  {(session.attendance || hasTemporaryChange) && (
                    <Badge 
                      colorScheme={
                        session.attendance?.status === 'present' ? 'green' : 
                        session.attendance?.status === 'absent' ? 'red' : 
                        'yellow'
                      }
                    >
                      {session.attendance ? session.attendance.status.toUpperCase() : 
                       hasTemporaryChange.new_status.toUpperCase()}
                    </Badge>
                  )}
                </HStack>
              </VStack>
    
              {/* Modify button */}
              {(!isDeckConfirmed || (session.attendance && 
                (session.attendance.status === 'cancelled' || 
                 session.attendance.status === 'absent'))) && (
                <Button
                  size="sm"
                  leftIcon={<EditIcon />}
                  onClick={() => onEdit(session)}
                  colorScheme="blue"
                  variant="outline"
                  width={{ base: "100%", md: "auto" }}
                >
                  Modify Session
                </Button>
              )}
    
              {/* Notes section */}
              {(hasTemporaryChange?.notes || session.attendance?.notes) && (
                <Text fontSize="sm" color="gray.600">
                  {hasTemporaryChange?.notes || session.attendance?.notes}
                </Text>
              )}
            </VStack>
          </CardBody>
        </Card>
    );
  };
  
  // Update the main session list rendering in CurrentDayDeck
  const renderTimeSlots = () => {
    // Get unique times from sessions and sort them
    const times = sortTimes([...new Set(sessions.map(s => s.time))]);
    
    return times.map(time => (
      <HStack key={time} align="flex-start" spacing={4}>
        <Box w="80px" pt={2}>
          <Text fontWeight="bold" color="gray.600">
            {formatTime(time)}
          </Text>
        </Box>
        <VStack flex="1" align="stretch" spacing={2}>
          {sessions
            .filter(session => session.time === time)
            .map(session => (
              <SessionCard
                key={session.id}
                session={session}
                temporaryChanges={temporaryChanges}
                onEdit={handleEditSession}
                isDeckConfirmed={isDeckConfirmed}
              />
            ))}
        </VStack>
      </HStack>
    ));
  };
  
  return (
    <Box>
      {/* Header with date navigation and controls */}
      <HStack justify="space-between" mb={6} align="center">
        <HStack>
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={() => {
              const newDate = new Date(selectedDate);
              newDate.setDate(selectedDate.getDate() - 1);
              setSelectedDate(newDate);
            }}
            aria-label="Previous day"
            //isDisabled={isDeckConfirmed}
          />
          <Popover>
            <PopoverTrigger>
            <Heading 
                size={{ base: "sm", md: "md" }} 
                cursor="pointer" 
                display="flex" 
                alignItems="center"
            >
                <Box display={{ base: "none", md: "block" }}>
                    {selectedDate.toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                    })}
                </Box>
                <Box display={{ base: "block", md: "none" }} noOfLines={2} minW="50px">
                    {selectedDate.toLocaleDateString('en-US', {
                    weekday: 'short',
                    month: 'short',
                    day: 'numeric'
                    })}
                </Box>
                <CalendarIcon ml={2} />
            </Heading>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>
                <FormControl>
                  <FormLabel>Select a date</FormLabel>
                  <Input
                    type="date"
                    value={selectedDate.toISOString().split('T')[0]}
                    onChange={(e) => setSelectedDate(new Date(e.target.value))}
                    //isDisabled={isDeckConfirmed}
                  />
                </FormControl>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <IconButton
            icon={<ChevronRightIcon />}
            onClick={() => {
              const newDate = new Date(selectedDate);
              newDate.setDate(selectedDate.getDate() + 1);
              setSelectedDate(newDate);
            }}
            aria-label="Next day"
            //isDisabled={isDeckConfirmed}
          />
        </HStack>
  
        {!isAttendanceConfirmed && (
        <VStack 
            spacing={4} 
            align="stretch"
            display={{ base: "flex", md: "none" }} // Mobile layout
        >
            {!isDeckConfirmed ? (
            <Button
                colorScheme="blue"
                onClick={handleConfirmDeck}
                isLoading={isLoading}
                isDisabled={!isWithinOneDayOfSession(selectedDate)}
                size="sm"
            >
                Confirm Deck
            </Button>
            ) : (
            <Button
                colorScheme="green"
                onClick={() => setIsConfirmModalOpen(true)}
                isLoading={isLoading}
                isDisabled={!canConfirmAttendance()}
                size="sm"
            >
                Confirm Attendance
            </Button>
            )}

            {canBulkAction && (
            <Button
                colorScheme="red"
                variant="outline"
                onClick={() => setIsBulkCancelModalOpen(true)}
                isDisabled={sessions.length === 0}
                size="sm"
            >
                Cancel All
            </Button>
            )}
        </VStack>
        )}

        {/* Desktop layout */}
        {!isAttendanceConfirmed && (
        <HStack 
            spacing={4}
            display={{ base: "none", md: "flex" }}
        >
            {/* Original button layout */}
            {!isDeckConfirmed ? (
            <Button
                colorScheme="blue"
                onClick={handleConfirmDeck}
                isLoading={isLoading}
                isDisabled={!isWithinOneDayOfSession(selectedDate)}
            >
                Confirm Deck
            </Button>
            ) : (
            <Button
                colorScheme="green"
                onClick={() => setIsConfirmModalOpen(true)}
                isLoading={isLoading}
                isDisabled={!canConfirmAttendance()}
            >
                Confirm Attendance
            </Button>
            )}

            {canBulkAction && (
            <Button
                colorScheme="red"
                variant="outline"
                onClick={() => setIsBulkCancelModalOpen(true)}
                isDisabled={sessions.length === 0}
            >
                Cancel All Sessions
            </Button>
            )}
        </HStack>
        )}
      </HStack>
  
      {/* Sessions list */}
      <VStack align="stretch" spacing={4}>
        {isLoading ? (
            <Center py={8}>
            <Spinner />
            </Center>
        ) : sessions.length === 0 ? (
            <Text textAlign="center" color="gray.500" py={8}>
            No sessions scheduled for this day
            </Text>
        ) : (
            renderTimeSlots()
        )}
      </VStack>
  
      {/* Edit Session Modal */}
      <Modal 
        isOpen={isEditModalOpen} 
        onClose={() => setIsEditModalOpen(false)}
        size="lg"
        >
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>
            Modify Session
            {editSession && ` - ${editSession.client.name}`}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <VStack spacing={4}>
                <FormControl>
                    <FormLabel>Status</FormLabel>
                    <Select
                        value={editSession?.temporaryStatus || ''}
                        onChange={(e) => setEditSession({
                        ...editSession,
                        temporaryStatus: e.target.value
                        })}
                    >
                        {editSession?.attendance ? (
                        // Show these options if session already has attendance
                        <>
                            <option value="">Re-Open Session</option>
                            <option value="cancelled">Change to Cancelled</option>
                            <option value="absent">Change to Absent</option>
                            <option value="makeup">Schedule Make-up</option>
                        </>
                        ) : (
                        // Show these options if session has no attendance
                        <>
                            <option value="">Select status</option>
                            <option value="cancelled">Mark as Cancelled</option>
                            <option value="absent">Mark as Absent</option>
                            <option value="makeup">Schedule Make-up</option>
                        </>
                        )}
                    </Select>
                </FormControl>

                {editSession?.temporaryStatus === 'makeup' && (
                <>
                    <FormControl>
                    <FormLabel>Make-up Date</FormLabel>
                    <Input
                        type="date"
                        value={makeupDate || ''}
                        onChange={(e) => setMakeupDate(e.target.value)}
                        min={new Date().toISOString().split('T')[0]}
                    />
                    </FormControl>

                    <FormControl>
                    <FormLabel>New Time</FormLabel>
                    <Select
                        value={editSession?.temporaryTime || ''}
                        onChange={(e) => setEditSession({
                        ...editSession,
                        temporaryTime: e.target.value
                        })}
                    >
                        <option value="">Select new time</option>
                        {Array.from({ length: 11 }, (_, i) => `${i + 8}:00`).map(time => (
                        <option key={time} value={time}>{formatTime(time)}</option>
                        ))}
                    </Select>
                    </FormControl>
                </>
                )}

                <FormControl>
                <FormLabel>Notes</FormLabel>
                <Textarea
                    value={editSession?.temporaryNotes || ''}
                    onChange={(e) => setEditSession({
                    ...editSession,
                    temporaryNotes: e.target.value
                    })}
                    placeholder="Add any notes about the change"
                />
                </FormControl>
            </VStack>
            </ModalBody>
            <ModalFooter>
            <Button
                colorScheme="blue"
                mr={3}
                onClick={handleSaveEdit}
            >
                Save Changes
            </Button>
            <Button variant="ghost" onClick={() => setIsEditModalOpen(false)}>
                Cancel
            </Button>
            </ModalFooter>
        </ModalContent>
      </Modal>

      <SessionSummaryModal 
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={handleConfirmAttendance}
      />

      <SuccessModal 
        isOpen={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        currentDate={selectedDate}
        nextDate={nextSessionDate}
      />
      <BulkCancelModal 
        isOpen={isBulkCancelModalOpen} 
        onClose={() => setIsBulkCancelModalOpen(false)}
        onConfirm={handleBulkCancel}
        selectedDate={selectedDate}
      />
    </Box>
  );
};

export default CurrentDayDeck;