import React, { useState, useEffect } from 'react';
import { useToast, Box, HStack, VStack, Select, Center, Spinner, Card, CardBody, Text, IconButton, Collapse, Table, Thead, Tr, Th, Td, Tbody, Badge, Button, StatLabel, Stat, StatNumber } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import supabase from '../supabaseClient';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

const PayrollManagement = () => {
    const [payrollData, setPayrollData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState(() => {
        const today = new Date();
        return getInitialPeriod().month;
    });
    const [selectedYear, setSelectedYear] = useState(() => {
        const today = new Date();
        return getInitialPeriod().year;
    });
    const [biMonthlyPeriod, setBiMonthlyPeriod] = useState(() => {
        return getInitialPeriod().period;
    });
    const [expandedTherapist, setExpandedTherapist] = useState(null);
    const [totalCommissions, setTotalCommissions] = useState(0);
    const toast = useToast();

    // Add this helper function inside the component
    function getInitialPeriod() {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth();
        const currentDate = today.getDate();
        
        // For first half of month (1-12)
        if (currentDate >= 1 && currentDate <= 15) {
            // If it's 13-15, we're still in payout period for previous cycle (27-12)
            if (currentDate >= 13 && currentDate <= 15) {
                // If it's January, we need to go back to December
                if (currentMonth === 0) {
                    return {
                        month: 11, // December
                        year: currentYear - 1,
                        period: 'second' // 27-12 period
                    };
                }
                return {
                    month: currentMonth - 1,
                    year: currentYear,
                    period: 'second' // 27-12 period
                };
            }
            return {
                month: currentMonth - 1,
                year: currentYear,
                period: 'second' // 27-12 period
            };
        }
        
        // For dates 16-26, we're in the first period (13-26)
        if (currentDate >= 16 && currentDate <= 26) {
            return {
                month: currentMonth,
                year: currentYear,
                period: 'first' // 13-26 period
            };
        }
        
        // For dates 27-end of month
        if (currentDate >= 27) {
            // If it's the last days (27-30/31) and within payout period
            const lastDayForPayout = getPayoutEndDate(currentYear, currentMonth, 'first');
            if (today <= lastDayForPayout) {
                return {
                    month: currentMonth,
                    year: currentYear,
                    period: 'first' // Still in 13-26 period
                };
            }
            
            // After payout date, move to next period (27-12)
            return {
                month: currentMonth,
                year: currentYear,
                period: 'second' // 27-12 period
            };
        }

        // Default case (should not reach here, but just in case)
        return {
            month: currentMonth,
            year: currentYear,
            period: 'first'
        };
    }

    // Helper function to get the payout end date for a period
    function getPayoutEndDate(year, month, period) {
        if (period === 'first') { // 13-26 period
            // Payout is until the 30th
            return new Date(year, month, 30);
        } else { // 27-12 period
            // Payout is until the 15th of next month
            return new Date(year, month + 1, 15);
        }
    }
  
    useEffect(() => {
      console.log('Bi Monthly Period: ', biMonthlyPeriod);
      fetchPayrollData();
    }, [selectedMonth, selectedYear, biMonthlyPeriod]);

    const markAsPaid = async (therapistData) => {
        try {
          let startDate, endDate;
          if (biMonthlyPeriod === 'first') {
            startDate = new Date(selectedYear, selectedMonth, 13);
            endDate = new Date(selectedYear, selectedMonth, 26);
          } else {
            startDate = new Date(selectedYear, selectedMonth, 27);
            endDate = new Date(selectedYear, selectedMonth + 1, 12);
          }
      
          const formatToLocalDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
          };
      
          const { error } = await supabase
            .from('payroll_payments')
            .insert([{
              therapist_id: therapistData.therapist.id,
              start_date: formatToLocalDate(startDate),
              end_date: formatToLocalDate(endDate),
              total_amount: therapistData.summary.totalAmount
            }]);
      
          if (error) throw error;

          if (therapistData.shouldShowBenefits) {
            const benefitsDate = new Date(selectedYear, selectedMonth, 26);
            const { error } = await supabase
                .from('expenses')
                .insert([{
                    purchase_date: formatToLocalDate(benefitsDate),
                    expense_name: `${therapistData.therapist.name} (Gov & Education)`,
                    expense_type: 'Benefits',
                    amount: 2500,
                    receipt_type: 'None',
                    notes: ''
                }]);
        
            if (error) throw error;
          }
      
          toast({
            title: 'Marked as paid',
            description: `Payroll for ${therapistData.therapist.name} has been marked as paid.`,
            status: 'success',
            duration: 3000
          });
      
          // Refresh data
          fetchPayrollData();
        } catch (error) {
          toast({
            title: 'Error marking as paid',
            description: error.message,
            status: 'error',
            duration: 5000
          });
        }
    };

    const shouldIncludeBenefits = () => {
        // Only include benefits for the 13-26 period
        return biMonthlyPeriod === 'first';
    };
  
    const fetchPayrollData = async () => {
        try {
          setIsLoading(true);
          let startDate, endDate;
      
          if (biMonthlyPeriod === 'first') {
            startDate = new Date(selectedYear, selectedMonth, 13);
            endDate = new Date(selectedYear, selectedMonth, 26);
          } else {
            startDate = new Date(selectedYear, selectedMonth, 27);
            endDate = new Date(selectedYear, selectedMonth + 1, 12);
          }
      
          const formatToLocalDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
          };
      
          const startDateStr = formatToLocalDate(startDate);
          const endDateStr = formatToLocalDate(endDate);
      
          const { data: therapists, error: therapistsError } = await supabase
            .from('therapists')
            .select('*')
            .eq('status', 'active');
      
          if (therapistsError) throw therapistsError;
      
          // Get payment status for all therapists for this period
          const { data: payrollPayments, error: paymentsError } = await supabase
            .from('payroll_payments')
            .select('*')
            .in('therapist_id', therapists.map(t => t.id))
            .eq('start_date', startDateStr)
            .eq('end_date', endDateStr);
      
          if (paymentsError) throw paymentsError;

          let totalCommissions = 0;
      
          const payrollDetails = await Promise.all(
            therapists.map(async (therapist) => {

              const { isEligible, totalHours } = await checkBenefitsEligibility(
                therapist.id,
                selectedMonth + 1,
                selectedYear
              );
              // Get valid service fee for this period
              const { data: serviceFee } = await supabase
                .from('service_fees')
                .select('amount')
                .eq('therapist_id', therapist.id)
                .lte('valid_from', endDateStr)
                .or(`valid_until.is.null,valid_until.gte.${startDateStr}`)
                .order('valid_from', { ascending: false })
                .limit(1)
                .single();
      
              const { data: sessions, error: sessionsError } = await supabase
                .from('session_attendance')
                .select(`
                  *,
                  session:sessions (
                    client:clients (
                      name
                    )
                  )
                `)
                .eq('therapist_id', therapist.id)
                .eq('status', 'present')
                .gte('attendance_date', startDateStr)
                .lte('attendance_date', endDateStr);
      
              if (sessionsError) throw sessionsError;
      
              // Calculate summary
              const summary = sessions.reduce((acc, session) => {
                acc.sessionTypes[session.session_type] = (acc.sessionTypes[session.session_type] || 0) + 1;
                const sessionAmount = session.session_type === 'IE' ? 2200 : (serviceFee?.amount || 0);
                acc.totalAmount += sessionAmount;
                return acc;
              }, { sessionTypes: {}, totalAmount: 0 });

              // Check if this period is already paid
              const isPaid = payrollPayments?.some(payment => 
                payment.therapist_id === therapist.id
              );
      
              const shouldAddBenefits = isEligible && shouldIncludeBenefits();
              const totalWithBenefits = shouldAddBenefits ? 
                  summary.totalAmount + 2500 : 
                  summary.totalAmount;
              
              totalCommissions += totalWithBenefits;
      
              return {
                therapist,
                sessions: sessions.map(session => ({
                    ...session,
                    service_fee: session.session_type === 'IE' ? 2200 : (serviceFee?.amount || 0)
                })),
                summary,
                isPaid,
                isEligibleForBenefits: isEligible,
                shouldShowBenefits: isEligible && biMonthlyPeriod === 'first',
                totalHours,
                totalWithBenefits
              };
            })
          );
      
          setPayrollData(payrollDetails);
          setTotalCommissions(totalCommissions);
        } catch (error) {
          toast({
            title: 'Error fetching payroll data',
            description: error.message,
            status: 'error',
            duration: 5000
          });
        } finally {
          setIsLoading(false);
        }
    };
  
    const formatSessionTypeSummary = (sessionTypes) => {
      return Object.entries(sessionTypes)
        .map(([type, count]) => `${count} ${type}`)
        .join(' | ');
    };
  
    // Special function for PDF currency formatting
    const formatCurrencyPDF = (amount) => {
        //if (amount === undefined || amount === null) return 'PHP 0.00';
        return `PHP ${Number(amount).toLocaleString('en-PH', { minimumFractionDigits: 2 })}`;
    };

    // Keep the original formatCurrency for web display
    const formatCurrency = (amount) => {
        //if (amount === undefined || amount === null) return '₱0.00';
        return `₱${Number(amount).toLocaleString('en-PH', { minimumFractionDigits: 2 })}`;
    };
      
    const checkBenefitsEligibility = async (therapistId, month, year) => {
        try {
          let startYear = year;
          let startMonth = month - 1;
          
          // Handle December to January transition
          if (startMonth === 0) { // January
            startMonth = 12; // December
            startYear = year - 1;
          }
      
          const startDate = new Date(startYear, startMonth - 1, 27); // Previous month's 27th
          const endDate = new Date(year, month - 1, 26);  // Current month's 26th
      
          console.log('Checking benefits from:', startDate, 'to:', endDate);
      
          const { data: sessions, error } = await supabase
            .from('session_attendance')
            .select('*')
            .eq('therapist_id', therapistId)
            .eq('status', 'present')
            .gte('attendance_date', startDate.toISOString().split('T')[0])
            .lte('attendance_date', endDate.toISOString().split('T')[0]);
      
          if (error) throw error;
      
          const totalHours = sessions?.length || 0;
          return { isEligible: totalHours >= 72, totalHours };
        } catch (error) {
          console.error('Error checking benefits eligibility:', error);
          return { isEligible: false, totalHours: 0 };
        }
    };

    const generatePayslipPDF = async (therapistData) => {
        try {
            const doc = new jsPDF({
                unit: 'mm',
                format: 'a4',
                putOnlyUsedFonts: true
            });
    
            const drawHeader = () => {
                const pageWidth = doc.internal.pageSize.width;
                doc.setFillColor(52, 180, 226);
                doc.rect(0, 0, pageWidth, 25, 'F');
                doc.setTextColor(255, 255, 255);
                doc.setFontSize(20);
                doc.text('Tiny Triumphs', pageWidth/2, 12, { align: 'center' });
                doc.setFontSize(14);
                doc.text('PAYSLIP', pageWidth/2, 20, { align: 'center' });
                doc.setTextColor(0, 0, 0);
            };
            
            const pageWidth = doc.internal.pageSize.width;
            const pageHeight = doc.internal.pageSize.height;
            drawHeader();
    
            // Payroll Period and Therapist Info
            doc.setFontSize(12);
            const periodText = `Payroll Period: ${formatBiMonthlyPeriod(selectedYear, selectedMonth, biMonthlyPeriod)}`;
            doc.text(periodText, pageWidth/2, 35, { align: 'center' });
            doc.text(`Therapist: ${therapistData.therapist.name}`, 20, 45);
            doc.text(`Specialization: ${therapistData.therapist.specialization}`, 20, 52);
    
            // Session Details Table
            let tableData = therapistData.sessions.map(session => [
                new Date(session.attendance_date).toLocaleDateString(),
                session.session.client.name,
                session.session_type,
                formatCurrencyPDF(session.service_fee)
            ]);
    
            tableData.push([
                { content: 'Total', colSpan: 3, styles: { fontStyle: 'bold' } },
                { content: formatCurrencyPDF(therapistData.summary.totalAmount), styles: { fontStyle: 'bold' } }
            ]);
    
            // Calculate space needed for summary and benefits
            const summaryHeight = Object.keys(therapistData.summary.sessionTypes).length * 7 + 20; // Base height for summary
            const benefitsHeight = therapistData.shouldShowBenefits ? 60 : 0; // Space for benefits section
            const signatureHeight = 40; // Space for signatures
            const totalExtraHeight = summaryHeight + benefitsHeight + signatureHeight;
    
            // First, render the table with a maximum height
            const maxTableHeight = pageHeight - 80 - totalExtraHeight; // Leave space for header and extra sections
    
            doc.autoTable({
                startY: 60,
                head: [['Date', 'Client', 'Session Type', 'Service Fee']],
                body: tableData,
                theme: 'grid',
                headStyles: { fillColor: [52, 180, 226], textColor: [255, 255, 255] },
                styles: { fontSize: 10 },
                alternateRowStyles: { fillColor: [240, 240, 240] },
                willDrawPage: drawHeader,
                margin: { bottom: totalExtraHeight } // Reserve space at bottom
            });
    
            const finalY = doc.previousAutoTable.finalY;
    
            // If we're close to the bottom of the page, add a new page
            if (finalY > pageHeight - totalExtraHeight) {
                doc.addPage();
                drawHeader();
                doc.previousAutoTable.finalY = 60; // Reset finalY to top of new page
            }
    
            // Now add the summary section
            const summaryY = doc.previousAutoTable.finalY + 15;
            doc.text('Summary:', 20, summaryY);
            Object.entries(therapistData.summary.sessionTypes).forEach(([type, count], index) => {
                const text = type === 'Regular' ? `${count} ${type} Sessions` : `${count} ${type}`;
                doc.text(text, 30, summaryY + 10 + (index * 7));
            });
    
            // Benefits section
            let currentY = summaryY + summaryHeight;
            if (therapistData.shouldShowBenefits) {
                doc.text('Monthly Benefits:', 20, currentY);
                doc.text('Government Contributions Allowance: PHP 2,000.00', 30, currentY + 7);
                doc.text('Educational Subsidy: PHP 500.00', 30, currentY + 14);
                doc.text(`Total Amount (with benefits): ${formatCurrencyPDF(therapistData.totalWithBenefits)}`, 20, currentY + 28);
                currentY += benefitsHeight;
            }
    
            // Signatures section
            try {
                const signatureResponse = await fetch('roberto-signature.png');
                if (!signatureResponse.ok) throw new Error('Failed to load signature');
                
                const signatureBlob = await signatureResponse.blob();
                const signatureBase64 = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(signatureBlob);
                });
    
                doc.addImage(signatureBase64, 'PNG', pageWidth - 80, currentY + 2, 60, 15);
            } catch (signatureError) {
                console.error('Signature error:', signatureError);
            }
    
            doc.setFontSize(10);
            doc.text('Received by:', 20, currentY);
            doc.line(20, currentY + 15, 80, currentY + 15);
            doc.text(therapistData.therapist.name, 20, currentY + 20);
    
            doc.text('Prepared by:', pageWidth - 80, currentY);
            doc.line(pageWidth - 80, currentY + 15, pageWidth - 20, currentY + 15);
            doc.text('Roberto A. Domingo', pageWidth - 80, currentY + 20);
            doc.text('Administrative Head', pageWidth - 80, currentY + 25);
    
            // Save PDF
            const monthName = new Date(selectedYear, selectedMonth).toLocaleString('default', { month: 'short' });
            const periodRange = biMonthlyPeriod === 'first' ? '13-26' : '27-12';
            doc.save(`${therapistData.therapist.name}-Payslip-${monthName}-${periodRange}-${selectedYear}.pdf`);
    
        } catch (error) {
            console.error('PDF Generation Error:', error);
            toast({
                title: 'Error generating PDF',
                description: error.message,
                status: 'error',
                duration: 5000
            });
        }
    };
  
    const formatBiMonthlyPeriod = (year, month, period) => {
      if (period === 'first') {
        return `${new Date(year, month, 13).toLocaleDateString('en-US', { 
          month: 'short', 
          day: 'numeric' 
        })} - ${new Date(year, month, 26).toLocaleDateString('en-US', { 
          month: 'short', 
          day: 'numeric'
        })}`;
      } else {
        const startDate = new Date(year, month, 27);
        const endDate = new Date(year, month + 1, 12);
        return `${startDate.toLocaleDateString('en-US', { 
          month: 'short', 
          day: 'numeric' 
        })} - ${endDate.toLocaleDateString('en-US', { 
          month: 'short', 
          day: 'numeric'
        })}`;
      }
    };
  
    return (
      <Box>
        <HStack spacing={4} mb={6}>
          <Select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
            w="200px"
          >
            {Array.from({ length: 12 }, (_, i) => (
              <option key={i} value={i}>
                {new Date(2024, i, 1).toLocaleString('default', { month: 'long' })}
              </option>
            ))}
          </Select>
  
          <Select
            value={selectedYear}
            onChange={(e) => setSelectedYear(parseInt(e.target.value))}
            w="150px"
          >
            {Array.from({ length: 5 }, (_, i) => (
              <option key={i} value={new Date().getFullYear() - i}>
                {new Date().getFullYear() - i}
              </option>
            ))}
          </Select>
  
          <Select
            value={biMonthlyPeriod}
            onChange={(e) => setBiMonthlyPeriod(e.target.value)}
            w="200px"
          >
            <option value="first">
              {formatBiMonthlyPeriod(selectedYear, selectedMonth, 'first')}
            </option>
            <option value="second">
              {formatBiMonthlyPeriod(selectedYear, selectedMonth, 'second')}
            </option>
          </Select>
          <Stat>
                <StatLabel>Total Commissions</StatLabel>
                <StatNumber>{formatCurrency(totalCommissions)}</StatNumber>
          </Stat>
        </HStack>
  
        {isLoading ? (
          <Center py={8}>
            <Spinner />
          </Center>
        ) : (
          <VStack spacing={4} align="stretch">
            {payrollData.map((data) => (
              <Box key={data.therapist.id}>
                <Card>
                  <CardBody>
                    <HStack justify="space-between" onClick={() => 
                      setExpandedTherapist(
                        expandedTherapist === data.therapist.id ? null : data.therapist.id
                      )
                    } cursor="pointer">
                      <VStack align="start" spacing={1}>
                        <HStack align="center">
                            <Text fontWeight="bold">{data.therapist.name}</Text>
                            <Badge colorScheme={data.isPaid ? 'green' : 'yellow'}>
                            {data.isPaid ? 'Paid' : 'Pending'}
                            </Badge>
                        </HStack>
                        <Text fontSize="sm" color="gray.600">
                          {formatSessionTypeSummary(data.summary.sessionTypes)}
                        </Text>
                      </VStack>
                      <HStack>
                        <Text fontWeight="bold">{formatCurrency(data.totalWithBenefits)}</Text>
                        <IconButton
                          icon={<DownloadIcon />}
                          aria-label="Download Payslip"
                          onClick={(e) => {
                            e.stopPropagation();
                            generatePayslipPDF(data);
                          }}
                        />
                        {!data.isPaid && (
                            <Button
                                size="sm"
                                colorScheme="green"
                                onClick={(e) => {
                                e.stopPropagation();
                                markAsPaid(data);
                                }}
                            >
                                Mark as Paid
                            </Button>
                        )}
                      </HStack>
                    </HStack>
  
                    <Collapse in={expandedTherapist === data.therapist.id}>
                    <Box mt={4}>
                        <Table size="sm">
                        <Thead>
                            <Tr>
                            <Th>Date</Th>
                            <Th>Client</Th>
                            <Th>Session Type</Th>
                            <Th isNumeric>Service Fee</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.sessions.map((session) => (
                            <Tr key={session.id}>
                                <Td>{new Date(session.attendance_date).toLocaleDateString()}</Td>
                                <Td>{session.session.client.name}</Td>
                                <Td>{session.session_type}</Td>
                                <Td isNumeric>{formatCurrency(session.service_fee)}</Td>
                            </Tr>
                            ))}
                            <Tr fontWeight="bold">
                                <Td colSpan={3} textAlign="right">Total:</Td>
                                <Td isNumeric>{formatCurrency(data.summary.totalAmount)}</Td>
                            </Tr>
                            {data.shouldShowBenefits && (
                            <>
                                <Tr>
                                <Td colSpan={3} textAlign="right">Government Contributions Allowance:</Td>
                                <Td isNumeric>{formatCurrency(2000)}</Td>
                                </Tr>
                                <Tr>
                                <Td colSpan={3} textAlign="right">Educational Subsidy:</Td>
                                <Td isNumeric>{formatCurrency(500)}</Td>
                                </Tr>
                                <Tr fontWeight="bold">
                                <Td colSpan={3} textAlign="right">Total with Benefits:</Td>
                                <Td isNumeric>{formatCurrency(data.summary.totalAmount + 2500)}</Td>
                                </Tr>
                            </>
                            )}
                        </Tbody>
                        </Table>
                        {data.shouldShowBenefits && (
                            <Text fontSize="sm" color="green.500" mt={2}>
                                {(data.shouldShowBenefits && biMonthlyPeriod === 'first') ? 
                                    `Eligible for benefits (${data.totalHours} hours rendered)` :
                                    `${data.totalHours} hours rendered - Benefits will be included in 13-26 period payslip`
                                }
                            </Text>
                        )}
                    </Box>
                    </Collapse>
                  </CardBody>
                </Card>
              </Box>
            ))}
          </VStack>
        )}
      </Box>
    );
  };

  export default PayrollManagement;