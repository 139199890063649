import React from 'react';
import { 
  Box, 
  Container, 
  Tab, 
  TabList, 
  TabPanel, 
  TabPanels, 
  Tabs,
  Spinner,
  Center,
  VStack,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useMediaQuery,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { CalendarIcon, ViewIcon, TimeIcon } from '@chakra-ui/icons';
import { useAuth } from '../context/AuthContext';
import CurrentDayDeck from '../components/CurrentDayDeck';
import WeeklySchedule from '../components/WeeklySchedule';
import MainDeck from '../components/MainDeck';
import TherapistFinance from '../components/TherapistFinance';
import { BiMoney } from 'react-icons/bi';

const LoadingOverlay = () => (
  <Center height="100%" width="100%">
    <VStack spacing={4}>
      <Spinner 
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
      <Text color="gray.500">Loading...</Text>
    </VStack>
  </Center>
);

const BottomNavItem = ({ icon, label, isSelected, onClick }) => (
  <VStack
    as="button"
    spacing={1}
    flex={1}
    py={2}
    px={4}
    onClick={onClick}
    color={isSelected ? "blue.500" : "gray.600"}
    bg={isSelected ? "blue.50" : "transparent"}
    transition="all 0.2s"
    _hover={{
      color: "blue.500",
      bg: "blue.50",
    }}
  >
    <Icon as={icon} boxSize={5} />
    <Text fontSize="xs" fontWeight={isSelected ? "medium" : "normal"}>
      {label}
    </Text>
  </VStack>
);

const TherapistDashboard = () => {
  const { profile, loading } = useAuth();
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  if (loading) {
    return <LoadingOverlay />;
  }

  if (!profile || profile.role !== 'therapist') {
    return (
      <Container maxW="container.md" py={8}>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Access Denied</AlertTitle>
          <AlertDescription>
            You must be logged in as a therapist to view this dashboard.
          </AlertDescription>
        </Alert>
      </Container>
    );
  }

  const handleTabChange = (index) => {
    setSelectedTabIndex(index);
  };

  const navigationItems = [
    { icon: TimeIcon, label: "Today", index: 0 },
    { icon: CalendarIcon, label: "Main Deck", index: 1 },
    { icon: BiMoney, label: "Finance", index: 2 },
  ];

  return (
    <Container maxW="1200px" py={8} position="relative">
      <Tabs 
        isFitted 
        variant="enclosed" 
        index={selectedTabIndex} 
        onChange={handleTabChange}
      >
        {/* Hide TabList on mobile */}
        <TabList mb="1em" display={{ base: "none", md: "flex" }}>
          <Tab>Current Day Deck</Tab>
          <Tab>Main Deck</Tab>
          <Tab>Finance</Tab>
        </TabList>

        <TabPanels>
          <TabPanel position="relative" minHeight="500px">
            <CurrentDayDeck 
              key={`current-day-deck-${selectedTabIndex}`}
              therapistId={profile.id}
            />
          </TabPanel>
          <TabPanel position="relative" minHeight="500px">
            <MainDeck 
              key={`weekly-schedule-${selectedTabIndex}`}
              LoadingComponent={LoadingOverlay}
              therapistId={profile.id}
              isTherapistView={true}
            />
          </TabPanel>
          <TabPanel position="relative" minHeight="500px">
            <TherapistFinance
              key={`finance-tab-${selectedTabIndex}`} 
              therapistId={profile.id} 
            />
          </TabPanel>
        </TabPanels>

        {/* Bottom Navigation for Mobile */}
        <Box
          display={{ base: "block", md: "none" }}
          position="fixed"
          bottom="0"
          left="0"
          right="0"
          bg="white"
          borderTop="1px"
          borderColor="gray.200"
          boxShadow="0 -2px 10px rgba(0, 0, 0, 0.05)"
          zIndex="docked"
        >
          <Flex justify="space-around" align="center">
            {navigationItems.map((item) => (
              <BottomNavItem
                key={item.index}
                icon={item.icon}
                label={item.label}
                isSelected={selectedTabIndex === item.index}
                onClick={() => handleTabChange(item.index)}
              />
            ))}
          </Flex>
        </Box>

        {/* Add bottom padding on mobile to account for navigation bar */}
        <Box display={{ base: "block", md: "none" }} h="80px" />
      </Tabs>
    </Container>
  );
};

export default TherapistDashboard;