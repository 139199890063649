// src/components/MainDeck.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Text,
  VStack,
  HStack,
  Button,
  Badge,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Select,
  IconButton,
  ModalFooter,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Tooltip,
  Input,
  Textarea,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tag,
  Link,
} from '@chakra-ui/react';
import { EditIcon, AddIcon, TimeIcon, CalendarIcon, DeleteIcon } from '@chakra-ui/icons';
import supabase from '../supabaseClient';
import { useAuth } from '../context/AuthContext';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const LeaveModal = React.memo(({ isOpen, onClose, onSubmit, editData }) => {
  const [formData, setFormData] = useState({
    startDate: '',
    endDate: '',
    purpose: ''
  });

  useEffect(() => {
    if (isOpen && editData) {
      setFormData({
        startDate: editData.start_date,
        endDate: editData.end_date,
        purpose: editData.purpose
      });
    } else if (isOpen) {
      setFormData({
        startDate: '',
        endDate: '',
        purpose: ''
      });
    }
  }, [isOpen, editData]);

  const handleSubmit = () => {
    if (editData) {
      onSubmit(editData.id, formData, editData.start_date, editData.end_date);
    } else {
      onSubmit(formData);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
      <ModalHeader>{editData ? 'Edit Leave' : 'Schedule Leave'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>From</FormLabel>
              <Input
                type="date"
                value={formData.startDate}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  startDate: e.target.value
                }))}
                min={new Date().toISOString().split('T')[0]}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>To</FormLabel>
              <Input
                type="date"
                value={formData.endDate}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  endDate: e.target.value
                }))}
                min={formData.startDate || new Date().toISOString().split('T')[0]}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Purpose</FormLabel>
              <Textarea
                value={formData.purpose}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  purpose: e.target.value
                }))}
                placeholder="Enter reason for leave"
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSubmit}
            isDisabled={!formData.startDate || !formData.endDate || !formData.purpose}
          >
            Schedule
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

const DraggableSession = ({ session, isDyad }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'SESSION',
    item: { session },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  return (
    <Box
      ref={drag}
      key={session.id}
      p={2}
      bg={session.session_type === 'Regular' ? 'blue.100' : 'green.100'}
      borderRadius="md"
      flex="1"
      height="100%"
      opacity={isDragging ? 0.5 : 1}
      cursor="move"
    >
      <Text fontSize="xs" fontWeight="bold" noOfLines={1}>
        {session.client_name}
      </Text>
      <Badge fontSize="2xs" mb={1}>
        {isDyad ? 'Dyad' : session.session_type}
      </Badge>
    </Box>
  );
};

const DroppableTimeSlot = ({ day, time, sessions, hasTimeSlot, onDrop, onClick }) => {
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'SESSION',
    canDrop: () => {
        // A slot is droppable if it's a scheduled time slot AND it's empty
        return hasTimeSlot && sessions.length === 0;
    },
    drop: (item) => {
        onDrop(item.session, day, time)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }), [hasTimeSlot, sessions.length, day, time]); // Add dependencies here

  return (
    <Box
      ref={drop}
      key={`${day}-${time}`}
      height="58px"
      p={1}
      bg={isOver ? "blue.50" : hasTimeSlot ? "white" : "gray.100"}
      borderRadius="md"
      border="1px"
      borderColor={hasTimeSlot ? "gray.200" : "transparent"}
      position="relative"
      _hover={{ bg: hasTimeSlot ? 'gray.50' : 'gray.100', cursor: hasTimeSlot ? 'pointer' : 'default' }}
      onClick={() => hasTimeSlot && onClick(day, time)}
    >
      {sessions.length > 0 && (
        <HStack spacing={1} height="100%" width="100%">
          {sessions.map((sess) => (
            <DraggableSession 
              key={sess.id}
              session={sess}
              isDyad={sessions.length > 1}
            />
          ))}
        </HStack>
      )}
    </Box>
  );
};

const MainDeck = () => {
  const [schedule, setSchedule] = useState({});
  const [sessions, setSessions] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [isNewDay, setIsNewDay] = useState(false);
  const [scheduleAction, setScheduleAction] = useState(''); // 'add' or 'remove'
  const [existingSession, setExistingSession] = useState(null);
  const [moveDay, setMoveDay] = useState('');
  const [moveTime, setMoveTime] = useState('');
  const [isMoving, setIsMoving] = useState(false);
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const [leaves, setLeaves] = useState([]);
  const [nextLeave, setNextLeave] = useState(null);
  const [isLeavesDrawerOpen, setIsLeavesDrawerOpen] = useState(false);
  const [editLeave, setEditLeave] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { profile } = useAuth();
  const toast = useToast();

  const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const DAYS_ORDER = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];

  // Fetch initial data
  useEffect(() => {
    if (profile?.id) {
      fetchTherapistSchedule();
      fetchSessions();
      fetchRegularClients();
    }
  }, [profile?.id]);


  useEffect(() => {
    if (profile?.id) {
      fetchLeaves();
    }
  }, [profile?.id]);

// Add this function to your component
const handleDrop = async (session, newDay, newTime) => {
    try {
      const currentDate = new Date();
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowStr = tomorrow.toISOString().split('T')[0];
  
      // End current session
      const { error: updateError } = await supabase
        .from('sessions')
        .update({ valid_until: currentDate.toISOString().split('T')[0] })
        .eq('id', session.id);
  
      if (updateError) throw updateError;
  
      // Create new session at new time/day
      const { error: insertError } = await supabase
        .from('sessions')
        .insert([{
          therapist_id: profile.id,
          client_id: session.client_id,
          client_name: session.client_name,
          day: newDay,
          time: newTime,
          session_type: session.session_type,
          valid_from: tomorrowStr,
          valid_until: null
        }]);
  
      if (insertError) throw insertError;
  
      await fetchSessions();
  
      toast({
        title: 'Session Moved',
        description: `Session moved to ${newDay} at ${formatTime(newTime)} starting tomorrow`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error moving session:', error);
      toast({
        title: 'Error',
        description: 'Failed to move session',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchTherapistSchedule = async () => {
    try {
      const { data, error } = await supabase
        .from('therapists')
        .select('schedule')
        .eq('id', profile.id)
        .single();

      if (error) throw error;
      setSchedule(data.schedule || {});
    } catch (error) {
      console.error('Error fetching schedule:', error);
      toast({
        title: 'Error fetching schedule',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchRegularClients = async () => {
    try {
      // Fetch only regular clients (those who have had regular sessions with this therapist)
      const { data, error } = await supabase
        .from('sessions')
        .select(`
          client_id,
          client:clients (
            id,
            name,
            nickname,
            diagnosis,
            pwd
          )
        `)
        .eq('therapist_id', profile.id)
        .eq('session_type', 'Regular')
        .is('valid_until', null); // Changed from eq to is for handling null values
  
      if (error) throw error;
  
      // Remove duplicates and filter out any null clients
      const uniqueClients = [...new Map(
        data
          .filter(item => item.client) // Filter out any null clients
          .map(item => [item.client_id, item.client])
      ).values()];
  
      setClients(uniqueClients);
    } catch (error) {
      console.error('Error fetching clients:', error);
      toast({
        title: 'Error fetching clients',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Add this new helper function at the beginning of your component:
  const getDyadSessionsForSlot = (day, time) => {
    return sessions.filter(session => 
        session.day === day && 
        session.time === time
    );
  };

  const fetchSessions = async () => {
    try {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowStr = tomorrow.toISOString().split('T')[0];
      
      const { data, error } = await supabase
        .from('sessions')
        .select('*')
        .eq('therapist_id', profile.id)
        .or(`and(valid_from.gte.${tomorrowStr},valid_until.is.null),and(valid_from.lte.${tomorrowStr},or(valid_until.is.null,valid_until.gt.${tomorrowStr}))`)
        .order('valid_from', { ascending: true });
  
      if (error) throw error;
  
      console.log('Sessions: ', data);
      
      // Modified process to handle multiple sessions per time slot
      const latestSessions = data.reduce((acc, session) => {
        const timeSlotKey = `${session.day}-${session.time}`;
        
        if (!acc[timeSlotKey]) {
          // Initialize array for this time slot
          acc[timeSlotKey] = [];
        }
  
        // Check if we already have a session for this client in this time slot
        const existingClientSession = acc[timeSlotKey].find(
          s => s.client_id === session.client_id
        );
  
        if (existingClientSession) {
          // If we have an existing session for this client, update it if the new one is more recent
          if (new Date(session.valid_from) > new Date(existingClientSession.valid_from)) {
            acc[timeSlotKey] = acc[timeSlotKey].map(s =>
              s.client_id === session.client_id ? session : s
            );
          }
        } else {
          // If no existing session for this client, add it to the array
          acc[timeSlotKey].push(session);
        }
  
        return acc;
      }, {});
  
      console.log('Latest Sessions by Time Slot: ', latestSessions);
  
      // Flatten the sessions arrays into a single array
      const allSessions = Object.values(latestSessions).flat();
      console.log('All Sessions Flattened: ', allSessions);
  
      setSessions(allSessions);
    } catch (error) {
      console.error('Error fetching sessions:', error);
      toast({
        title: 'Error fetching sessions',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const LeavesDrawer = ({ isOpen, onClose, leaves, onEdit, onDelete }) => (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Scheduled Leaves</DrawerHeader>
        <DrawerBody>
          <VStack spacing={4} align="stretch">
            {leaves.map(leave => (
              <Box
                key={leave.id}
                p={4}
                borderWidth="1px"
                borderRadius="md"
                position="relative"
              >
                <HStack justify="space-between">
                  <VStack align="start" spacing={1}>
                    <Text fontWeight="bold">
                      {new Date(leave.start_date).toLocaleDateString()} - {new Date(leave.end_date).toLocaleDateString()}
                    </Text>
                    <Text color="gray.600">{leave.purpose}</Text>
                  </VStack>
                  <HStack>
                    <IconButton
                      icon={<EditIcon />}
                      size="sm"
                      onClick={() => onEdit(leave)}
                      aria-label="Edit leave"
                    />
                    <IconButton
                      icon={<DeleteIcon />}
                      size="sm"
                      colorScheme="red"
                      onClick={() => onDelete(leave.id, leave.start_date, leave.end_date)}
                      aria-label="Delete leave"
                    />
                  </HStack>
                </HStack>
              </Box>
            ))}
            {leaves.length === 0 && (
              <Text color="gray.500" textAlign="center">No scheduled leaves</Text>
            )}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );

  const fetchLeaves = async () => {
    try {
      const today = new Date().toISOString().split('T')[0];
      
      // Fetch all leaves
      const { data, error } = await supabase
        .from('leaves')
        .select('*')
        .eq('therapist_id', profile.id)
        .order('start_date', { ascending: true });
  
      if (error) throw error;
  
      setLeaves(data);
  
      // Find next upcoming leave
      const upcomingLeave = data.find(leave => leave.start_date >= today);
      setNextLeave(upcomingLeave);
    } catch (error) {
      console.error('Error fetching leaves:', error);
    }
  };
  
  // Add function to handle leave deletion
  const handleDeleteLeave = async (leaveId, startDate, endDate) => {
    try {
      const confirmDelete = window.confirm('Are you sure you want to delete this leave? This will also reopen any cancelled sessions.');
      if (!confirmDelete) return;
  
      // Delete leave record
      const { error: leaveError } = await supabase
        .from('leaves')
        .delete()
        .eq('id', leaveId);
  
      if (leaveError) throw leaveError;
  
      // Delete related session_attendance records
      const { error: attendanceError } = await supabase
        .from('session_attendance')
        .delete()
        .eq('therapist_id', profile.id)
        .gte('attendance_date', startDate)
        .lte('attendance_date', endDate)
        .eq('status', 'cancelled')
        .like('notes', '%Cancelled due to therapist leave%');
  
      if (attendanceError) throw attendanceError;
  
      // Remove deck confirmations for the leave period
      await handleDeckConfirmationsForLeave(startDate, endDate, false);
  
      // Refresh data
      await Promise.all([
        fetchSessions(),
        fetchLeaves()
      ]);
  
      toast({
        title: 'Leave Deleted',
        description: 'Leave has been deleted and affected sessions have been reopened.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting leave:', error);
      toast({
        title: 'Error',
        description: 'Failed to delete leave',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUpdateLeave = async (leaveId, newData, oldStartDate, oldEndDate) => {
    try {
      const { startDate, endDate, purpose } = newData;
      
      // Get affected sessions for new date range
      const newAffectedSessions = await getSessionsInDateRange(startDate, endDate);
      
      // Update leave record
      const { error: leaveError } = await supabase
        .from('leaves')
        .update({
          start_date: startDate,
          end_date: endDate,
          purpose
        })
        .eq('id', leaveId);
  
      if (leaveError) throw leaveError;
  
      // Delete old attendance records
      await supabase
        .from('session_attendance')
        .delete()
        .eq('therapist_id', profile.id)
        .gte('attendance_date', oldStartDate)
        .lte('attendance_date', oldEndDate)
        .eq('status', 'cancelled')
        .like('notes', '%Cancelled due to therapist leave%');
  
      // Create new attendance records
      const attendanceRecords = newAffectedSessions.map(session => ({
        session_id: session.id,
        client_id: session.client_id,
        therapist_id: profile.id,
        attendance_date: session.date,
        status: 'cancelled',
        notes: `Cancelled due to therapist leave: ${purpose}`,
        base_rate: 0,
        final_amount: 0
      }));
  
      if (attendanceRecords.length > 0) {
        const { error: attendanceError } = await supabase
          .from('session_attendance')
          .insert(attendanceRecords);
  
        if (attendanceError) throw attendanceError;
      }
  
      // Remove old deck confirmations and add new ones
      await handleDeckConfirmationsForLeave(oldStartDate, oldEndDate, false);
      await handleDeckConfirmationsForLeave(startDate, endDate, true);
  
      setIsLeaveModalOpen(false);
      setEditLeave(null);
  
      // Refresh data
      await Promise.all([
        fetchSessions(),
        fetchLeaves()
      ]);
  
      toast({
        title: 'Leave Updated',
        description: `Successfully updated leave and affected sessions`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating leave:', error);
      toast({
        title: 'Error',
        description: 'Failed to update leave',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeckConfirmationsForLeave = async (startDate, endDate, shouldAdd = true) => {
    try {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const dates = [];
      
      // Generate array of dates
      for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
        const dateStr = date.toISOString().split('T')[0];
        dates.push(dateStr);
      }
  
      if (shouldAdd) {
        // Add deck confirmations for all affected dates
        const confirmations = dates.map(date => ({
          therapist_id: profile.id,
          date: date,
          confirmed_at: new Date().toISOString(),
          auto_confirmed: true // Add this flag to identify auto-confirmations
        }));
  
        const { error } = await supabase
          .from('deck_confirmations')
          .upsert(confirmations, { 
            onConflict: 'therapist_id,date',
            ignoreDuplicates: true 
          });
  
        if (error) throw error;
      } else {
        // Remove deck confirmations for dates that were auto-confirmed
        const { error } = await supabase
          .from('deck_confirmations')
          .delete()
          .eq('therapist_id', profile.id)
          .eq('auto_confirmed', true)
          .in('date', dates);
  
        if (error) throw error;
      }
    } catch (error) {
      console.error('Error managing deck confirmations:', error);
      throw error;
    }
  };

  const handleLeaveSubmit = async (formData) => {
    try {
      const { startDate, endDate, purpose } = formData;
      
      // Get affected sessions
      const affectedSessions = await getSessionsInDateRange(startDate, endDate);
      
      // Get existing attendance records for the date range
      const { data: existingAttendance, error: attendanceError } = await supabase
        .from('session_attendance')
        .select('session_id, attendance_date')
        .eq('therapist_id', profile.id)
        .gte('attendance_date', startDate)
        .lte('attendance_date', endDate);
  
      if (attendanceError) throw attendanceError;

      console.log('Existing Attendance: ', existingAttendance);
  
      // Create a Set of session_id-date combinations that already have attendance records
      const existingAttendanceSet = new Set(
        existingAttendance.map(record => `${record.session_id}-${record.attendance_date}`)
      );

      console.log('Existing Attendance Set: ', existingAttendanceSet);
  
      // Filter out sessions that already have attendance records
      const sessionsToCancel = affectedSessions.filter(session => 
        !existingAttendanceSet.has(`${session.id}-${session.date}`)
      );

      console.log('Sessions to Cancel: ', sessionsToCancel);
      
      // Show confirmation with affected sessions count
      const confirmCancel = window.confirm(
        `This will cancel ${sessionsToCancel.length} session(s) between ${startDate} and ${endDate}. Continue?`
      );
      
      if (!confirmCancel) return;
  
      // Insert leave record
      const { error: leaveError } = await supabase
        .from('leaves')
        .insert([{
          therapist_id: profile.id,
          start_date: startDate,
          end_date: endDate,
          purpose
        }]);
  
      if (leaveError) throw leaveError;
  
      // Create attendance records only for sessions without existing attendance
      const attendanceRecords = sessionsToCancel.map(session => ({
        session_id: session.id,
        client_id: session.client_id,
        therapist_id: profile.id,
        attendance_date: session.date,
        status: 'cancelled',
        notes: `Cancelled due to therapist leave: ${purpose}`,
        base_rate: 0,
        final_amount: 0
      }));
  
      if (attendanceRecords.length > 0) {
        const { error: attendanceError } = await supabase
          .from('session_attendance')
          .insert(attendanceRecords);
  
        if (attendanceError) throw attendanceError;
      }
  
      // Add deck confirmations for leave period
      await handleDeckConfirmationsForLeave(startDate, endDate, true);
  
      // Close modal
      setIsLeaveModalOpen(false);
      
      // Refresh data
      await Promise.all([
        fetchSessions(),
        fetchLeaves()
      ]);
  
      toast({
        title: 'Leave Scheduled',
        description: `Successfully scheduled leave and cancelled ${attendanceRecords.length} session(s)`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error scheduling leave:', error);
      toast({
        title: 'Error',
        description: 'Failed to schedule leave',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const sortTimes = (times) => {
    return times.sort((a, b) => {
      const hourA = parseInt(a.split(':')[0]);
      const hourB = parseInt(b.split(':')[0]);
      return hourA - hourB;
    });
  };

  const getSessionsInDateRange = async (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const affectedSessions = [];
    
    for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
      const dayOfWeek = DAYS[date.getDay()];
      const dateStr = date.toISOString().split('T')[0];
      
      // Get sessions for this day
      const daySessions = sessions.filter(session => 
        session.day === dayOfWeek &&
        (!session.valid_until || new Date(session.valid_until) >= date)
      );
      
      // Add each session with the specific date
      daySessions.forEach(session => {
        affectedSessions.push({
          ...session,
          date: dateStr
        });
      });
    }
    
    return affectedSessions;
  };
  
  const getOrderedWorkingDays = (scheduleObj) => {
    return DAYS_ORDER.filter(day => scheduleObj.hasOwnProperty(day));
  };

  const getAllPossibleTimes = (scheduleObj) => {
    // Get all unique times across all days
    const allTimes = new Set();
    Object.values(scheduleObj).forEach(times => {
      times.forEach(time => allTimes.add(time));
    });
    
    // Return sorted array of only the times that are actually used
    return sortTimes([...allTimes]);
  };

  const formatTime = (time) => {
    const hour = parseInt(time.split(':')[0]);
    const h = hour % 12 || 12;
    const ampm = hour < 12 ? 'AM' : 'PM';
    return `${h}:00 ${ampm}`;
  };

  const handleSlotClick = (day, time) => {
    const currentSession = getSessionForSlot(day, time);
    setSelectedSlot({ day, time });
    setExistingSession(currentSession);
    
    if (currentSession) {
      const client = {
        id: currentSession.client_id,
        name: currentSession.client_name
      };
      setSelectedClient(client);
    } else {
      setSelectedClient(null);
    }
    
    onOpen();
  };

  const handleMoveSession = async () => {
    try {
      const currentDate = new Date();
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowStr = tomorrow.toISOString().split('T')[0];
  
      // End current session
      const { error: updateError } = await supabase
        .from('sessions')
        .update({ valid_until: currentDate.toISOString().split('T')[0] })
        .eq('id', existingSession.id);
  
      if (updateError) throw updateError;
  
      // Create new session at new time/day
      const { error: insertError } = await supabase
        .from('sessions')
        .insert([{
          therapist_id: profile.id,
          client_id: selectedClient.id,
          client_name: selectedClient.name,
          day: moveDay,
          time: moveTime,
          session_type: 'Regular',
          valid_from: tomorrowStr,
          valid_until: null
        }]);
  
      if (insertError) throw insertError;
  
      await fetchSessions();
      onClose();
      setSelectedClient(null);
      setExistingSession(null);
      setMoveDay('');
      setMoveTime('');
      setIsMoving(false);
  
      toast({
        title: 'Session Moved',
        description: `Session moved to ${moveDay} at ${formatTime(moveTime)} starting tomorrow`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error moving session:', error);
      toast({
        title: 'Error',
        description: 'Failed to move session',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDeleteSession = async () => {
    try {
      const currentDate = new Date();
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowStr = tomorrow.toISOString().split('T')[0];
      
      const existingSession = sessions.find(
        session => session.day === selectedSlot.day && 
                   session.time === selectedSlot.time
      );
  
      if (!existingSession) {
        toast({
          title: 'Error',
          description: 'No session found to delete',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
  
      const confirmDelete = window.confirm(
        'Do you want to remove this session from the schedule? Historical attendance records will be preserved.'
      );
      if (!confirmDelete) return;
  
      const { error } = await supabase
        .from('sessions')
        .update({ valid_until: currentDate.toISOString().split('T')[0] })
        .eq('id', existingSession.id);
  
      if (error) throw error;
  
      await fetchSessions();
      onClose();
      setSelectedClient(null);
  
      toast({
        title: 'Session Removed',
        description: 'The session has been removed from future schedules',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating session:', error);
      toast({
        title: 'Error',
        description: 'Failed to remove session',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleScheduleUpdate = async (action, day, time) => {
    try {
      const updatedSchedule = { ...schedule };
      
      if (action === 'remove') {
        // Check if there are any active sessions in this time slot
        const existingSession = sessions.find(
          session => session.day === day && 
                   session.time === time &&
                   (!session.valid_until || new Date(session.valid_until) > new Date())
        );
  
        if (existingSession) {
          const confirmRemove = window.confirm(
            'This time slot has active sessions. Removing it will end all sessions in this slot. Continue?'
          );
          if (!confirmRemove) return;
  
          // End all sessions in this time slot
          const currentDate = new Date().toISOString().split('T')[0];
          const { error: sessionError } = await supabase
            .from('sessions')
            .update({ valid_until: currentDate })
            .eq('day', day)
            .eq('time', time)
            .eq('therapist_id', profile.id)
            .is('valid_until', null);
  
          if (sessionError) throw sessionError;
        }
  
        if (updatedSchedule[day]) {
          updatedSchedule[day] = updatedSchedule[day].filter(t => t !== time);
          if (updatedSchedule[day].length === 0) {
            delete updatedSchedule[day];
          }
        }
      } else if (action === 'add') {
        if (!updatedSchedule[day]) {
          updatedSchedule[day] = [];
        }
        if (!updatedSchedule[day].includes(time)) {
          updatedSchedule[day].push(time);
          updatedSchedule[day] = sortTimes(updatedSchedule[day]);
        }
      }
  
      const { error } = await supabase
        .from('therapists')
        .update({ schedule: updatedSchedule })
        .eq('id', profile.id);
  
      if (error) throw error;
  
      setSchedule(updatedSchedule);
      await fetchSessions(); // Refresh sessions after schedule update
      
      toast({
        title: 'Schedule Updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating schedule:', error);
      toast({
        title: 'Error',
        description: 'Failed to update schedule',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Add this new function for handling bulk updates
  const handleBulkScheduleUpdate = async (action, day, timeSlots) => {
    try {
      const updatedSchedule = { ...schedule };
      
      if (action === 'add') {
        if (!updatedSchedule[day]) {
          updatedSchedule[day] = [];
        }
        
        // Add all new time slots at once
        timeSlots.forEach(time => {
          if (!updatedSchedule[day].includes(time)) {
            updatedSchedule[day].push(time);
          }
        });
        
        // Sort the times after adding all slots
        updatedSchedule[day] = sortTimes(updatedSchedule[day]);
      }
  
      const { error } = await supabase
        .from('therapists')
        .update({ schedule: updatedSchedule })
        .eq('id', profile.id);
  
      if (error) throw error;
  
      setSchedule(updatedSchedule);
      await fetchSessions();
      
      toast({
        title: 'Schedule Updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating schedule:', error);
      toast({
        title: 'Error',
        description: 'Failed to update schedule',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  const handleSessionAssignment = async () => {
    try {
      if (!selectedClient) {
        toast({
          title: 'Error',
          description: 'Please select a client',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
  
      const currentDate = new Date();
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      const tomorrowStr = tomorrow.toISOString().split('T')[0];
      
      const existingSession = sessions.find(
        session => session.day === selectedSlot.day && 
                   session.time === selectedSlot.time
      );
  
      if (existingSession) {
        const confirmReplace = window.confirm(
          'This will change the schedule going forward. Historical records will be preserved. Continue?'
        );
        if (!confirmReplace) return;
  
        const { error: updateError } = await supabase
          .from('sessions')
          .update({ valid_until: currentDate.toISOString().split('T')[0] })
          .eq('id', existingSession.id);
  
        if (updateError) throw updateError;
      }
  
      const { error: insertError } = await supabase
        .from('sessions')
        .insert([{
          therapist_id: profile.id,
          client_id: selectedClient.id,
          client_name: selectedClient.name,
          day: selectedSlot.day,
          time: selectedSlot.time,
          session_type: 'Regular',
          valid_from: tomorrowStr,
          valid_until: null
        }]);
  
      if (insertError) throw insertError;
  
      await fetchSessions();
      onClose();
      setSelectedClient(null);
  
      toast({
        title: 'Schedule Updated',
        description: `Schedule updated for ${selectedClient.name} starting tomorrow`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error updating schedule:', error);
      toast({
        title: 'Error',
        description: 'Failed to update schedule',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const getAvailableTimesForDay = (day) => {
    if (!schedule[day]) return [];
    
    // Get all time slots for the day
    const dayTimeSlots = schedule[day];
    
    // Get occupied times (including the current time slot if it's the same day)
    const occupiedTimes = [
      ...sessions
        .filter(session => 
          session.day === day && 
          (!session.valid_until || new Date(session.valid_until) > new Date()) &&
          session.id !== existingSession?.id
        )
        .map(session => session.time),
      // Add the current time slot if we're looking at the same day
      ...(day === selectedSlot.day ? [selectedSlot.time] : [])
    ];
    
    // Return available times
    return dayTimeSlots.filter(time => !occupiedTimes.includes(time));
  };
  
  const getSessionForSlot = (day, time) => {
    return sessions.find(session => 
      session.day === day && session.time === time
    );
  };

  const renderClientList = () => {
    if (clients.length === 0) {
      return (
        <Box p={4} textAlign="center" color="gray.500">
          No regular clients found. New clients must first be assigned through Initial Evaluation or Endorsement by the receptionist.
        </Box>
      );
    }
  
    return (
      <VStack spacing={4} align="stretch" overflowY="auto" height="500px" pb={5}>
        {clients.map(client => (
          <Box 
            key={client.id}
            p={3}
            borderWidth={1}
            borderRadius="md"
            cursor="pointer"
            _hover={{ bg: 'gray.100' }}
            onClick={() => setSelectedClient(client)}
          >
            <HStack justify="space-between">
              <Text fontWeight="bold">{client.name}</Text>
            </HStack>
            {client.nickname && (
              <Text fontSize="sm" color="gray.500">"{client.nickname}"</Text>
            )}
            <Text fontSize="sm" color="gray.600">
              {client.diagnosis}
            </Text>
          </Box>
        ))}
      </VStack>
    );
  };

  const generateTimeRange = (start, end) => {
    const times = [];
    let currentHour = parseInt(start.split(':')[0]);
    const endHour = parseInt(end.split(':')[0]);
    
    while (currentHour < endHour) {
      times.push(`${currentHour}:00`);
      currentHour++;
    }
    
    return times;
  };
  
  // Add schedule modification modal
  const ScheduleModificationModal = ({ isOpen, onClose }) => {
    // Check if selected day already has time slots
    useEffect(() => {
      if (selectedDay) {
        setIsNewDay(!schedule[selectedDay] || schedule[selectedDay].length === 0);
      }
    }, [selectedDay]);

    const handleScheduleSubmit = () => {
        if (scheduleAction === 'add' && isNewDay) {
          if (!startTime || !endTime) {
            toast({
              title: 'Missing Time Range',
              description: 'Please select both start and end times',
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
            return;
          }
      
          const timeSlots = generateTimeRange(startTime, endTime);
          console.log('Generated time slots:', timeSlots);
          handleBulkScheduleUpdate('add', selectedDay, timeSlots);
        } else {
          handleScheduleUpdate(scheduleAction, selectedDay, selectedTime);
        }
        onClose();
    };
  
    // Get days that have time slots
    const getDaysWithSchedule = () => {
      return DAYS_ORDER.filter(day => schedule[day] && schedule[day].length > 0);
    };
  
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modify Schedule</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Action</FormLabel>
                <Select
                  value={scheduleAction}
                  onChange={(e) => {
                    setScheduleAction(e.target.value);
                    setSelectedDay('');
                    setSelectedTime('');
                  }}
                  placeholder="Select action"
                >
                  <option value="add">Add Time Slot{isNewDay ? 's' : ''}</option>
                  <option value="remove">Remove Time Slot</option>
                </Select>
              </FormControl>
  
              {scheduleAction === 'remove' ? (
                // Show only days with schedules for remove action
                <FormControl>
                  <FormLabel>Day</FormLabel>
                  <Select
                    value={selectedDay}
                    onChange={(e) => {
                      setSelectedDay(e.target.value);
                      setSelectedTime('');
                    }}
                    placeholder="Select day"
                  >
                    {getDaysWithSchedule().map(day => (
                      <option key={day} value={day}>{day}</option>
                    ))}
                  </Select>
                  {getDaysWithSchedule().length === 0 && (
                    <Text color="orange.500" fontSize="sm" mt={1}>
                      No scheduled days available
                    </Text>
                  )}
                </FormControl>
              ) : (
                // Show all days for add action
                <FormControl>
                  <FormLabel>Day</FormLabel>
                  <Select
                    value={selectedDay}
                    onChange={(e) => setSelectedDay(e.target.value)}
                    placeholder="Select day"
                  >
                    {DAYS_ORDER.map(day => (
                      <option key={day} value={day}>{day}</option>
                    ))}
                  </Select>
                </FormControl>
              )}
  
              {scheduleAction === 'add' && isNewDay ? (
                <>
                  <FormControl>
                    <FormLabel>Start Time</FormLabel>
                    <Select
                      value={startTime}
                      onChange={(e) => setStartTime(e.target.value)}
                      placeholder="Select start time"
                    >
                      {Array.from({ length: 13 }, (_, i) => `${i + 7}:00`).map(time => (
                        <option key={time} value={time}>{formatTime(time)}</option>
                      ))}
                    </Select>
                  </FormControl>
  
                  <FormControl>
                    <FormLabel>End Time</FormLabel>
                    <Select
                      value={endTime}
                      onChange={(e) => setEndTime(e.target.value)}
                      placeholder="Select end time"
                      isDisabled={!startTime}
                    >
                      {Array.from({ length: 13 }, (_, i) => `${i + 7}:00`)
                        .filter(time => parseInt(time) > parseInt(startTime))
                        .map(time => (
                          <option key={time} value={time}>{formatTime(time)}</option>
                        ))}
                    </Select>
                  </FormControl>
                </>
              ) : selectedDay && scheduleAction === 'add' ? (
                <FormControl>
                  <FormLabel>Time</FormLabel>
                  <Select
                    value={selectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                    placeholder="Select time"
                  >
                    {Array.from({ length: 13 }, (_, i) => `${i + 7}:00`)
                      .filter(time => !schedule[selectedDay]?.includes(time))
                      .map(time => (
                        <option key={time} value={time}>{formatTime(time)}</option>
                      ))}
                  </Select>
                </FormControl>
              ) : selectedDay && scheduleAction === 'remove' ? (
                <FormControl>
                  <FormLabel>Time</FormLabel>
                  <Select
                    value={selectedTime}
                    onChange={(e) => setSelectedTime(e.target.value)}
                    placeholder="Select time"
                  >
                    {schedule[selectedDay]?.map(time => (
                      <option key={time} value={time}>{formatTime(time)}</option>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleScheduleSubmit}
              isDisabled={!selectedDay || (!selectedTime && !isNewDay) || (isNewDay && (!startTime || !endTime))}
            >
              Update Schedule
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  // Rest of the component implementation will follow...

  return (
    <DndProvider backend={HTML5Backend}>
    <Box overflowX="auto" overflowY="auto" maxHeight="80vh">
        <VStack spacing={4} align="stretch" mb={4}>
            <HStack justify="space-between">
                <Button
                leftIcon={<TimeIcon />}
                colorScheme="blue"
                onClick={() => setIsScheduleModalOpen(true)}
                size="sm"
                >
                Modify Schedule
                </Button>
                <Button
                leftIcon={<CalendarIcon />}
                colorScheme="orange"
                onClick={() => {
                    setEditLeave(null);
                    setIsLeaveModalOpen(true);
                }}
                size="sm"
                >
                Schedule A Leave
                </Button>
            </HStack>
            
            {nextLeave && (
                <HStack justify="space-between" p={4} bg="orange.50" borderRadius="md">
                <HStack align="start" spacing={1}>
                    <Text fontSize="xs" fontWeight="bold">Next Scheduled Leave: </Text>
                    <Text fontSize="xs">
                    {new Date(nextLeave.start_date).toLocaleDateString()} - {new Date(nextLeave.end_date).toLocaleDateString()}
                    </Text>
                    <Text color="gray.600" fontSize="xs">({nextLeave.purpose})</Text>
                </HStack>
                <Link fontSize="xs" color="blue.500" onClick={() => setIsLeavesDrawerOpen(true)}>
                    View all leaves
                </Link>
                </HStack>
            )}
        </VStack>
  
      {Object.keys(schedule).length > 0 ? (
        <Grid 
          templateColumns={`auto repeat(${getOrderedWorkingDays(schedule).length}, 1fr)`}
          gap={1} 
          bg="gray.50" 
          p={4} 
          borderRadius="md" 
          minWidth={`${200 + (getOrderedWorkingDays(schedule).length * 200)}px`}
        >
          {/* Time slots column */}
          <VStack align="stretch" spacing={1}>
            <Box height="40px" />
            {getAllPossibleTimes(schedule).map(time => (
                <Box key={time} height="58px" p={2}>
                <Text fontSize="sm" color="gray.600">{formatTime(time)}</Text>
                </Box>
            ))}
          </VStack>
  
          {/* Days columns */}
          {getOrderedWorkingDays(schedule).map(day => (
            <VStack key={day} align="stretch" spacing={1}>
                <Box p={2} bg="blue.500" color="white" borderRadius="md">
                <Text fontWeight="bold" textAlign="center">{day}</Text>
                </Box>
                {getAllPossibleTimes(schedule).map(time => {
                    const session = getDyadSessionsForSlot(day, time); // New helper function
                    const hasTimeSlot = schedule[day]?.includes(time);
                    
                    return (
                      <DroppableTimeSlot
                        key={`${day}-${time}`}
                        day={day}
                        time={time}
                        sessions={session}
                        hasTimeSlot={hasTimeSlot}
                        onDrop={handleDrop}
                        onClick={handleSlotClick}
                      />
                    );
                })}
            </VStack>
          ))}
        </Grid>
      ) : (
        <Box p={4} textAlign="center" color="gray.500">
          No schedule set. Click "Modify Schedule" to add time slots.
        </Box>
      )}
  
      {/* Client Assignment Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
            {!selectedClient ? (
            <>
                <ModalHeader>Select Regular Client</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                {renderClientList()}
                </ModalBody>
            </>
            ) : existingSession ? (
            <>
                <ModalHeader>
                Manage Session - {selectedClient.name}
                {selectedSlot && ` (${selectedSlot.day} at ${formatTime(selectedSlot.time)})`}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <VStack spacing={4} align="stretch">
                    {isMoving ? (
                    <>
                        <Text fontWeight="medium">Move session to:</Text>
                        <FormControl isRequired>
                        <FormLabel>Day</FormLabel>
                        <Select
                            value={moveDay}
                            onChange={(e) => setMoveDay(e.target.value)}
                            placeholder="Select day"
                        >
                            {DAYS_ORDER.filter(day => schedule[day]).map(day => (
                            <option key={day} value={day}>{day}</option>
                            ))}
                        </Select>
                        </FormControl>

                        {moveDay && (
                            <FormControl isRequired>
                                <FormLabel>Time</FormLabel>
                                <Select
                                value={moveTime}
                                onChange={(e) => setMoveTime(e.target.value)}
                                placeholder="Select time"
                                >
                                {getAvailableTimesForDay(moveDay).map(time => (
                                    <option key={time} value={time}>{formatTime(time)}</option>
                                ))}
                                </Select>
                                {getAvailableTimesForDay(moveDay).length === 0 && (
                                <Text color="orange.500" fontSize="sm" mt={1}>
                                    No available time slots for this day
                                </Text>
                                )}
                            </FormControl>
                        )}

                        <HStack spacing={4} justify="flex-end">
                        <Button
                            colorScheme="blue"
                            onClick={handleMoveSession}
                            isDisabled={!moveDay || !moveTime}
                        >
                            Confirm Move
                        </Button>
                        <Button variant="ghost" onClick={() => setIsMoving(false)}>
                            Cancel
                        </Button>
                        </HStack>
                    </>
                    ) : (
                    <>
                        <Text>What would you like to do with this session?</Text>
                        <HStack spacing={4}>
                        <Button
                            colorScheme="blue"
                            onClick={() => setIsMoving(true)}
                            flex={1}
                        >
                            Move to Another Time
                        </Button>
                        <Button
                            colorScheme="red"
                            variant="outline"
                            onClick={handleDeleteSession}
                            flex={1}
                        >
                            Remove Session
                        </Button>
                        </HStack>
                    </>
                    )}
                </VStack>
                </ModalBody>
            </>
            ) : (
            <>
                <ModalHeader>
                Confirm Assignment - {selectedClient.name}
                {selectedSlot && ` (${selectedSlot.day} at ${formatTime(selectedSlot.time)})`}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <Text>
                    Assign {selectedClient.name} to this time slot? This will be effective starting tomorrow.
                </Text>
                </ModalBody>
                <ModalFooter>
                <Button
                    colorScheme="blue"
                    mr={3}
                    onClick={handleSessionAssignment}
                >
                    Confirm Assignment
                </Button>
                <Button
                    variant="ghost"
                    onClick={() => setSelectedClient(null)}
                >
                    Back to Clients
                </Button>
                </ModalFooter>
            </>
            )}
        </ModalContent>
      </Modal>
  
      {/* Schedule Modification Modal */}
      <ScheduleModificationModal 
        isOpen={isScheduleModalOpen} 
        onClose={() => {
          setIsScheduleModalOpen(false);
          setSelectedDay('');
          setSelectedTime('');
          setScheduleAction('');
        }}
      />
      <LeaveModal 
        isOpen={isLeaveModalOpen}
        onClose={() => {
            setIsLeaveModalOpen(false);
            setEditLeave(null);
        }}
        onSubmit={editLeave ? handleUpdateLeave : handleLeaveSubmit}
        editData={editLeave}
      />

      <LeavesDrawer 
        isOpen={isLeavesDrawerOpen}
        onClose={() => setIsLeavesDrawerOpen(false)}
        leaves={leaves}
        onEdit={(leave) => {
            setEditLeave(leave);
            setIsLeaveModalOpen(true);
            setIsLeavesDrawerOpen(false);
        }}
        onDelete={handleDeleteLeave}
      />
    </Box>
    </DndProvider>
  );
};

export default MainDeck;