import React, { useState, useRef, useEffect, useCallback, memo, useMemo } from 'react';
import {
  Box,
  VStack,
  HStack,
  Text,
  Button,
  IconButton,
  Badge,
  Card,
  CardBody,
  Heading,
  Divider,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Select,
  NumberInput,
  NumberInputField,
  Switch,
  Tooltip,
  Checkbox,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Spinner,
  Image,
  Spacer
} from '@chakra-ui/react';

import { ChevronLeftIcon, ChevronRightIcon, EditIcon, CheckIcon, CloseIcon, CalendarIcon, AddIcon, PhoneIcon } from '@chakra-ui/icons';
import { BiCalculator } from 'react-icons/bi';
import supabase from '../supabaseClient';

const VATBreakdown = ({ selectedSession, matchingService, attendanceForm }) => {
  const [totalSales, setTotalSales] = useState(null);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-PH', {
      style: 'currency',
      currency: 'PHP'
    }).format(amount);
  };


  const getPackageBaseRate = async (serviceType) => {
    try {
      const currentDate = new Date().toISOString().split('T')[0];
      
      const { data, error } = await supabase
        .from('service_rates')
        .select('*')
        .eq('service_type', serviceType)
        .eq('session_type', 'Regular')
        .eq('is_package', true)
        .lte('valid_from', currentDate)
        .or(`valid_until.is.null,valid_until.gte.${currentDate}`)
        .single();
  
      if (error) throw error;
      return data?.base_rate || null;
    } catch (error) {
      console.error('Error fetching package rate:', error);
      return null;
    }
  };

  console.log('Type: ', selectedSession.session_type);

  useEffect(() => {
    const fetchAndSetRate = async () => {
      if (attendanceForm.isPromo) {
        const packageRate = await getPackageBaseRate(selectedSession.service_type);
        setTotalSales(packageRate || matchingService.base_rate * 10);
      } else if (selectedSession.session_type === 'IE') {
        setTotalSales(3500);
      }
        else {
        setTotalSales(matchingService.base_rate);
      }
    };

    fetchAndSetRate();
  }, [attendanceForm.isPromo, selectedSession.service_type, matchingService.base_rate]);

  if (totalSales === null) return <Spinner size="sm" />;

  const preVatAmount = totalSales / 1.12;
  const vatAmount = totalSales - preVatAmount;

  let pwdDiscount = 0;
  if (selectedSession.session_type !== 'IE') {
    pwdDiscount = selectedSession.client.pwd ? (preVatAmount * 0.2) : 0;
  }
  const finalAmount = (preVatAmount - pwdDiscount) + vatAmount;

  return (
    <VStack align="start" spacing={2}>
      <HStack justify="space-between" width="100%">
        <Text>Total Sales (VAT Inclusive):</Text>
        <Text>{formatCurrency(totalSales)}</Text>
      </HStack>
      <HStack justify="space-between" width="100%">
        <Text>Less: VAT:</Text>
        <Text>{formatCurrency(vatAmount)}</Text>
      </HStack>
      <HStack justify="space-between" width="100%">
        <Text>Amount Net of VAT:</Text>
        <Text>{formatCurrency(preVatAmount)}</Text>
      </HStack>
      {selectedSession.client.pwd && (
        <HStack justify="space-between" width="100%">
          <Text>Less: Discount (PWD 20%):</Text>
          <Text>{formatCurrency(pwdDiscount)}</Text>
        </HStack>
      )}
      <HStack justify="space-between" width="100%">
        <Text>Add: VAT:</Text>
        <Text>{formatCurrency(vatAmount)}</Text>
      </HStack>
      <Divider />
      <HStack justify="space-between" width="100%">
        <Text fontWeight="bold">Total Amount Due:</Text>
        <Text fontWeight="bold">{formatCurrency(finalAmount)}</Text>
      </HStack>
    </VStack>
  );
};

const TherapistSchedule = ({ therapistId, selectedDate }) => {
  const [sessions, setSessions] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [signatureImage, setSignatureImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
  const canvasRef = useRef(null);
  const isDrawingRef = useRef(false);
  const toast = useToast();
  const contextRef = useRef(null);

  const formatTime = (time) => {
    const hour = parseInt(time.split(':')[0]);
    const h = hour % 12 || 12;
    const ampm = hour < 12 ? 'AM' : 'PM';
    return `${h}:00 ${ampm}`;
  };

  useEffect(() => {
    if (isSignatureModalOpen && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      
      // Set white background
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      // Set drawing style
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 2;
      ctx.lineCap = 'round';
      ctx.lineJoin = 'round';
      
      console.log('Canvas initialized');
    }
  }, [isSignatureModalOpen]);

  let lastX = 0;
  let lastY = 0;

  const initializeCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    if (!context) return;

    // Set initial canvas state
    context.fillStyle = 'white';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.strokeStyle = '#000';
    context.lineWidth = 2;
    context.lineCap = 'round';
    contextRef.current = context;
  };

  const startDrawing = (e) => {
    isDrawingRef.current = true;
    lastX = e.nativeEvent.offsetX;
    lastY = e.nativeEvent.offsetY;
    console.log('Start drawing', { lastX, lastY });
  };

  const draw = (e) => {
    if (!isDrawingRef.current) return;
    
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    const currentX = e.nativeEvent.offsetX;
    const currentY = e.nativeEvent.offsetY;
    
    ctx.beginPath();
    ctx.moveTo(lastX, lastY);
    ctx.lineTo(currentX, currentY);
    ctx.stroke();
    
    lastX = currentX;
    lastY = currentY;
    
    console.log('Drawing', { currentX, currentY });
  };

  const stopDrawing = () => {
    isDrawingRef.current = false;
    console.log('Stop drawing');
  };

  const clearSignature = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 2;
    
    console.log('Signature cleared');
  };

  useEffect(() => {
    fetchSessions();
    checkConfirmation();
  }, [therapistId, selectedDate]);

  useEffect(() => {
    if (isSignatureModalOpen && canvasRef.current) {
      // Small delay to ensure modal is rendered
      const timer = setTimeout(() => {
        initializeCanvas();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isSignatureModalOpen]);

  const fetchSessions = async () => {
    const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    try {
      const { data } = await supabase
        .from('sessions')
        .select(`
          *,
          client:clients (*),
          session_attendance:session_attendance(*)
        `)
        .eq('therapist_id', therapistId)
        .eq('day', DAYS[selectedDate.getDay()])
        .lte('valid_from', selectedDate.toISOString().split('T')[0])
        .or(`valid_until.is.null,valid_until.gte.${selectedDate.toISOString().split('T')[0]}`);

      setSessions(data?.map(session => ({
        ...session,
        attendance: session.session_attendance.find(
          a => a.attendance_date === selectedDate.toISOString().split('T')[0]
        )
      })).sort((a, b) => parseInt(a.time) - parseInt(b.time)) || []);
    } catch (error) {
      console.error('Error:', error);
      toast({ title: 'Error fetching sessions', status: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const checkConfirmation = async () => {
    const { data } = await supabase
      .from('attendance_confirmations')
      .select('*')
      .eq('therapist_id', therapistId)
      .eq('date', selectedDate.toISOString().split('T')[0]);

    if (data && data.length > 0) {
      setIsConfirmed(true);
      setSignatureImage(data[0].signature);
    } else {
      setIsConfirmed(false);
      setSignatureImage(null);
    }
  };

  const handleConfirm = () => {
    setIsSignatureModalOpen(true);
  };

  const handleSignatureConfirm = async () => {
    try {
      const canvas = canvasRef.current;
      const signatureData = canvas.toDataURL('image/png');
      
      const { error } = await supabase
        .from('attendance_confirmations')
        .insert({
          therapist_id: therapistId,
          date: selectedDate.toISOString().split('T')[0],
          confirmed_at: new Date().toISOString(),
          signature: signatureData
        });

      if (error) throw error;
      
      setIsSignatureModalOpen(false);
      await checkConfirmation(); // Refresh the data
      toast({ title: 'Attendance confirmed', status: 'success' });
    } catch (error) {
      toast({ title: 'Error confirming attendance', status: 'error' });
    }
  };

  const getSessionSummary = () => {
    const presentSessions = sessions.filter(s => s.attendance?.status === 'present');
    const presentTypes = presentSessions.reduce((acc, session) => {
      const type = session.attendance.session_type;
      acc[type] = (acc[type] || 0) + 1;
      return acc;
    }, {});
  
    const typeBreakdown = Object.entries(presentTypes)
      .map(([type, count]) => `${count} ${type}`)
      .join(', ');
  
    return {
      total: sessions.length,
      present: presentSessions.length,
      absent: sessions.filter(s => s.attendance?.status === 'absent').length,
      cancelled: sessions.filter(s => s.attendance?.status === 'cancelled').length,
      presentTypeBreakdown: typeBreakdown
    };
  };

  const summary = getSessionSummary();

  return (
    <Box>
      <Card mb={4}>
        <CardBody>
          <HStack justify="space-between">
            <VStack align="start" spacing={2}>
              <Heading size="sm">Daily Summary</Heading>
              <Text>Total Sessions: {summary.total}</Text>
              <Text>Present: {summary.present} ({summary.presentTypeBreakdown})</Text>
              <Text>Absent: {summary.absent}</Text>
              <Text>Cancelled: {summary.cancelled}</Text>
            </VStack>
            {!isConfirmed && (
              <Button 
                colorScheme="green"
                onClick={handleConfirm}
                isDisabled={
                  sessions.some(s => !s.attendance) || // Has unrecorded sessions
                  sessions.length === 0 || // No sessions
                  selectedDate > new Date() // Future date
                }
              >
                Confirm Attendance
              </Button>
            )}
            {signatureImage && (
                <Box mt={2}>
                  <Text fontSize="sm" color="gray.600">Signature:</Text>
                  <Image
                    src={signatureImage}
                    alt="Therapist's signature"
                    width="150px"
                    style={{ maxHeight: '60px', objectFit: 'contain' }}
                  />
                </Box>
            )}
          </HStack>
        </CardBody>
      </Card>

      <VStack align="stretch" spacing={4}>
        {sessions.map(session => (
          <Card 
            key={session.id} 
            bg={
              session.attendance?.status === 'present' ? 'green.50' : 
              session.attendance?.status === 'absent' || session.attendance?.status === 'cancelled' ? 'red.50' : 
              'white'
            }
          >
            <CardBody>
              <HStack justify="space-between">
                <VStack align="start" spacing={1}>
                  <HStack>
                    <Text fontWeight="bold">{formatTime(session.time)}</Text>
                    <Text>{session.client.name}</Text>
                    <Text fontSize="sm" color="gray.500">"{session.client.nickname}"</Text>
                    <Badge colorScheme={
                      session.attendance?.status === 'present' ? 'green' : 
                      session.attendance?.status === 'absent' || session.attendance?.status === 'cancelled' ? 'red' : 
                      'gray'
                    }>
                      {session.attendance?.status || 'Pending'}
                    </Badge>
                  </HStack>
                </VStack>
                {session.attendance?.notes && (
                  <Text color="gray.600">{session.attendance.notes}</Text>
                )}
              </HStack>
            </CardBody>
          </Card>
        ))}
      </VStack>

      <Modal 
        isOpen={isSignatureModalOpen} 
        onClose={() => setIsSignatureModalOpen(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sign to Confirm Attendance</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>Please sign below to confirm the attendance records for today.</Text>
            <Box borderWidth={1} borderRadius="lg" overflow="hidden" mb={4}>
              <canvas
                ref={canvasRef}
                width={400}
                height={200}
                style={{ 
                  backgroundColor: '#f0f0f0',
                  cursor: 'crosshair',
                  border: '2px solid #2196F3'
                }}
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={stopDrawing}
                onMouseLeave={stopDrawing}
              />
            </Box>
            <Button
              variant="outline"
              onClick={clearSignature}
              width="full"
            >
              Clear Signature
            </Button>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={() => setIsSignatureModalOpen(false)}>
              Cancel
            </Button>
            <Button colorScheme="green" onClick={handleSignatureConfirm}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

// 1. First, move this outside the DailySchedule component, placing it just before it
const SessionCard = memo(({ 
    session,
    selectedDate,
    setSelectedSession,
    setAttendanceForm,
    setIsAttendanceModalOpen,
    setSessionNotes,
    setIsNotesModalOpen,
    calculatePrice,
    formatCurrency,
    formatTime,
    fetchSessions
  }) => {
    const [nextSession, setNextSession] = useState(null);
    const toast = useToast();
  
    useEffect(() => {
      if (session.attendance) {
        fetchNextSession();
      }
    }, [session.attendance]);
  
    const fetchNextSession = useCallback(async () => {
      const currentDate = new Date();
      const currentDayIndex = currentDate.getDay();
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      
      try {
        const { data, error } = await supabase
          .from('sessions')
          .select(`
            day,
            time,
            therapist:therapists (
              name,
              specialization
            )
          `)
          .eq('client_id', session.client.id)
          .lte('valid_from', currentDate.toISOString().split('T')[0])
          .or(`valid_until.is.null,valid_until.gte.${currentDate.toISOString().split('T')[0]}`);
  
        if (error) throw error;
  
        if (data && data.length > 0) {
          const nextSessions = data.map(s => {
            const sessionDayIndex = daysOfWeek.indexOf(s.day);
            let daysUntil = sessionDayIndex - currentDayIndex;
            if (daysUntil <= 0) daysUntil += 7;
            
            const nextDate = new Date(currentDate);
            nextDate.setDate(currentDate.getDate() + daysUntil);
            
            return {
              ...s,
              nextDate
            };
          });
  
          const nextSession = nextSessions.sort((a, b) => a.nextDate - b.nextDate)[0];
          setNextSession(nextSession);
        }
      } catch (error) {
        console.error('Error fetching next session:', error);
      }
    }, [session.client.id]);
  
    const handleAttendanceClick = useCallback(() => {
      const selectedDateStr = selectedDate.toISOString().split('T')[0];
      const specializationToService = {
        'Speech Therapy': 'ST',
        'Occupational Therapy': 'OT',
        'Physical Therapy': 'PT',
        'Play Group': 'PG'
      };
  
      const matchingService = session.client?.client_services?.find(service => {
        const serviceMatches = service.service_type === specializationToService[session.therapist.specialization];
        const dateMatches = (!service.pricing_valid_from || service.pricing_valid_from <= selectedDateStr) &&
                           (!service.pricing_valid_until || service.pricing_valid_until >= selectedDateStr);
        return serviceMatches && dateMatches;
      });
  
      const baseAmount = session.session_type === 'IE' ? 3500 : 
        calculatePrice(
          matchingService?.base_rate || 0,
          session.client?.pwd || false,
          matchingService?.vat_inclusive || false
        );
      
      const reservationFee = (session.session_type === 'IE' || session.session_type === 'Endorsement') 
        ? (matchingService?.reservation_fee || 0)
        : 0;
  
      const hasOngoingPromo = matchingService?.promo_count > 0 && matchingService?.promo_count < 10;
      
      const amountPaid = hasOngoingPromo ? 
        (matchingService.promo_count === 0 ? 
          calculatePrice(
            matchingService.base_rate * 10,
            session.client.pwd,
            matchingService.vat_inclusive
          ) : 
          0
        ) : (baseAmount - reservationFee);
  
      setSelectedSession(session);
      setAttendanceForm({
        status: session.attendance?.status || 'present',
        notes: session.attendance?.notes || '',
        amountPaid: session.attendance ? 
          session.attendance.amount_paid?.toString() : 
          amountPaid.toString(),
        orNumber: session.attendance?.or_number || '',
        orName: session.client.or_name || '',
        isPromo: hasOngoingPromo || Boolean(session.attendance?.promo_session),
        isPaid: session.attendance?.is_paid ?? true  // Add this line
      });
      
      setIsAttendanceModalOpen(true);
    }, [
      session, 
      selectedDate, 
      calculatePrice, 
      setSelectedSession, 
      setAttendanceForm, 
      setIsAttendanceModalOpen,
      fetchSessions
    ]);
  
    const handleNotesClick = useCallback(() => {
      setSelectedSession(session);
      setSessionNotes(session.client.notes || '');
      setIsNotesModalOpen(true);
    }, [session, setSelectedSession, setSessionNotes, setIsNotesModalOpen]);

    return (
    <Card size="sm" variant="outline" mb={2} bg={session.attendance?.status === 'present' ? 'green.50' : (session.attendance?.status === 'cancelled' || session.attendance?.status === 'absent') ? 'red.50' : 'white'} borderColor={session.attendance?.status === 'present' ? 'green.200' : (session.attendance?.status === 'cancelled' || session.attendance?.status === 'absent') ? 'red.200' : 'gray.200'}>
      <CardBody>
        <VStack align="start" spacing={1}>
          <VStack spacing={0} width="100%" pb={1}>
          <HStack justify="space-between" align="start" width="100%">
            <VStack align="start" spacing={0}>
                <Text fontWeight="bold">{session.client.name}</Text>
                {session.client.nickname && (
                <Text fontSize="sm" color="gray.500">
                    "{session.client.nickname}"
                </Text>
                )}
            </VStack>
            <HStack>
            <Badge colorScheme={session.attendance ? 
                (session.attendance.session_type === 'Regular' ? 'blue' : 'green') : 
                (session.session_type === 'Regular' ? 'blue' : 'green')}>
                {session.attendance ? session.attendance.session_type : session.session_type}
            </Badge>
              {session.client.pwd && <Badge colorScheme="purple">PWD</Badge>}
            </HStack>
          </HStack>
          
          <HStack justify="space-between" width="100%">
            <Text fontSize="sm" color="gray.600">
              {session.client.age}yo {session.client.gender} - {session.client.diagnosis || 'No diagnosis'}
            </Text>
            <HStack spacing={2} mt={1}>
                <Tooltip label={`DR/PR: ${session.client.initial_document ? '✅' : '❌'}`}>
                    <Box>
                    <Text fontSize="3xs" borderWidth={2} borderColor={session.client.initial_document ? 'green.500' : 'red.500'}>
                        📄
                    </Text>
                    </Box>
                </Tooltip>
                <Tooltip label={`CA Given: ${session.client.given_ca ? '✅' : '❌'}`}>
                    <Box>
                    <Text fontSize="3xs" borderWidth={2} borderColor={session.client.given_ca ? 'green.500' : 'red.500'}>
                        📋
                    </Text>
                    </Box>
                </Tooltip>
                <Tooltip label={`CA Signed: ${session.client.signed_ca ? '✅' : '❌'}`}>
                    <Box>
                    <Text fontSize="3xs" borderWidth={2} borderColor={session.client.signed_ca ? 'green.500' : 'red.500'}>
                        ✍️
                    </Text>
                    </Box>
                </Tooltip>
                {session.client.pwd && (
                    <Tooltip label={`PWD Card: ${session.client.pwd_card ? '✅' : '❌'}`}>
                    <Box>
                        <Text fontSize="3xs" borderWidth={2} borderColor={session.client.pwd_card ? 'green.500' : 'red.500'}>
                        🆔
                        </Text>
                    </Box>
                    </Tooltip>
                )}
                {session.client.official_receipt && (
                <Tooltip label={`Official Receipt (${session.client.or_name})`}>
                    <Box>
                    <Text fontSize="3xs" borderWidth={2} borderColor="green.500">
                        🧾
                    </Text>
                    </Box>
                </Tooltip>
                )}
            </HStack>
          </HStack>
          </VStack>
          
          <Divider />
          
          <HStack width="100%" pt={2}>
            <Text fontSize="sm">
                <Text as="span" fontWeight="medium">Base Rate:</Text> {formatCurrency(session.base_rate)}
                {session.vat_inclusive && <Text as="span" color="gray.500"> (VAT incl.)</Text>}
            </Text>
            {session.client.pwd && (
                <Text fontSize="sm">
                <Text as="span" fontWeight="medium">PWD Rate:</Text> {formatCurrency(session.discounted_rate)}
                </Text>
            )}
          </HStack>

          <HStack>
            <Text fontSize="sm">
                <Text as="span" fontWeight="medium">Therapist:</Text> {session.therapist.name}
            </Text>
            <Badge colorScheme="pink">
                {session.service_type}
            </Badge>
          </HStack>

          <Text fontSize="sm">
            <Text as="span" fontWeight="medium">Guardian:</Text> {session.client.guardian_name}
          </Text>
          <Text fontSize="sm">
            <Text as="span" fontWeight="medium">Contact:</Text> {session.client.guardian_contact}
          </Text>
          
          <Text fontSize="sm">
            <Text as="span" fontWeight="medium">Notes:</Text> {session.client.notes}
          </Text>
          <HStack spacing={2} mt={2}>
          <Button
            size="sm"
            leftIcon={session.attendance ? <EditIcon /> : <CheckIcon />}
            colorScheme={session.attendance ? 'gray' : 'green'}
            // In the SessionCard onClick:
            // Inside the Button onClick handler in SessionCard:
            onClick={handleAttendanceClick}  // Replace the long inline handler with this
            >
            {session.attendance ? 'Update Attendance' : 'Mark Attendance'}
          </Button>
            <Tooltip label="Edit Client Notes" placement="top">
                <IconButton
                icon={<EditIcon />}
                size="sm"
                variant="outline"
                onClick={() => {
                    setSelectedSession(session);
                    setSessionNotes(session.client.notes || '');
                    setIsNotesModalOpen(true);
                }}
                aria-label="Edit notes"
                />
            </Tooltip>
          </HStack>
          

          {session.attendance && (
            <Box mt={2} p={2} bg="gray.50" borderRadius="md" width="100%">
              <HStack>
              <Text fontSize="sm" fontWeight="medium">
                Status: <Badge colorScheme={session.attendance.status === 'present' ? 'green' : 'red'}>
                  {session.attendance.status}
                </Badge>
              </Text>
              {session.attendance?.status === 'present' && !session.attendance.is_paid && (
                <Badge colorScheme="orange">Unpaid</Badge>
              )}
              </HStack>
              {session.attendance.status === 'present' && session.attendance.is_paid && (
                <>
                  <Text fontSize="sm">
                    Paid: {formatCurrency(session.attendance.amount_paid)}
                  </Text>
                  {session.attendance.or_number &&
                  <Text fontSize="sm">
                    OR#: {session.attendance.or_number}
                  </Text>
                  }
                </>
              )}
              {session.attendance.notes && (
                <Text fontSize="sm">Session Notes: {session.attendance.notes}</Text>
              )}
              {nextSession && (
                <Text fontSize="xs" fontWeight="bold" mt={2}>
                    Next Session: {nextSession.nextDate.toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                    })} ({nextSession.day}) at {formatTime(nextSession.time)}
                </Text>
              )}
              {session.attendance && session.attendance.status === 'absent' && session.attendance.final_amount === 800 && (
                <Button
                    mt={3}
                    size="sm"
                    colorScheme="red"
                    onClick={async () => {
                    try {
                        const { error } = await supabase
                        .from('session_attendance')
                        .update({ 
                            final_amount: 0,
                            amount_paid: 800
                        })
                        .eq('id', session.attendance.id);

                        if (error) throw error;
                        fetchSessions();
                        
                        toast({
                        title: 'Absence fee collected',
                        status: 'success',
                        duration: 3000
                        });
                    } catch (error) {
                        toast({
                        title: 'Error updating payment',
                        description: error.message,
                        status: 'error',
                        duration: 5000
                        });
                    }
                    }}
                    isDisabled={session.attendance.final_amount === 0}
                >
                    {session.attendance.final_amount === 0 ? 'Collected' : 'Collect ₱800'}
                </Button>
              )}
              {session.attendance && session.attendance.status === 'present' && !session.attendance.is_paid && (
                <Button
                    mt={3}
                    size="sm"
                    colorScheme="red"
                    onClick={async () => {
                        try {
                            const { error } = await supabase
                                .from('session_attendance')
                                .update({ 
                                    is_paid: true,
                                    payment_date: new Date().toISOString()
                                })
                                .eq('id', session.attendance.id);

                            if (error) throw error;
                            fetchSessions();
                            
                            toast({
                                title: 'Payment collected',
                                status: 'success',
                                duration: 3000
                            });
                        } catch (error) {
                            toast({
                                title: 'Error updating payment',
                                description: error.message,
                                status: 'error',
                                duration: 5000
                            });
                        }
                    }}
                >
                    Collect {formatCurrency(session.attendance.amount_paid)}
                </Button>
              )}
            </Box>
          )}
        </VStack>
      </CardBody>
    </Card>
    );
  });

// Add display name for debugging
SessionCard.displayName = 'SessionCard';

const VATCalculatorModal = ({ isOpen, onClose }) => {
  const [amount, setAmount] = useState('');
  const [isPWD, setIsPWD] = useState(false);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-PH', {
      style: 'currency',
      currency: 'PHP'
    }).format(value);
  };

  const calculations = useMemo(() => {
    const numAmount = parseFloat(amount) || 0;
    const preVatAmount = numAmount / 1.12;
    const vatAmount = numAmount - preVatAmount;
    const pwdDiscount = isPWD ? (preVatAmount * 0.2) : 0;
    const finalAmount = (preVatAmount - pwdDiscount) + vatAmount;

    return {
      totalSales: numAmount,
      preVatAmount,
      vatAmount,
      pwdDiscount,
      finalAmount
    };
  }, [amount, isPWD]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>VAT Calculator</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Amount (VAT Inclusive)</FormLabel>
              <NumberInput
                value={amount}
                onChange={(value) => setAmount(value)}
                min={0}
              >
                <NumberInputField placeholder="Enter amount" />
              </NumberInput>
            </FormControl>

            <FormControl>
              <Checkbox
                isChecked={isPWD}
                onChange={(e) => setIsPWD(e.target.checked)}
              >
                Apply PWD Discount (20%)
              </Checkbox>
            </FormControl>

            <Box width="100%" p={4} borderWidth={1} borderRadius="md">
              <VStack align="start" spacing={2}>
                <HStack justify="space-between" width="100%">
                  <Text>Total Sales (VAT Inclusive):</Text>
                  <Text>{formatCurrency(calculations.totalSales)}</Text>
                </HStack>
                <HStack justify="space-between" width="100%">
                  <Text>Less: VAT:</Text>
                  <Text>{formatCurrency(calculations.vatAmount)}</Text>
                </HStack>
                <HStack justify="space-between" width="100%">
                  <Text>Amount Net of VAT:</Text>
                  <Text>{formatCurrency(calculations.preVatAmount)}</Text>
                </HStack>
                {isPWD && (
                  <HStack justify="space-between" width="100%">
                    <Text>Less: Discount (PWD 20%):</Text>
                    <Text>{formatCurrency(calculations.pwdDiscount)}</Text>
                  </HStack>
                )}
                <HStack justify="space-between" width="100%">
                  <Text>Add: VAT:</Text>
                  <Text>{formatCurrency(calculations.vatAmount)}</Text>
                </HStack>
                <Divider />
                <HStack justify="space-between" width="100%">
                  <Text fontWeight="bold">Total Amount Due:</Text>
                  <Text fontWeight="bold">{formatCurrency(calculations.finalAmount)}</Text>
                </HStack>
              </VStack>
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const DailySchedule = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSession, setSelectedSession] = useState(null);
  const [isAttendanceModalOpen, setIsAttendanceModalOpen] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [attendanceForm, setAttendanceForm] = useState({
    status: 'present',
    notes: '',
    amountPaid: '',
    orNumber: '',
    orName: '',
    isPromo: false,
    isPaid: true,  // Add this line
    //finalAmount: 0 // New field
  });
  const [sessionNotes, setSessionNotes] = useState('');
  const [view, setView] = useState('clients'); // 'clients' or 'therapists'
  const [selectedTherapist, setSelectedTherapist] = useState(null);
  const [therapists, setTherapists] = useState([]);
  const [isOneTimeModalOpen, setIsOneTimeModalOpen] = useState(false);
  const [oneTimeSessionForm, setOneTimeSessionForm] = useState({
    therapist_id: '',
    client_id: '',
    time: '',
    notes: ''
  });
  const [availableClients, setAvailableClients] = useState([]);
  // Add this near your other state declarations
  const [isVATCalculatorOpen, setIsVATCalculatorOpen] = useState(false);
  const toast = useToast();

  const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const TIME_SLOTS = Array.from({ length: 11 }, (_, i) => `${i + 8}:00`); // 8 AM to 6 PM

  const [documentStatus, setDocumentStatus] = useState({
    initial_document: false,
    given_ca: false,
    signed_ca: false,
    pwd_card: false,
    official_receipt: false
  });

  const calculatePrice = useCallback((baseRate, isPwd, isVatInclusive) => {
    if (!baseRate) return 0;
      
    const VAT_RATE = 0.12;
      
    if (isVatInclusive) {
      const preVatRate = Math.round(baseRate / (1 + VAT_RATE) * 100) / 100;
      if (isPwd) {
        const discountedPreVatRate = Math.round(preVatRate * 0.8 * 100) / 100;
        return Math.round((discountedPreVatRate + (baseRate-preVatRate))* 100) / 100;
      }
      return baseRate;
    }
    return isPwd ? Math.round(baseRate * 0.8 * 100) / 100 : baseRate;
  }, []);

  useEffect(() => {
    if (isOneTimeModalOpen) {
      fetchAvailableClients();
    }
  }, [isOneTimeModalOpen]);

  useEffect(() => {
    const fetchTherapists = async () => {
        const { data } = await supabase
          .from('sessions')
          .select(`
            therapist:therapists (
              id,
              name,
              specialization
            )
          `)
          .eq('day', DAYS[selectedDate.getDay()])
          .lte('valid_from', selectedDate.toISOString().split('T')[0])
          .or(`valid_until.is.null,valid_until.gte.${selectedDate.toISOString().split('T')[0]}`);
  
        const uniqueTherapists = [...new Set(data?.map(s => s.therapist).filter(Boolean)
          .map(t => JSON.stringify(t)))]
          .map(t => JSON.parse(t));
  
        setTherapists(uniqueTherapists);
        if (uniqueTherapists.length > 0 && !selectedTherapist) {
          setSelectedTherapist(uniqueTherapists[0].id);
        }
      };
  
      fetchTherapists();
      fetchSessions();
  }, [selectedDate]);

  const fetchAvailableClients = async () => {
    try {
      const { data, error } = await supabase
        .from('clients')
        .select(`
          id,
          name,
          nickname,
          diagnosis,
          pwd,
          client_services (*)
        `);
  
      if (error) throw error;
      setAvailableClients(data);
    } catch (error) {
      toast({
        title: 'Error fetching clients',
        description: error.message,
        status: 'error',
        duration: 5000
      });
    }
  };
  
  const handleAddOneTimeSession = async () => {
    try {
      const selectedDateStr = selectedDate.toISOString().split('T')[0];
      
      // Convert the time to 24-hour format
      const timeStr = oneTimeSessionForm.time;
      const hour = parseInt(timeStr);
      const formattedTime = `${hour < 10 ? '0' + hour : hour}:00`;
  
      // Get client name from availableClients
      const selectedClient = availableClients.find(client => client.id === oneTimeSessionForm.client_id);
      const clientName = selectedClient ? selectedClient.name : '';
      
      // Insert the one-time session and return the data
      const { data, error } = await supabase
        .from('sessions')
        .insert([{
          therapist_id: oneTimeSessionForm.therapist_id,
          client_id: oneTimeSessionForm.client_id,
          client_name: clientName,
          day: DAYS[selectedDate.getDay()],
          time: formattedTime,
          session_type: 'Regular',
          valid_from: selectedDateStr,
          valid_until: selectedDateStr,
          notes: oneTimeSessionForm.notes
        }])
        .select(); // Add this to return the inserted data
  
      if (error) throw error;
  
      console.log('Inserted session:', data); // Add this for debugging
  
      toast({
        title: 'Session added successfully',
        status: 'success',
        duration: 3000
      });
  
      setIsOneTimeModalOpen(false);
      setOneTimeSessionForm({
        therapist_id: '',
        client_id: '',
        time: '',
        notes: ''
      });
      
      // Refresh the sessions list
      await fetchSessions(); // Add await here to ensure it completes before continuing
    } catch (error) {
      console.error('Error details:', error); // Add this for debugging
      toast({
        title: 'Error adding session',
        description: error.message,
        status: 'error',
        duration: 5000
      });
    }
  };

  const getValidServiceFee = async (therapistId, date) => {
    try {
      const { data, error } = await supabase
        .from('service_fees')
        .select('amount')
        .eq('therapist_id', therapistId)
        .lte('valid_from', date)
        .or(`valid_until.is.null,valid_until.gte.${date}`)
        .maybeSingle();
      if (error) throw error;
      return data?.amount || 0;
    } catch (error) {
      console.error('Error getting service fee:', error);
      return 0;
    }
  };

  const getPackageRate = async (serviceType, isPwd, isVatInclusive) => {
    try {
      const currentDate = new Date().toISOString().split('T')[0];
      
      const { data, error } = await supabase
        .from('service_rates')
        .select('*')
        .eq('service_type', serviceType)
        .eq('session_type', 'Regular')
        .eq('is_package', true)
        .lte('valid_from', currentDate)
        .or(`valid_until.is.null,valid_until.gte.${currentDate}`)
        .single();
  
      if (error) throw error;

      console.log('Data: ', data)
      console.log('Is VAT Inclusive: ', isVatInclusive)
  
      if (!data) return null;
  
      if (isVatInclusive) {
        console.log('ENTERED')
        const VAT_RATE = 0.12;
        if (isPwd) {
          const preVatRate = data.base_rate / (1 + VAT_RATE);
          const discountedPreVatRate = preVatRate * 0.8;
          return Math.round((discountedPreVatRate + (data.base_rate-preVatRate)) * 100) / 100;
        }
        return data.base_rate;
      } else {
        return isPwd ? data.base_rate * 0.8 : data.base_rate;
      }
    } catch (error) {
      console.error('Error fetching package rate:', error);
      return null;
    }
  };

  const fetchSessions = async () => {
    try {
      setIsLoading(true);
      const dayOfWeek = DAYS[selectedDate.getDay()];
      const selectedDateStr = selectedDate.toISOString().split('T')[0];
  
      // Fetch attendance records for this date
      const { data: attendanceData, error: attendanceError } = await supabase
        .from('session_attendance')
        .select(`
          *,
          session:sessions (
            id,
            day,
            time,
            client_id,
            therapist_id,
            session_type,
            valid_from,
            valid_until
          ),
          client:clients (
            id,
            name,
            nickname,
            age,
            gender,
            diagnosis,
            guardian_name,
            guardian_contact,
            pwd,
            pwd_number,
            notes,
            initial_document,
            given_ca,
            signed_ca,
            pwd_card,
            official_receipt,
            or_name,
            client_services (*)
          ),
          therapist:therapists (
            id,
            name,
            specialization
          )
        `)
        .eq('attendance_date', selectedDateStr);
  
      if (attendanceError) throw attendanceError;
  
      // Fetch all scheduled sessions for this day
      const { data: sessionsData, error: sessionsError } = await supabase
        .from('sessions')
        .select(`
          *,
          client:clients (
            id,
            name,
            nickname,
            age,
            gender,
            diagnosis,
            guardian_name,
            guardian_contact,
            pwd,
            pwd_number,
            notes,
            initial_document,
            given_ca,
            signed_ca,
            pwd_card,
            official_receipt,
            or_name,
            client_services (*)
          ),
          therapist:therapists (
            id,
            name,
            specialization
          )
        `)
        .eq('day', dayOfWeek)
        .lte('valid_from', selectedDateStr)
        .or(`valid_until.is.null,valid_until.gte.${selectedDateStr}`);
  
      if (sessionsError) throw sessionsError;
  
      // Process attendance records
      const attendanceSessions = attendanceData?.map(record => {
        const matchingService = record.client?.client_services?.find(service => {
          const specializationToService = {
            'Speech Therapy': 'ST',
            'Occupational Therapy': 'OT',
            'Physical Therapy': 'PT',
            'Play Group': 'PG'
          };
          const serviceMatches = service.service_type === specializationToService[record.therapist.specialization];
          const dateMatches = (!service.pricing_valid_from || service.pricing_valid_from <= selectedDateStr) &&
                            (!service.pricing_valid_until || service.pricing_valid_until >= selectedDateStr);
          return serviceMatches && dateMatches;
        });
      
        return {
          ...record.session,
          client: record.client,
          therapist: record.therapist,
          attendance: {
            ...record,
            session: undefined,
            client: undefined,
            therapist: undefined
          },
          base_rate: matchingService?.base_rate || 0,
          discounted_rate: calculatePrice(
            matchingService?.base_rate || 0,
            record.client?.pwd || false,
            matchingService?.vat_inclusive || false
          ),
          service_type: matchingService?.service_type || 'N/A'
        };
      }) || [];
  
      // Process scheduled sessions that don't have attendance records
      const scheduledSessionsProcessed = sessionsData.map(session => {
        // Skip if we already have an attendance record for this session
        if (attendanceSessions.some(as => as.id === session.id)) {
          return null;
        }
      
        const matchingService = session.client?.client_services?.find(service => {
          const specializationToService = {
            'Speech Therapy': 'ST',
            'Occupational Therapy': 'OT',
            'Physical Therapy': 'PT',
            'Play Group': 'PG'
          };
          const serviceMatches = service.service_type === specializationToService[session.therapist.specialization];
          const dateMatches = (!service.pricing_valid_from || service.pricing_valid_from <= selectedDateStr) &&
                            (!service.pricing_valid_until || service.pricing_valid_until >= selectedDateStr);
          return serviceMatches && dateMatches;
        });
      
        return {
          ...session,
          base_rate: matchingService?.base_rate || 0,
          discounted_rate: calculatePrice(
            matchingService?.base_rate || 0,
            session.client?.pwd || false,
            matchingService?.vat_inclusive || false
          ),
          service_type: matchingService?.service_type || 'N/A'
        };
      }).filter(Boolean);
  
      // Combine both arrays, with attendance records taking precedence
      const combinedSessions = [...attendanceSessions, ...scheduledSessionsProcessed];
  
      setSessions(combinedSessions);
    } catch (error) {
      toast({
        title: 'Error fetching sessions',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAttendanceSubmit = async () => {
    try {
        const matchingService = selectedSession.client?.client_services?.find(service => {
            const specializationToService = {
            'Speech Therapy': 'ST',
            'Occupational Therapy': 'OT',
            'Physical Therapy': 'PT',
            'Play Group': 'PG'
            };
            const serviceMatches = service.service_type === specializationToService[selectedSession.therapist.specialization];
            const dateMatches = (!service.pricing_valid_from || service.pricing_valid_from <= selectedDate.toISOString().split('T')[0]) &&
                            (!service.pricing_valid_until || service.pricing_valid_until >= selectedDate.toISOString().split('T')[0]);
            return serviceMatches && dateMatches;
        });

        console.log("Matching Service: ", matchingService);

        console.log('Selected Session: ', selectedSession);
  
        // Check if attendance record already exists
        const { data: existingAttendance } = await supabase
            .from('session_attendance')
            .select('*')  // Changed from just 'id' to get full record
            .eq('session_id', selectedSession.id)
            .eq('attendance_date', selectedDate.toISOString().split('T')[0])
            .single();

        // Determine if this is an initial session by checking either:
        // 1. For a new record: check the original session type
        // 2. For existing record: check the stored session_type in attendance
        const isInitialSession = existingAttendance 
            ? ['IE', 'Endorsement'].includes(existingAttendance.session_type)
            : ['IE', 'Endorsement'].includes(selectedSession.session_type);

        // Calculate the actual amount to be stored in the database
        let dbAmountPaid = parseFloat(attendanceForm.amountPaid) || 0;
        if (isInitialSession && attendanceForm.status === 'present') {
            // Add reservation fee to the amount paid
            // You should replace RESERVATION_FEE with your actual reservation fee amount
            const RESERVATION_FEE = matchingService.reservation_fee; // Replace with your actual reservation fee
            dbAmountPaid += RESERVATION_FEE;
        }

        let newPromoCount = null;
        if (!existingAttendance) {
            // If this is a promo session start or within promo period
            if ((attendanceForm.isPromo || matchingService?.promo_count > 0) && 
                attendanceForm.status === 'present') { 
                // Update promo_count in client_services
                newPromoCount = matchingService?.promo_count ? matchingService.promo_count + 1 : 1;
            }
        } else { // If editing an existing session_attendance, promo count shouldn't move
            if ((attendanceForm.isPromo || matchingService?.promo_count > 0) && 
                attendanceForm.status === 'present') {
                newPromoCount = matchingService?.promo_count ? matchingService.promo_count : 1;
            }
        }

        const attendanceData = {
            session_id: selectedSession.id,
            client_id: selectedSession.client.id,
            therapist_id: selectedSession.therapist.id,
            attendance_date: selectedDate.toISOString().split('T')[0],
            status: attendanceForm.status,
            notes: attendanceForm.notes,
            base_rate: selectedSession.base_rate,
            pwd_discount: selectedSession.client.pwd,
            final_amount: attendanceForm.status === 'absent' ? 800 : 0,
            session_type: selectedSession.session_type, // Store original session type
            promo_session: attendanceForm.isPromo ? newPromoCount : null,
            amount_paid: attendanceForm.status === 'present' ? dbAmountPaid : null,
            or_number: attendanceForm.status === 'present' ? attendanceForm.orNumber : null,
            payment_date: attendanceForm.status === 'present' ? new Date().toISOString() : null,
            is_paid: attendanceForm.isPaid  // Add this line
        };
  
        let error;
        let attendanceId;
        if (existingAttendance) {
            const { error: updateError } = await supabase
                .from('session_attendance')
                .update(attendanceData)
                .eq('id', existingAttendance.id);
            error = updateError;
            attendanceId = existingAttendance.id;
        } else {
            const { data: insertedAttendance, error: insertError } = await supabase
                .from('session_attendance')
                .insert([attendanceData]);
            error = insertError;
            attendanceId = insertedAttendance?.id;
        }
  
        if (error) throw error;
  
        // If status is present, create service fee expense
        if (attendanceData.status === 'present') {
            let serviceFee;
            if (attendanceData.session_type === 'IE') {
                serviceFee = 2200;
            } else {
                serviceFee = await getValidServiceFee(
                selectedSession.therapist.id, 
                selectedDate.toISOString().split('T')[0]
            );
            }
    
            const expenseData = {
                purchase_date: selectedDate.toISOString().split('T')[0],
                expense_name: `${selectedSession.therapist.name} (${selectedSession.client.name})`,
                expense_type: 'Service Fee',
                amount: serviceFee,
                receipt_type: 'None',
                notes: '',
                session_attendance_id: attendanceId
            };
    
            const { error: expenseError } = await supabase
                .from('expenses')
                .insert([expenseData]);
    
            if (expenseError) throw expenseError;
        }

        if ((attendanceForm.isPromo || matchingService?.promo_count > 0) && 
            attendanceForm.status === 'present') {

            // If we've reached 10 sessions, reset back to null
            if (newPromoCount === 10) {
                newPromoCount = null;
            }

            const { error: promoError } = await supabase
                .from('client_services')
                .update({ promo_count: newPromoCount })
                .eq('client_id', selectedSession.client.id)
                .eq('service_type', selectedSession.service_type);

            if (promoError) throw promoError;
        }
  
        // Update client document status and OR information
        const documentsToUpdate = {
            ...(documentStatus.initial_document && { initial_document: true }),
            ...(documentStatus.given_ca && { given_ca: true }),
            ...(documentStatus.signed_ca && { signed_ca: true }),
            ...(documentStatus.pwd_card && { pwd_card: true }),
            ...(documentStatus.official_receipt && { 
            official_receipt: true,
            or_name: attendanceForm.orName 
            })
        };
  
        if (Object.keys(documentsToUpdate).length > 0) {
            const { error: clientUpdateError } = await supabase
                .from('clients')
                .update(documentsToUpdate)
                .eq('id', selectedSession.client.id);
    
            if (clientUpdateError) throw clientUpdateError;
        }
  
        // Update session type to Regular if conditions are met
        if (attendanceForm.status === 'present' && 
            (selectedSession.session_type === 'IE' || 
            selectedSession.session_type === 'Endorsement')) {
            const { error: sessionUpdateError } = await supabase
                .from('sessions')
                .update({ session_type: 'Regular' })
                .eq('id', selectedSession.id)
                .eq('day', selectedSession.day)  // Add these two lines
                .eq('time', selectedSession.time);

            if (sessionUpdateError) {
                console.error('Error updating session type:', sessionUpdateError);
                throw sessionUpdateError;
            }

            // Also update the selectedSession to reflect the change
            selectedSession.session_type = 'Regular';
        }
  
        toast({
            title: existingAttendance ? 'Attendance Updated' : 'Attendance Recorded',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
  
        setIsAttendanceModalOpen(false);
        fetchSessions();
    } catch (error) {
      toast({
        title: 'Error recording attendance',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleNotesUpdate = async () => {
    try {
      const { error } = await supabase
        .from('clients')
        .update({ notes: sessionNotes })
        .eq('id', selectedSession.client_id);

      if (error) throw error;

      toast({
        title: 'Notes Updated',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      setIsNotesModalOpen(false);
      fetchSessions();
    } catch (error) {
      toast({
        title: 'Error updating notes',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Helper functions for date and time formatting
  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const handleDateChange = (event) => {
    setSelectedDate(new Date(event.target.value));
  };

  const formatTime = (time) => {
    const hour = parseInt(time.split(':')[0]);
    const h = hour % 12 || 12;
    const ampm = hour < 12 ? 'AM' : 'PM';
    return `${h}:00 ${ampm}`;
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-PH', {
      style: 'currency',
      currency: 'PHP'
    }).format(amount);
  };

  const navigateDay = (direction) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(selectedDate.getDate() + direction);
    setSelectedDate(newDate);
  };

  const getSessionsForTimeSlot = (timeSlot) => {
    //console.log('Timeslot Sessions: ', sessions.filter(session => session.time === timeSlot));
    return sessions.filter(session => session.time === timeSlot);
  };

  return (
    <Box>
      <HStack justify="space-between" mb={6}>
        <IconButton
          icon={<ChevronLeftIcon />}
          onClick={() => navigateDay(-1)}
          aria-label="Previous day"
        />
        <Popover>
          <PopoverTrigger>
            <Heading size="md" cursor="pointer" display="flex" alignItems="center">
              {formatDate(selectedDate)}
              <CalendarIcon ml={2} />
            </Heading>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <FormControl>
                <FormLabel>Select a date</FormLabel>
                <Input
                  type="date"
                  value={selectedDate.toISOString().split('T')[0]}
                  onChange={handleDateChange}
                />
              </FormControl>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <IconButton
          icon={<ChevronRightIcon />}
          onClick={() => navigateDay(1)}
          aria-label="Next day"
        />
      </HStack>

      <Tabs onChange={(index) => setView(index === 0 ? 'clients' : 'therapists')} mb={4}>
        <TabList>
          <Tab>Client Schedule</Tab>
          <Tab>Therapist Summary</Tab>
        </TabList>
      </Tabs>

      {view === 'clients' &&       
        <HStack justify="space-between">
            <Spacer/>
            <Button
                leftIcon={<BiCalculator />}  // You'll need to import this icon
                colorScheme="teal"
                size="sm"
                mb={4}
                mr={2}
                onClick={() => setIsVATCalculatorOpen(true)}
            >
            VAT Calculator
            </Button>
            <Button
            leftIcon={<AddIcon />}
            colorScheme="blue"
            size="sm"
            mb={4}
            mr={4}
            onClick={() => setIsOneTimeModalOpen(true)}
            >
            Add One-Time Session
            </Button>
        </HStack>
      }
  
      {view === 'clients' ? (
      <VStack align="stretch" spacing={4}>
        {TIME_SLOTS.map(timeSlot => {
          const timeSlotSessions = getSessionsForTimeSlot(timeSlot);
          if (timeSlotSessions.length === 0) return null;
  
          return (
            <Box key={timeSlot} p={4} borderWidth={1} borderRadius="md">
              <Heading size="sm" mb={4}>{formatTime(timeSlot)}</Heading>
              <Box 
                display="grid" 
                gridTemplateColumns={{
                  base: "1fr",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(3, 1fr)"
                }}
                gap={3}
              >
                {timeSlotSessions.map(session => (
                    <SessionCard 
                    key={session.id} 
                    session={session}
                    selectedDate={selectedDate}
                    setSelectedSession={setSelectedSession}
                    setAttendanceForm={setAttendanceForm}
                    setIsAttendanceModalOpen={setIsAttendanceModalOpen}
                    setSessionNotes={setSessionNotes}
                    setIsNotesModalOpen={setIsNotesModalOpen}
                    calculatePrice={calculatePrice}
                    formatCurrency={formatCurrency}
                    formatTime={formatTime}
                    fetchSessions={fetchSessions}  // Add this prop
                    />
                ))}
              </Box>
            </Box>
          );
        })}
      </VStack>
      ) : (
        <VStack align="stretch" spacing={4}>
          <Select
            value={selectedTherapist}
            onChange={(e) => setSelectedTherapist(e.target.value)}
            maxW="400px"
          >
            {therapists.map(therapist => (
              <option key={therapist.id} value={therapist.id}>
                {therapist.name} - {therapist.specialization}
              </option>
            ))}
          </Select>
          <TherapistSchedule 
            key={`${selectedTherapist}-${selectedDate.toISOString()}-${view}`}
            therapistId={selectedTherapist} 
            selectedDate={selectedDate}
          />
        </VStack>
      )}
  
      {!isLoading && sessions.length === 0 && (
        <Box textAlign="center" py={8}>
          <Text color="gray.500">No sessions scheduled for this day</Text>
        </Box>
      )}
  
      {/* Attendance Modal */}
      <Modal 
        isOpen={isAttendanceModalOpen} 
        onClose={() => setIsAttendanceModalOpen(false)}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Record Attendance
            {selectedSession && ` - ${selectedSession.client.name}`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>Status</FormLabel>
                <Select
                  value={attendanceForm.status}
                  onChange={(e) => setAttendanceForm({
                    ...attendanceForm,
                    status: e.target.value
                  })}
                >
                  <option value="present">Present</option>
                  <option value="absent">Absent</option>
                  <option value="cancelled">Cancelled</option>
                </Select>
              </FormControl>
  
              {attendanceForm.status === 'present' && selectedSession && (
                <>
                {selectedSession.session_type !== 'IE' && (
                <FormControl>
                    <Checkbox
                    isChecked={attendanceForm.isPromo}
                    onChange={async (e) => {
                        const isPromo = e.target.checked;
                        const selectedDateStr = selectedDate.toISOString().split('T')[0];
                        
                        const matchingService = selectedSession.client?.client_services?.find(service => {
                          const serviceMatches = service.service_type === selectedSession.service_type;
                          const dateMatches = (!service.pricing_valid_from || service.pricing_valid_from <= selectedDateStr) &&
                                             (!service.pricing_valid_until || service.pricing_valid_until >= selectedDateStr);
                          return serviceMatches && dateMatches;
                        });
                      
                        let promoAmount;
                        if (isPromo) {
                          promoAmount = await getPackageRate(
                            selectedSession.service_type,
                            selectedSession.client.pwd,
                            matchingService?.vat_inclusive
                          );
                        }
                      
                        setAttendanceForm(prev => ({
                          ...prev,
                          isPromo,
                          amountPaid: isPromo ? 
                            (promoAmount ? promoAmount.toString() : 
                              selectedSession.client.pwd ? 
                                (selectedSession.base_rate * 0.8 * 10) : 
                                (selectedSession.base_rate * 10)
                            ).toString() : 
                            selectedSession.discounted_rate.toString()
                        }));
                    }}
                    isDisabled={selectedSession.client?.client_services?.find(service => 
                        service.service_type === selectedSession.service_type
                    )?.promo_count > 0}
                    >
                    Promo Package (10 Sessions)
                    </Checkbox>
                </FormControl>
                )}
  
                  <FormControl>
                    <FormLabel>Amount Paid</FormLabel>
                    {(selectedSession.session_type === 'IE' || selectedSession.session_type === 'Endorsement') && (
                      <Text fontSize="sm" color="gray.600" mb={2}>
                        {selectedSession.client?.client_services?.find(service => 
                          service.service_type === selectedSession.service_type
                        )?.reservation_fee > 0 
                          ? `Reservation fee of ${formatCurrency(selectedSession.client.client_services.find(
                              service => service.service_type === selectedSession.service_type
                            ).reservation_fee)} deducted from total amount`
                          : 'No reservation fee applied'
                        }
                      </Text>
                    )}
                    
                    {/* Add promo session display */}
                    {(selectedSession.attendance?.promo_session || 
                    (selectedSession.client?.client_services?.find(service => 
                        service.service_type === selectedSession.service_type
                    )?.promo_count > 0 && 
                    selectedSession.client?.client_services?.find(service => 
                        service.service_type === selectedSession.service_type
                    )?.promo_count < 10)) && (
                    <Text fontSize="sm" color="blue.600" mb={2}>
                        {selectedSession.attendance ? 
                        `Promo Session ${selectedSession.attendance.promo_session}/10` :
                        `Promo Session ${selectedSession.client.client_services.find(
                            service => service.service_type === selectedSession.service_type
                        ).promo_count + 1}/10`
                        }
                    </Text>
                    )}
                    
                    <NumberInput
                      value={attendanceForm.amountPaid}
                      onChange={(valueString) => setAttendanceForm({
                        ...attendanceForm,
                        amountPaid: valueString
                      })}
                      min={0}
                      isDisabled={selectedSession.client?.client_services?.find(service => 
                        service.service_type === selectedSession.service_type
                      )?.promo_count > 0 && selectedSession.client?.client_services?.find(service => 
                        service.service_type === selectedSession.service_type
                      )?.promo_count < 10}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </FormControl>

                  {/* Add this new FormControl */}
                  <FormControl>
                    <Checkbox
                        isChecked={attendanceForm.isPaid}
                        onChange={(e) => setAttendanceForm(prev => ({
                            ...prev,
                            isPaid: e.target.checked,
                            orNumber: e.target.checked ? prev.orNumber : '',  // Clear OR if unpaid
                            orName: e.target.checked ? prev.orName : ''      // Clear OR name if unpaid
                        }))}
                    >
                        Payment Received
                    </Checkbox>
                  </FormControl>
                  
                  {/* Only show OR fields if isPaid is true */}
                  {attendanceForm.isPaid && (
                    <>
                  <FormControl>
                    <FormLabel>Request Official Receipt</FormLabel>
                    <Checkbox
                      isChecked={documentStatus.official_receipt || attendanceForm.orName}
                      onChange={(e) => setDocumentStatus(prev => ({
                        ...prev,
                        official_receipt: e.target.checked
                      }))}
                    >
                      Requests OR
                    </Checkbox>
                  </FormControl>
  
                  {(documentStatus.official_receipt || attendanceForm.orName) && (
                    <>
                      <FormControl>
                        <FormLabel>OR Name</FormLabel>
                        <Input
                          value={attendanceForm.orName}
                          onChange={(e) => setAttendanceForm({
                            ...attendanceForm,
                            orName: e.target.value
                          })}
                          placeholder="Enter name to appear on OR"
                        />
                      </FormControl>
  
                      <FormControl>
                        <FormLabel>OR Number</FormLabel>
                        <Input
                          value={attendanceForm.orNumber}
                          onChange={(e) => setAttendanceForm({
                            ...attendanceForm,
                            orNumber: e.target.value
                          })}
                          placeholder="Enter OR number"
                        />
                      </FormControl>
                      {selectedSession.client.pwd_number &&
                        <HStack justify="center" width="100%">
                            <Text>PWD Number: </Text>
                            <Text>{selectedSession.client.pwd_number}</Text>
                        </HStack>
                      }
                      {attendanceForm.status === 'present' && selectedSession && (() => {
                        const matchingService = selectedSession.client?.client_services?.find(service => {
                            const specializationToService = {
                            'Speech Therapy': 'ST',
                            'Occupational Therapy': 'OT',
                            'Physical Therapy': 'PT',
                            'Play Group': 'PG'
                            };
                            const serviceMatches = service.service_type === specializationToService[selectedSession.therapist.specialization];
                            const dateMatches = (!service.pricing_valid_from || service.pricing_valid_from <= selectedDate.toISOString().split('T')[0]) &&
                                            (!service.pricing_valid_until || service.pricing_valid_until >= selectedDate.toISOString().split('T')[0]);
                            return serviceMatches && dateMatches;
                        });

                        return matchingService?.vat_inclusive && (
                            <Box mt={0} p={4} borderWidth={1} borderRadius="md">
                              <Text fontWeight="bold" mb={2}>VAT Breakdown</Text>
                              <VATBreakdown 
                                selectedSession={selectedSession}
                                matchingService={matchingService}
                                attendanceForm={attendanceForm}
                              />
                            </Box>
                        );
                      })()}
                    </>
                  )}
                  </>
                  )}
                </>
              )}
  
              {selectedSession && (
                <FormControl>
                  {(!selectedSession.client.initial_document || !selectedSession.client.given_ca || !selectedSession.client.signed_ca || (selectedSession.client.pwd && !selectedSession.client.pwd_card) ) &&
                  <FormLabel>Update Document Status</FormLabel>
                  }
                  <VStack align="start" spacing={2}>
                    {!selectedSession.client.initial_document && (
                      <Checkbox
                        isChecked={documentStatus.initial_document}
                        onChange={(e) => setDocumentStatus(prev => ({
                          ...prev,
                          initial_document: e.target.checked
                        }))}
                      >
                        Initial Document Submitted
                      </Checkbox>
                    )}
                    {!selectedSession.client.given_ca && (
                      <Checkbox
                        isChecked={documentStatus.given_ca}
                        onChange={(e) => setDocumentStatus(prev => ({
                          ...prev,
                          given_ca: e.target.checked
                        }))}
                      >
                        Client Agreement Given
                      </Checkbox>
                    )}
                    {!selectedSession.client.signed_ca && (
                      <Checkbox
                        isChecked={documentStatus.signed_ca}
                        onChange={(e) => setDocumentStatus(prev => ({
                          ...prev,
                          signed_ca: e.target.checked
                        }))}
                      >
                        Client Agreement Signed
                      </Checkbox>
                    )}
                    {selectedSession.client.pwd && !selectedSession.client.pwd_card && (
                      <Checkbox
                        isChecked={documentStatus.pwd_card}
                        onChange={(e) => setDocumentStatus(prev => ({
                          ...prev,
                          pwd_card: e.target.checked
                        }))}
                      >
                        PWD Card Submitted
                      </Checkbox>
                    )}
                  </VStack>
                </FormControl>
              )}

              {attendanceForm.status === 'absent' && selectedSession &&
              <FormControl>
                <FormLabel>Amount To Collect</FormLabel>
                <NumberInput
                    value={800}
                    onChange={(valueString) => setAttendanceForm({
                    ...attendanceForm,
                    finalAmount: valueString
                    })}
                    min={0}
                    isDisabled={true}
                >
                    <NumberInputField />
                </NumberInput>
              </FormControl>
              }
              <FormControl>
                <FormLabel>Session Notes</FormLabel>
                <Textarea
                  value={attendanceForm.notes}
                  onChange={(e) => setAttendanceForm({
                    ...attendanceForm,
                    notes: e.target.value
                  })}
                  placeholder="e.g., Promo Session 1/10"
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleAttendanceSubmit}>
              Save Attendance
            </Button>
            <Button variant="ghost" onClick={() => setIsAttendanceModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  
      {/* Notes Modal */}
      <Modal 
        isOpen={isNotesModalOpen} 
        onClose={() => setIsNotesModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Edit Client Notes
            {selectedSession && ` - ${selectedSession.client.name}`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Client Notes</FormLabel>
              <Textarea
                value={sessionNotes}
                onChange={(e) => setSessionNotes(e.target.value)}
                placeholder="Add client notes"
                rows={6}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleNotesUpdate}>
              Save Notes
            </Button>
            <Button variant="ghost" onClick={() => setIsNotesModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

        {/* One-Time Session Modal */}
        <Modal
        isOpen={isOneTimeModalOpen}
        onClose={() => setIsOneTimeModalOpen(false)}
        size="lg"
        >
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Add One-Time Session</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <VStack spacing={4}>
                <FormControl isRequired>
                <FormLabel>Therapist</FormLabel>
                <Select
                    value={oneTimeSessionForm.therapist_id}
                    onChange={(e) => setOneTimeSessionForm(prev => ({
                    ...prev,
                    therapist_id: e.target.value
                    }))}
                    placeholder="Select therapist"
                >
                    {therapists.map(therapist => (
                    <option key={therapist.id} value={therapist.id}>
                        {therapist.name} - {therapist.specialization}
                    </option>
                    ))}
                </Select>
                </FormControl>

                <FormControl isRequired>
                <FormLabel>Client</FormLabel>
                <Select
                    value={oneTimeSessionForm.client_id}
                    onChange={(e) => setOneTimeSessionForm(prev => ({
                    ...prev,
                    client_id: e.target.value
                    }))}
                    placeholder="Select client"
                >
                    {availableClients.map(client => (
                    <option key={client.id} value={client.id}>
                        {client.name} {client.nickname ? `"${client.nickname}"` : ''} - {client.diagnosis || 'No diagnosis'}
                    </option>
                    ))}
                </Select>
                </FormControl>

                <FormControl isRequired>
                <FormLabel>Time</FormLabel>
                <Select
                    value={oneTimeSessionForm.time}
                    onChange={(e) => setOneTimeSessionForm(prev => ({
                    ...prev,
                    time: e.target.value
                    }))}
                    placeholder="Select time"
                >
                    {TIME_SLOTS.map(time => (
                    <option key={time} value={time}>
                        {formatTime(time)}
                    </option>
                    ))}
                </Select>
                </FormControl>

                <FormControl>
                <FormLabel>Notes</FormLabel>
                <Textarea
                    value={oneTimeSessionForm.notes}
                    onChange={(e) => setOneTimeSessionForm(prev => ({
                    ...prev,
                    notes: e.target.value
                    }))}
                    placeholder="Add any notes about this one-time session"
                />
                </FormControl>
            </VStack>
            </ModalBody>
            <ModalFooter>
            <Button
                colorScheme="blue"
                mr={3}
                onClick={handleAddOneTimeSession}
                isDisabled={!oneTimeSessionForm.therapist_id || !oneTimeSessionForm.client_id || !oneTimeSessionForm.time}
            >
                Add Session
            </Button>
            <Button variant="ghost" onClick={() => setIsOneTimeModalOpen(false)}>
                Cancel
            </Button>
            </ModalFooter>
        </ModalContent>
        </Modal>
        {/* Add this near your other modals */}
        <VATCalculatorModal 
            isOpen={isVATCalculatorOpen}
            onClose={() => setIsVATCalculatorOpen(false)}
        />
    </Box>
  );
};

export default DailySchedule;