// src/components/SignIn.js
import React, { useState } from 'react';
import { Box, Heading, FormControl, FormLabel, Input, Button, useToast, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { user, error } = await login(email, password);
      
      if (error) throw new Error(error);

      console.log('Role:', user.role);

      toast({
        title: 'Sign In Successful',
        description: 'You have successfully signed in.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Check role and navigate accordingly
      if (user.role === 'owner') {
        navigate('/owner-dashboard');
      } else if (user.role === 'therapist') {
        navigate('/therapist-dashboard');
      } else if (user.role === 'receptionist') {
        navigate('/receptionist-dashboard');
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      toast({
        title: 'Sign In Failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box p={8} maxWidth="400px" mx="auto" mt={16}>
      <Heading mb={6}>Sign In</Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="flex-start">
          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isDisabled={isLoading}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              isDisabled={isLoading}
            />
          </FormControl>
          <Button 
            type="submit" 
            colorScheme="teal" 
            size="lg" 
            width="full"
            isLoading={isLoading}
          >
            Sign In
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default SignIn;