import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://fwayhfhomihzinupnygs.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZ3YXloZmhvbWloemludXBueWdzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjIwODU5NDEsImV4cCI6MjAzNzY2MTk0MX0.Z-RrCvg6-heLYc823b_nRgJYm_rEci_LZrvnHuouwtw';

const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    storageKey: 'yourapp-auth'  // Optional but recommended
  }
});

export default supabase;
