// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import Navbar from './components/NavBar';
import Footer from './components/Footer';
import RegistrationForm from './components/RegistrationForm';
import Dashboard from './components/Dashboard';
import OwnerDashboard from './pages/OwnerDashboard';
import { AuthProvider, useAuth } from './context/AuthContext';
import TherapistDashboard from './pages/TherapistDashboard';
import { Center, Spinner } from '@chakra-ui/react';
import ReceptionistDashboard from './pages/ReceptionistDashboard';

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const location = useLocation(); // Add this

  // Handle loading state
  if (loading) {
    return <Center h="100vh"><Spinner /></Center>;
  }

  // If no user, redirect to sign-in
  if (!user) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  // If user exists but no role yet, show loading
  if (!user.role) {
    return <Center h="100vh"><Spinner /></Center>;
  }

  // Redirect to appropriate dashboard based on role
  if (location.pathname === '/dashboard') {
    if (user.role === 'owner') {
      return <Navigate to="/owner-dashboard" replace />;
    } else if (user.role === 'therapist') {
      return <Navigate to="/therapist-dashboard" replace />;
    } else if (user.role === 'receptionist') {
      return <Navigate to="/receptionist-dashboard" replace />;
    }
  }

  return children;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/register" element={<RegistrationForm />} />
          <Route 
            path="/owner-dashboard" 
            element={
              <ProtectedRoute>
                <OwnerDashboard />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/therapist-dashboard" 
            element={
              <ProtectedRoute>
                <TherapistDashboard />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/receptionist-dashboard" 
            element={
              <ProtectedRoute>
                <ReceptionistDashboard />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } 
          />
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;