// src/components/ClientManagement.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  VStack,
  HStack,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Switch,
  NumberInput,
  NumberInputField,
  IconButton,
  Divider,
  useToast,
  useDisclosure,
  Text,
  Badge,
  Stat,
  StatLabel,
  StatNumber,
  Spinner,
  Heading,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon, EditIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import supabase from '../supabaseClient';

// Updated ServiceRow component in ClientForm
const ServiceRow = ({ service, onDelete, onUpdate }) => {
  return (
    <HStack spacing={4} align="flex-start" width="100%">
      <FormControl isRequired>
        <FormLabel>Service Type</FormLabel>
        <Select 
          value={service.service_type}
          onChange={(e) => onUpdate({ ...service, service_type: e.target.value })}
        >
          <option value="">Select One</option>
          <option value="OT">Occupational Therapy</option>
          <option value="ST">Speech Therapy</option>
          <option value="PT">Physical Therapy</option>
          <option value="PG">Play Group</option>
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel>Frequency</FormLabel>
        <Input
          value={service.frequency}
          onChange={(e) => onUpdate({ ...service, frequency: e.target.value })}
          placeholder="e.g., 2x/week"
        />
      </FormControl>

      <FormControl>
        <FormLabel>Base Rate</FormLabel>
        <Input
            type="number"
            min={0}
            value={service.base_rate}
            onChange={(e) => onUpdate({ ...service, base_rate: e.target.value })}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Reservation Fee</FormLabel>
        <NumberInput
            min={0}
            value={service.reservation_fee || ''}
            onChange={(valueString) => onUpdate({ ...service, reservation_fee: valueString })}
        >
            <NumberInputField placeholder="Optional" />
        </NumberInput>
      </FormControl>

      <FormControl display="flex" alignItems="center" mt={8}>
        <FormLabel mb="0">VAT Inclusive</FormLabel>
        <Switch
          isChecked={service.vat_inclusive}
          onChange={(e) => onUpdate({ ...service, vat_inclusive: e.target.checked })}
        />
      </FormControl>

      <IconButton
        icon={<DeleteIcon />}
        colorScheme="red"
        onClick={() => onDelete(service)}
        aria-label="Delete service"
        mt={8}
      />
    </HStack>
  );
};

const ClientForm = ({ isOpen, onClose, initialData = null, onSave }) => {
  const [formData, setFormData] = useState({
    name: '',
    nickname: '', 
    age: '',
    gender: '',
    diagnosis: '',
    pwd: false,
    pwd_number: '',
    guardian_name: '',
    guardian_contact: '',
    status: 'Active',
    notes: '',
    services: []
  });
  const [allTherapists, setAllTherapists] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  useEffect(() => {
    if (initialData) {
      // Filter only currently valid services
      const currentDate = new Date().toISOString().split('T')[0];
      const validServices = initialData.client_services?.filter(service => 
        (!service.pricing_valid_from || service.pricing_valid_from <= currentDate) &&
        (!service.pricing_valid_until || service.pricing_valid_until >= currentDate)
      ) || [];

      setFormData({
        name: initialData.name || '',
        nickname: initialData.nickname || '', 
        age: initialData.age || '',
        gender: initialData.gender || '',
        diagnosis: initialData.diagnosis || '',
        pwd: Boolean(initialData.pwd),
        pwd_number: initialData.pwd_number || '',
        guardian_name: initialData.guardian_name || '',
        guardian_contact: initialData.guardian_contact || '',
        status: initialData.status || 'Active',
        notes: initialData.notes || '',
        services: validServices.map(service => ({
            id: service.id,
            service_type: service.service_type || '',
            frequency: service.frequency || '',
            base_rate: service.base_rate || '',
            reservation_fee: service.reservation_fee ? String(service.reservation_fee) : '',
            vat_inclusive: Boolean(service.vat_inclusive)
        }))
      });
    } else {
      // Reset form to initial state when initialData is null
      setFormData({
        name: '',
        nickname: '',
        age: '',
        gender: '',
        diagnosis: '',
        pwd: false,
        pwd_number: '',
        guardian_name: '',
        guardian_contact: '',
        status: 'Active',
        notes: '',
        services: []
      });
    }
  }, [initialData]);

  const fetchTherapists = async () => {
    const { data, error } = await supabase
      .from('therapists')
      .select('*')
      .eq('status', 'active');
    
    if (!error) {
      setAllTherapists(data);
    }
  };

  // In ClientForm component, update the handleSubmit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
  
    try {
      // First, save/update the client
      const clientData = {
        name: formData.name,
        nickname: formData.nickname,
        age: formData.age,
        gender: formData.gender,
        diagnosis: formData.diagnosis,
        pwd: formData.pwd,
        pwd_number: formData.pwd ? formData.pwd_number : null,
        guardian_name: formData.guardian_name,
        guardian_contact: formData.guardian_contact,
        status: formData.status,
        notes: formData.notes
      };
  
      let clientId;
      if (initialData) {
        console.log('Status: ', clientData.status);
        const { error } = await supabase
          .from('clients')
          .update(clientData)
          .eq('id', initialData.id);
        if (error) throw error;
        clientId = initialData.id;

        const currentDate = new Date().toISOString().split('T')[0];
        console.log('Date: ', currentDate);

        if (clientData.status === 'Inactive') {
            console.log('ENTERED');
            const { error } = await supabase
            .from('sessions')
            .update({valid_until: currentDate})
            .eq('client_id', initialData.id);
            if (error) throw error;
        }
  
        // Get current valid services
        const { data: existingServices, error: servicesError } = await supabase
          .from('client_services')
          .select('*')
          .eq('client_id', clientId)
          .is('pricing_valid_until', null);
  
        if (servicesError) throw servicesError;
  
        // Update existing services that have changed
        for (const existingService of existingServices) {
          const updatedService = formData.services.find(s => 
            s.service_type === existingService.service_type
          );
  
          if (updatedService && (
            updatedService.base_rate !== existingService.base_rate ||
            updatedService.vat_inclusive !== existingService.vat_inclusive
          )) {

            // Calculate previous day for valid_until
            const previousDay = new Date(currentDate);
            previousDay.setDate(previousDay.getDate() - 1);
            const previousDayStr = previousDay.toISOString().split('T')[0];

            // Set end date for old service
            await supabase
              .from('client_services')
              .update({ pricing_valid_until: previousDayStr })
              .eq('id', existingService.id);
  
            // Insert new service record
            await supabase
              .from('client_services')
              .insert({
                client_id: clientId,
                service_type: updatedService.service_type,
                frequency: updatedService.frequency,
                base_rate: updatedService.base_rate,
                reservation_fee: updatedService.reservation_fee || null,
                vat_inclusive: updatedService.vat_inclusive,
                pricing_valid_from: previousDayStr
              });
          }
        }
  
        // Insert new services
        const newServices = formData.services.filter(service => 
          !existingServices.some(es => es.service_type === service.service_type)
        );
  
        if (newServices.length > 0) {
          const newServicesData = newServices.map(service => ({
            client_id: clientId,
            service_type: service.service_type,
            frequency: service.frequency,
            base_rate: service.base_rate,
            reservation_fee: service.reservation_fee || null,
            vat_inclusive: service.vat_inclusive,
            pricing_valid_from: currentDate
          }));
  
          const { error: newServicesError } = await supabase
            .from('client_services')
            .insert(newServicesData);
  
          if (newServicesError) throw newServicesError;
        }
      } else {
        // Handle new client creation
        const { data, error } = await supabase
          .from('clients')
          .insert([clientData])
          .select()
          .single();
        if (error) throw error;
        clientId = data.id;
  
        // Insert all services for new client
        if (formData.services.length > 0) {
          const currentDate = new Date().toISOString().split('T')[0];
          const servicesData = formData.services.map(service => ({
            client_id: clientId,
            service_type: service.service_type,
            frequency: service.frequency,
            base_rate: service.base_rate,
            reservation_fee: service.reservation_fee || null,
            vat_inclusive: service.vat_inclusive,
            pricing_valid_from: currentDate
          }));
  
          const { error: servicesError } = await supabase
            .from('client_services')
            .insert(servicesData);
  
          if (servicesError) throw servicesError;
        }
      }
  
      toast({
        title: initialData ? 'Client Updated' : 'Client Added',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
  
      onSave();
      onClose();
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAddService = () => {
    setFormData(prev => ({
      ...prev,
      services: [...prev.services, {
        service_type: '',
        frequency: '',
        base_rate: '',
        therapists: []
      }]
    }));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>
            {initialData ? 'Edit Client' : 'Add New Client'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={6}>
              {/* Basic Information */}
              <Box width="100%">
                <VStack spacing={4} align="stretch">
                  <HStack spacing={4}>
                    <FormControl isRequired>
                        <FormLabel>Child's Name</FormLabel>
                        <Input
                        value={formData.name}
                        onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Nickname</FormLabel>
                        <Input
                            value={formData.nickname}
                            onChange={(e) => setFormData(prev => ({ ...prev, nickname: e.target.value }))}
                            placeholder="Optional"
                        />
                    </FormControl>
                  </HStack>

                  <HStack spacing={4}>
                    <FormControl>
                        <FormLabel>Age</FormLabel>
                        <Input
                            type="number"
                            value={formData.age}
                            onChange={(e) => setFormData(prev => ({ ...prev, age: e.target.value }))}
                        />
                    </FormControl>

                    <FormControl>
                      <FormLabel>Gender</FormLabel>
                      <Select
                        value={formData.gender}
                        onChange={(e) => setFormData(prev => ({ ...prev, gender: e.target.value }))}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Select>
                    </FormControl>
                  </HStack>

                  <FormControl>
                    <FormLabel>Diagnosis</FormLabel>
                    <Input
                      value={formData.diagnosis}
                      onChange={(e) => setFormData(prev => ({ ...prev, diagnosis: e.target.value }))}
                    />
                  </FormControl>

                  <FormControl display="flex" alignItems="center">
                    <HStack spacing={4} width="100%">
                        <Box flex="1">
                        <FormLabel mb="0">PWD</FormLabel>
                        <Switch
                            isChecked={formData.pwd}
                            onChange={(e) => setFormData(prev => ({ ...prev, pwd: e.target.checked }))}
                        />
                        </Box>
                        {formData.pwd && (
                        <FormControl flex="2">
                            <FormLabel>PWD No.</FormLabel>
                            <Input
                            type="text"
                            value={formData.pwd_number || ''}
                            onChange={(e) => setFormData(prev => ({ ...prev, pwd_number: e.target.value }))}
                            placeholder="Enter PWD ID Number"
                            />
                        </FormControl>
                        )}
                    </HStack>
                  </FormControl>
                </VStack>
              </Box>

              <Divider />

              {/* Services */}
              <Box width="100%">
                <VStack spacing={4} align="stretch">
                  <HStack justify="space-between">
                    <FormLabel>Services</FormLabel>
                    <Button
                      leftIcon={<AddIcon />}
                      size="sm"
                      onClick={handleAddService}
                    >
                      Add Service
                    </Button>
                  </HStack>

                  {formData.services.map((service, index) => (
                    <ServiceRow
                      key={index}
                      service={service}
                      onDelete={() => {
                        setFormData(prev => ({
                          ...prev,
                          services: prev.services.filter((_, i) => i !== index)
                        }));
                      }}
                      onUpdate={(updatedService) => {
                        setFormData(prev => ({
                          ...prev,
                          services: prev.services.map((s, i) => 
                            i === index ? updatedService : s
                          )
                        }));
                      }}
                      therapists={service.therapists}
                      allTherapists={allTherapists}
                    />
                  ))}
                </VStack>
              </Box>

              <Divider />

              {/* Guardian Information */}
              <Box width="100%">
                <VStack spacing={4} align="stretch">
                  <FormControl>
                    <FormLabel>Guardian's Name</FormLabel>
                    <Input
                      value={formData.guardian_name}
                      onChange={(e) => setFormData(prev => ({ ...prev, guardian_name: e.target.value }))}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Guardian's Contact Number</FormLabel>
                    <Input
                      value={formData.guardian_contact}
                      onChange={(e) => setFormData(prev => ({ ...prev, guardian_contact: e.target.value }))}
                    />
                  </FormControl>
                </VStack>
              </Box>

              <Divider />

              {/* Additional Information */}
              <Box width="100%">
                <VStack spacing={4} align="stretch">
                  <FormControl>
                    <FormLabel>Notes</FormLabel>
                    <Textarea
                      value={formData.notes}
                      onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Status</FormLabel>
                    <Select
                      value={formData.status}
                      onChange={(e) => setFormData(prev => ({ ...prev, status: e.target.value }))}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </Select>
                  </FormControl>
                </VStack>
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button type="submit" colorScheme="blue" mr={3} isLoading={isSubmitting}>
              {initialData ? 'Update Client' : 'Add Client'}
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const ClientAttendanceLog = ({ client, onBack }) => {
  const [attendanceLogs, setAttendanceLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    fetchAttendanceLogs();
  }, [client]);

  const formatTime = (time) => {
    if (!time) return '';
    const hour = parseInt(time.split(':')[0]);
    const h = hour % 12 || 12;
    const ampm = hour < 12 ? 'AM' : 'PM';
    return `${h}:00 ${ampm}`;
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-PH', {
      style: 'currency',
      currency: 'PHP'
    }).format(amount);
  };

  const fetchAttendanceLogs = async () => {
    try {
      const { data, error } = await supabase
        .from('session_attendance')
        .select(`
          *,
          session:sessions (
            day,
            time,
            therapist:therapists (
              name,
              specialization
            )
          )
        `)
        .eq('client_id', client.id)
        .order('attendance_date', { ascending: false });

      if (error) throw error;
      setAttendanceLogs(data);
    } catch (error) {
      toast({
        title: 'Error fetching attendance logs',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getStats = () => {
    const total = attendanceLogs.length;
    const present = attendanceLogs.filter(log => log.status === 'present').length;
    const absent = attendanceLogs.filter(log => log.status === 'absent').length;
    const cancelled = attendanceLogs.filter(log => log.status === 'cancelled').length;
    return { total, present, absent, cancelled };
  };

  const stats = getStats();

  if (isLoading) return <Spinner />;

  return (
    <Box>
      <Button leftIcon={<ChevronLeftIcon />} onClick={onBack} mb={6}>
        Back to Clients
      </Button>
      
      <VStack spacing={6} align="stretch">
        <Box>
          <Heading size="lg">{client.name}'s Attendance Log</Heading>
          {client.nickname && (
            <Text fontSize="lg" color="gray.600">"{client.nickname}"</Text>
          )}
        </Box>

        <HStack spacing={8} p={4} bg="gray.50" borderRadius="md">
          <Stat>
            <StatLabel>Total Sessions</StatLabel>
            <StatNumber>{stats.total}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Present</StatLabel>
            <StatNumber color="green.500">{stats.present}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Absent</StatLabel>
            <StatNumber color="red.500">{stats.absent}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Cancelled</StatLabel>
            <StatNumber color="orange.500">{stats.cancelled}</StatNumber>
          </Stat>
        </HStack>

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Date/Time</Th>
              <Th>Session Type</Th>
              <Th>Service Type</Th>
              <Th>Therapist</Th>
              <Th>Status</Th>
              <Th>Amount Paid</Th>
              <Th>Notes</Th>
            </Tr>
          </Thead>
          <Tbody>
            {attendanceLogs.map(log => (
              <Tr key={log.id}>
                <Td>
                  <VStack align="start" spacing={0}>
                  <Text>{new Date(log.attendance_date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })}</Text>
                    <Text fontSize="sm" color="gray.600">
                      {log.session.day}, {formatTime(log.session.time)}
                    </Text>
                  </VStack>
                </Td>
                <Td>
                  <Badge colorScheme={log.session_type === 'Regular' ? 'blue' : 'green'}>
                    {log.session_type}
                  </Badge>
                </Td>
                <Td>{log.session.therapist.specialization}</Td>
                <Td>{log.session.therapist.name}</Td>
                <Td>
                  <Badge
                    colorScheme={
                      log.status === 'present' ? 'green' : 
                      log.status === 'absent' ? 'red' : 'orange'
                    }
                  >
                    {log.status}
                  </Badge>
                </Td>
                <Td>{log.amount_paid ? formatCurrency(log.amount_paid) : '-'}</Td>
                <Td>{log.notes || '-'}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </VStack>
    </Box>
  );
};

// Continuing from where we left off...

const ClientManagement = ({ LoadingComponent }) => {
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    // Search and filter states
    const [searchTerm, setSearchTerm] = useState('');
    const [therapistFilter, setTherapistFilter] = useState('');
    const [serviceFilter, setServiceFilter] = useState('');

    // Unique therapists and services for dropdown
    const [uniqueTherapists, setUniqueTherapists] = useState([]);
    const [uniqueServices, setUniqueServices] = useState([]);

    const [selectedClientForLog, setSelectedClientForLog] = useState(null);
  
    useEffect(() => {
      fetchClients();
    }, []);

    // Update filtered clients whenever search or filters change
    useEffect(() => {
        let result = clients;

        // Name search filter
        if (searchTerm) {
            result = result.filter(client => 
                client.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        // Therapist filter
        if (therapistFilter) {
            result = result.filter(client => 
                client.services.some(service => 
                    service.therapists.includes(therapistFilter)
                )
            );
        }

        // Service filter
        if (serviceFilter) {
            result = result.filter(client => 
                client.services.some(service => 
                    service.service_type === serviceFilter
                )
            );
        }

        setFilteredClients(result);
    }, [clients, searchTerm, therapistFilter, serviceFilter]);
  
    const fetchClients = async () => {
        try {
            // Fetch clients with their services
            const { data: clients, error: clientsError } = await supabase
            .from('clients')
            .select(`
                *,
                client_services (
                    *
                ),
                sessions (
                    id,
                    client_id,
                    therapist_id,
                    session_type,
                    day,
                    time,
                    valid_from,
                    valid_until,
                    therapist:therapists (
                        id,
                        name,
                        specialization
                    )
                )
            `);
    
            if (clientsError) throw clientsError;
    
            const currentDate = new Date().toISOString().split('T')[0];
            // Process the data to combine services and sessions
            const processedClients = clients.map(client => {
                // Filter valid services first
                const validServices = Array.isArray(client.client_services) 
                    ? client.client_services.filter(service => 
                        (!service.pricing_valid_from || service.pricing_valid_from <= currentDate) &&
                        (!service.pricing_valid_until || service.pricing_valid_until >= currentDate)
                      )
                    : [];
    
                return {
                    ...client,
                    services: validServices.map(service => {
                        const specializationMap = {
                            'OT': 'Occupational Therapy',
                            'ST': 'Speech Therapy',
                            'PT': 'Physical Therapy',
                            'PG': 'Play Group'
                        };

                        // Filter valid sessions based on dates
                        const validSessions = (client.sessions || [])
                            .filter(session => 
                                session.client_id === client.id && 
                                session.therapist.specialization === specializationMap[service.service_type] &&
                                (!session.valid_from || session.valid_from <= currentDate) &&
                                (!session.valid_until || session.valid_until >= currentDate)
                            );
    

                        const serviceSessions = validSessions
                            .map(session => ({
                                day: session.day,
                                time: formatTime(session.time)
                            }));

                        const serviceTherapists = validSessions
                            .map(session => session.therapist.name)
                            .filter((value, index, self) => self.indexOf(value) === index);
    
                        return {
                            id: service.id,
                            service_type: service.service_type,
                            frequency: service.frequency,
                            base_rate: service.base_rate,
                            therapists: serviceTherapists,
                            schedule: serviceSessions.length > 0 
                                ? serviceSessions.map(s => `${s.day} ${s.time}`).join(' | ')
                                : 'Not scheduled'
                        };
                    })
                };
            });

            // Add the sorting before setting the state:
            const sortedClients = processedClients.sort(sortByLastName);
            setClients(processedClients);

            // Extract unique therapists and services
            const allTherapists = new Set();
            const allServices = new Set();

            processedClients.forEach(client => {
                client.services.forEach(service => {
                    service.therapists.forEach(therapist => allTherapists.add(therapist));
                    allServices.add(service.service_type);
                });
            });

            setUniqueTherapists(Array.from(allTherapists));
            setUniqueServices(Array.from(allServices));
        } catch (error) {
            console.error('Error details:', error);
            toast({
                title: 'Error fetching clients',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsLoading(false);
        }
    };

    // Add these helper functions here
    const formatName = (fullName) => {
        const nameParts = fullName.trim().split(' ');
        const lastName = nameParts[nameParts.length - 1];
        const firstAndMiddle = nameParts.slice(0, -1).join(' ');
        return `${lastName}, ${firstAndMiddle}`;
    };

    const sortByLastName = (a, b) => {
        const aLastName = a.name.trim().split(' ').pop();
        const bLastName = b.name.trim().split(' ').pop();
        return aLastName.localeCompare(bLastName);
    };
    
    // Helper function to format time
    const formatTime = (time) => {
        if (!time) return '';
        const hour = parseInt(time.split(':')[0]);
        const h = hour % 12 || 12;
        const ampm = hour < 12 ? 'AM' : 'PM';
        return `${h}:00 ${ampm}`;
    };
  
    const handleEditClient = (client) => {
        console.log('Original client data:', client); // For debugging
        console.log('Client Age:', client.age); // For debugging
        const formattedClient = {
            ...client,
            age: client.age ? String(client.age) : '', // Explicitly convert to string
            gender: client.gender || '',
            diagnosis: client.diagnosis || '',
            pwd: Boolean(client.pwd),
            pwd_number: client.pwd_number || '',
            guardian_name: client.guardian_name || '',
            guardian_contact: client.guardian_contact || '',
            status: client.status || 'Active',
            notes: client.notes || '',
            services: (client.client_services || []).map(service => ({
                id: service.id,
                service_type: service.service_type,
                frequency: service.frequency,
                base_rate: service.base_rate ? String(service.base_rate) : '',
                reservation_fee: service.reservation_fee ? String(service.reservation_fee) : ''
            }))
        };
        console.log('Formatted client data:', formattedClient); // For debugging
        setSelectedClient(formattedClient);
        onOpen();
    };
  
    const handleCloseForm = () => {
      setSelectedClient(null);
      onClose();
    };
  
    const handleClientSaved = () => {
      fetchClients();
      handleCloseForm();
    };

    if (isLoading) {
        return <LoadingComponent />;
    }
  
    return (
      <Box>
        {!selectedClientForLog &&
        <VStack spacing={4} mb={4}>
            <HStack width="100%">
                <Input 
                    placeholder="Search by client name" 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Select 
                    placeholder="Filter by Therapist"
                    value={therapistFilter}
                    onChange={(e) => setTherapistFilter(e.target.value)}
                >
                    {uniqueTherapists.map(therapist => (
                        <option key={therapist} value={therapist}>
                            {therapist}
                        </option>
                    ))}
                </Select>
                <Select 
                    placeholder="Filter by Service"
                    value={serviceFilter}
                    onChange={(e) => setServiceFilter(e.target.value)}
                >
                    {uniqueServices.map(service => (
                        <option key={service} value={service}>
                            {service}
                        </option>
                    ))}
                </Select>
            </HStack>
        </VStack>
        }
        {!selectedClientForLog &&
        <Button 
          colorScheme="blue" 
          onClick={() => {
            setSelectedClient(null); // Explicitly set to null before opening
            onOpen();
          }}
          mb={4}
        >
          Add New Client
        </Button>
        }

        {selectedClientForLog && 
        <ClientAttendanceLog 
            client={selectedClientForLog}
            onBack={() => setSelectedClientForLog(null)}
            />
        }

        {!selectedClientForLog &&
        <Table variant="simple">
            <Thead>
                <Tr>
                <Th>Child's Name</Th>
                <Th>Age/Gender</Th>
                <Th>Guardian</Th>
                <Th>Services & Therapists</Th>
                <Th>Status</Th>
                <Th>Actions</Th>
                </Tr>
            </Thead>
            <Tbody>
                {filteredClients.map(client => (
                <Tr 
                  key={client.id} 
                  onClick={() => setSelectedClientForLog(client)}
                  cursor="pointer"
                  _hover={{ bg: 'gray.50' }}
                >
                    <Td>
                        <VStack align="start" spacing={0}>
                            <Text>{formatName(client.name)}</Text>
                            {client.nickname && (
                            <Text fontSize="sm" color="gray.600">"{client.nickname}"</Text>
                            )}
                            <Text fontSize="sm" color="gray.600">
                                {client.pwd ? 'PWD' : 'Non-PWD'}
                            </Text>
                        </VStack>
                    </Td>
                    <Td>{`${client.age}/${client.gender[0]}`}</Td>
                    <Td>
                    <VStack align="start" spacing={0}>
                        <Text>{client.guardian_name}</Text>
                        <Text fontSize="sm" color="gray.600">
                        {client.guardian_contact}
                        </Text>
                    </VStack>
                    </Td>
                    <Td>
                    <VStack align="start" spacing={3}>
                        {client.services
                        .filter(service => {
                            const currentDate = new Date().toISOString().split('T')[0];
                            return (!service.pricing_valid_from || service.pricing_valid_from <= currentDate) &&
                                (!service.pricing_valid_until || service.pricing_valid_until >= currentDate);
                        })
                        .map((service, index) => (
                            <Box key={index}>
                            <Text fontWeight="medium">
                                {service.service_type} - {service.frequency}
                            </Text>
                            {service.therapists && service.therapists.length > 0 ? (
                                <Text fontSize="sm" color="gray.600">
                                Therapist/s: {service.therapists.join(', ')}
                                </Text>
                            ) : (
                                <Text fontSize="sm" color="gray.400" fontStyle="italic">
                                No therapists assigned
                                </Text>
                            )}
                            <Text fontSize="sm" color="gray.500">
                                Schedule: {service.schedule ? service.schedule : 'Not specified'}
                            </Text>
                            </Box>
                        ))}
                    </VStack>
                    </Td>
                    <Td>
                    <Badge
                        colorScheme={client.status === 'Active' ? 'green' : 'red'}
                    >
                        {client.status}
                    </Badge>
                    </Td>
                    <Td>
                    <IconButton
                        icon={<EditIcon />}
                        onClick={() => handleEditClient(client)}
                        aria-label="Edit client"
                        size="sm"
                        colorScheme="blue"
                    />
                    </Td>
                </Tr>
                ))}
            </Tbody>
        </Table>
        }
  
        <ClientForm
          isOpen={isOpen}
          onClose={handleCloseForm}
          initialData={selectedClient}
          onSave={handleClientSaved}
        />
      </Box>
    );
  };
  
  export default ClientManagement;