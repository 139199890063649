// src/components/TherapistFinance.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Select,
  VStack,
  Spinner,
  Center,
  useToast
} from '@chakra-ui/react';
import TherapistFinanceSummary from './TherapistFinanceSummary';
import TherapistPayslips from './TherapistPayslips';
import TherapistSessions from './TherapistSessions';
import supabase from '../supabaseClient';

const useAvailableYears = (therapistId) => {
  const [availableYears, setAvailableYears] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAvailableYears = async () => {
      try {
        const { data: firstSession } = await supabase
          .from('session_attendance')
          .select('attendance_date')
          .eq('therapist_id', therapistId)
          .eq('status', 'present')
          .order('attendance_date', { ascending: true })
          .limit(1)
          .single();

        if (firstSession) {
          const startYear = new Date(firstSession.attendance_date).getFullYear();
          const currentYear = new Date().getFullYear();
          const years = [];
          for (let year = currentYear; year >= startYear; year--) {
            years.push(year);
          }
          setAvailableYears(years);
        }
      } catch (error) {
        console.error('Error fetching available years:', error);
        setAvailableYears([new Date().getFullYear()]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAvailableYears();
  }, [therapistId]);

  return { availableYears, isLoading };
};

const TherapistFinance = ({ therapistId }) => {
  const { availableYears, isLoading: yearsLoading } = useAvailableYears(therapistId);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const toast = useToast();

  useEffect(() => {
    if (availableYears.length > 0 && !availableYears.includes(selectedYear)) {
      setSelectedYear(availableYears[0]);
    }
  }, [availableYears]);

  if (yearsLoading) {
    return (
      <Center py={8}>
        <Spinner />
      </Center>
    );
  }

  const handleTabChange = (index) => {
    setSelectedTabIndex(index);
  };

  return (
    <Box>
      <Select
        value={selectedYear}
        onChange={(e) => setSelectedYear(parseInt(e.target.value))}
        maxW="200px"
        mb={6}
        isDisabled={availableYears.length <= 1}
      >
        {availableYears.map(year => (
          <option key={year} value={year}>{year}</option>
        ))}
      </Select>

      <Tabs index={selectedTabIndex}  onChange={handleTabChange}>
        <TabList>
          <Tab>Summary</Tab>
          <Tab>Payslips</Tab>
          <Tab>Sessions</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <TherapistFinanceSummary 
              key={`therapist-finance-summary-${selectedTabIndex}`}
              therapistId={therapistId} 
              selectedYear={selectedYear}
            />
          </TabPanel>
          <TabPanel>
            <TherapistPayslips 
              key={`therapist-payslips-${selectedTabIndex}`}
              therapistId={therapistId} 
              selectedYear={selectedYear}
            />
          </TabPanel>
          <TabPanel>
            <TherapistSessions
              key={`therapist-sessions-${selectedTabIndex}`}
              therapistId={therapistId}
              selectedYear={selectedYear}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default TherapistFinance;