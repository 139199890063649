// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import supabase from '../supabaseClient';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);

  const fetchProfile = async (userData) => {
    try {
      console.log('USER DATA: ', userData);
      
      // First fetch the user role
      const { data: userRoleData, error: userRoleError } = await supabase
        .from('users')
        .select('role')
        .eq('id', userData.id)
        .single();
  
      if (userRoleError) throw userRoleError;
  
      // Based on the role, fetch the appropriate profile
      if (userRoleData.role === 'therapist') {
        const { data: therapistData } = await supabase
          .from('therapists')
          .select('*')
          .eq('user_id', userData.id)
          .single();
  
        if (therapistData) {
          setProfile({ ...therapistData, role: 'therapist' });
          // Update user with role
          setUser(prev => ({ ...prev, role: 'therapist' }));
          return;
        }
      } else if (userRoleData.role === 'owner') {
        setProfile({ ...userData, role: 'owner' });
        setUser(prev => ({ ...prev, role: 'owner' }));
        return;
      } else if (userRoleData.role === 'receptionist') {
        setProfile({ ...userData, role: 'receptionist' });
        setUser(prev => ({ ...prev, role: 'receptionist' }));
        return;
      }
  
      setProfile(null);
    } catch (error) {
      console.error('Error fetching profile:', error);
      setProfile(null);
    }
  };

  useEffect(() => {
    // Check active sessions and sets the user
    const initializeAuth = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (error) {
          throw error;
        }

        if (session?.user) {
          console.log('User: ', session.user)
          setUser(session.user);
          await fetchProfile(session.user);
        }
      } catch (error) {
        console.error('Error initializing auth:', error);
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();

    // Listen for changes on auth state (sign in, sign out, etc.)
    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('Auth state changed:', event, session?.user);
      
      if (session?.user) {
        console.log('Session.User: ', session.user);
        setUser(session.user);
        //await fetchProfile(session.user);
      } else {
        setUser(null);
        setProfile(null);
      }
      setLoading(false);
    });

    return () => subscription?.unsubscribe();
  }, []);

  const login = async (email, password) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;

      // Fetch user role after successful login
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('role')
        .eq('id', data.user.id)
        .single();

      if (userError) throw userError;

      // Set user immediately
      setUser(data.user);

      // Fetch profile after successful login
      await fetchProfile(data.user);

      return { user: { ...data.user, role: userData.role }, error: null };
    } catch (error) {
      console.error('Login error:', error);
      return { user: null, error: error.message };
    }
  };

  const logout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setUser(null);
      setProfile(null);
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  const value = {
    user,
    profile,
    login,
    logout,
    loading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);