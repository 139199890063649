// src/components/TherapistSessions.js
import React, { useState, useEffect } from 'react';
import {
    VStack,
    HStack,
    Stack,
    Box,
    Card,
    CardHeader,
    CardBody,
    Heading,
    Text,
    Select,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup,
    Spinner,
    Center,
    useToast
} from '@chakra-ui/react';
import supabase from '../supabaseClient';

const TherapistSessions = ({ therapistId, selectedYear }) => {
    const [cycles, setCycles] = useState([]);
    const [selectedCycle, setSelectedCycle] = useState(null);
    const [sessions, setSessions] = useState([]);
    const [summary, setSummary] = useState({
        totalHours: 0,
        sessionTypes: {}
    });
    const [isLoading, setIsLoading] = useState(true);
    const toast = useToast();

    // Get list of cycles for the selected year
    useEffect(() => {
        const generateCycles = () => {
            const cycles = [];
            // Start from Dec 27 of previous year
            const startDate = new Date(selectedYear - 1, 11, 27);
            
            // End at Dec 26 of selected year
            const endDate = new Date(selectedYear, 11, 26);
            
            let currentDate = startDate;
            while (currentDate <= endDate) {
                const cycleStart = new Date(currentDate);
                const cycleEnd = new Date(currentDate);
                cycleEnd.setMonth(cycleEnd.getMonth() + 1);
                cycleEnd.setDate(26);
                
                cycles.push({
                    start: cycleStart,
                    end: cycleEnd,
                    label: `${cycleStart.toLocaleDateString('en-US', { 
                        month: 'short',
                        day: 'numeric'
                    })} - ${cycleEnd.toLocaleDateString('en-US', { 
                        month: 'short',
                        day: 'numeric'
                    })}`
                });

                currentDate = new Date(cycleEnd);
                currentDate.setDate(27);
            }
            return cycles;
        };

        const cycles = generateCycles();
        setCycles(cycles);
        // Find current cycle
        if (cycles.length > 0 && !selectedCycle) {
            const today = new Date();
            const currentCycle = cycles.find(cycle => 
                today >= cycle.start && today <= cycle.end
            ) || cycles[0];  // Default to first (most recent) if not found
            setSelectedCycle(currentCycle);
        }
    }, [selectedYear]);

    // Fetch sessions for selected cycle
    useEffect(() => {
        const fetchSessions = async () => {
            if (!selectedCycle) return;

            setIsLoading(true);
            try {
                const formatToLocalDate = (date) => {
                    return date.toISOString().split('T')[0];
                };

                // In the fetchSessions useEffect:
                const { data: sessions, error } = await supabase
                    .from('session_attendance')
                    .select(`
                        *,
                        session:sessions (
                            client:clients (
                                name
                            )
                        )
                    `)
                    .eq('therapist_id', therapistId)
                    .eq('status', 'present')
                    .gte('attendance_date', formatToLocalDate(selectedCycle.start))
                    .lte('attendance_date', formatToLocalDate(selectedCycle.end))
                    .order('attendance_date', { ascending: false }); // Changed to false to sort by most recent first

                if (error) throw error;

                setSessions(sessions);

                // Calculate summary
                const summary = sessions.reduce((acc, session) => {
                    acc.totalHours++;
                    acc.sessionTypes[session.session_type] = 
                        (acc.sessionTypes[session.session_type] || 0) + 1;
                    return acc;
                }, { totalHours: 0, sessionTypes: {} });

                setSummary(summary);

            } catch (error) {
                toast({
                    title: 'Error fetching sessions',
                    description: error.message,
                    status: 'error',
                    duration: 5000
                });
            } finally {
                setIsLoading(false);
            }
        };

        fetchSessions();
    }, [selectedCycle, therapistId]);

    if (isLoading) {
        return (
            <Center py={8}>
                <Spinner />
            </Center>
        );
    }

    return (
        <VStack spacing={6} align="stretch" minW="340px">
            <Card>
            <CardHeader>
                <Stack 
                    direction={{ base: 'column', md: 'row' }} 
                    spacing={4} 
                    align={{ base: 'stretch', md: 'center' }}
                    justify="space-between"
                >
                    <Select
                        value={cycles.findIndex(c => c.label === selectedCycle?.label)}
                        onChange={(e) => setSelectedCycle(cycles[e.target.value])}
                        maxW={{ base: "100%", md: "300px" }}
                    >
                        {cycles.map((cycle, index) => (
                            <option key={index} value={index}>{cycle.label}</option>
                        ))}
                    </Select>
                    
                    <StatGroup 
                        width={{ base: '100%', md: 'auto' }}
                        display="flex"
                        flexWrap="wrap"
                        gap={4}
                    >
                        <Stat minW={{ base: "70px", md: "80px" }}>
                            <StatLabel>Total Hours</StatLabel>
                            <StatNumber>{summary.totalHours}</StatNumber>
                        </Stat>
                        {Object.entries(summary.sessionTypes).map(([type, count]) => (
                            <Stat key={type} minW={{ base: "80px", md: "90px" }}>
                                <StatLabel>{type}</StatLabel>
                                <StatNumber>{count}</StatNumber>
                            </Stat>
                        ))}
                    </StatGroup>
                </Stack>
            </CardHeader>
                <CardBody>
                    <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th fontSize={{ base: "xs", md: "sm" }}>Date</Th>
                            <Th fontSize={{ base: "xs", md: "sm" }}>Client</Th>
                            <Th fontSize={{ base: "xs", md: "sm" }}>Type</Th>
                        </Tr>
                    </Thead>
                        <Tbody>
                            {sessions.map((session) => (
                                <Tr key={session.id}>
                                    <Td fontSize={{ base: "xs", md: "sm" }}>{new Date(session.attendance_date).toLocaleDateString()}</Td>
                                    <Td fontSize={{ base: "xs", md: "sm" }}>{session.session.client.name}</Td>
                                    <Td fontSize={{ base: "xs", md: "sm" }}>{session.session_type}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </CardBody>
            </Card>
        </VStack>
    );
};

export default TherapistSessions;