// src/components/TherapistPayslips.js
import React, { useState, useEffect } from 'react';
import {
  VStack,
  Box,
  Card,
  CardBody,
  Text,
  Button,
  IconButton,
  Collapse,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  HStack,
  Spinner,
  Center,
  useToast
} from '@chakra-ui/react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { DownloadIcon } from '@chakra-ui/icons';
import supabase from '../supabaseClient';

const TherapistPayslips = ({ therapistId, selectedYear }) => {
  const [payslips, setPayslips] = useState([]);
  const [expandedPayslip, setExpandedPayslip] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    fetchPayslips();
  }, [therapistId, selectedYear]);

  const getBiMonthlyPeriods = (year) => {
    const periods = [];
    for (let month = 0; month < 12; month++) {
      // First half (13-26)
      periods.push({
        start: new Date(year, month, 13),
        end: new Date(year, month, 26)
      });
      // Second half (27-12 of next month)
      periods.push({
        start: new Date(year, month, 27),
        end: new Date(year, month + 1, 12)
      });
    }
    return periods;
  };
  
  // Add this to check if a period should be shown
  const shouldShowPeriod = (endDate) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const periodEnd = new Date(endDate);
    return periodEnd < today;
  };

  // Add this near the top with other helper functions
  const shouldShowBenefitsForPeriod = (startDate) => {
    const date = new Date(startDate);
    return date.getDate() === 13; // Only show benefits for periods starting on the 13th
  };

  const fetchPayslips = async () => {
    try {
      setIsLoading(true);
      // First, get therapist's first session date
      const { data: firstSession } = await supabase
        .from('session_attendance')
        .select('attendance_date')
        .eq('therapist_id', therapistId)
        .eq('status', 'present')
        .order('attendance_date', { ascending: true })
        .limit(1)
        .single();

      if (!firstSession) {
        setPayslips([]);
        return;
      }

      const periods = getBiMonthlyPeriods(selectedYear);
      console.log('Periods: ', periods);

      const formatToLocalDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
  
        // In the payslipsData mapping function:
        const payslipsData = await Promise.all(
            periods
            .filter(period => shouldShowPeriod(period.end))
            .map(async (period) => {
                const periodStartDate = formatToLocalDate(period.start);
                const periodEndDate = formatToLocalDate(period.end);
        
                // Check if period is already paid
                const { data: paymentRecord } = await supabase
                .from('payroll_payments')
                .select('*')
                .eq('therapist_id', therapistId)
                .eq('start_date', periodStartDate)
                .eq('end_date', periodEndDate)
                .maybeSingle();
        
                // Get valid service fee for this period
                const { data: serviceFee } = await supabase
                .from('service_fees')
                .select('amount')
                .eq('therapist_id', therapistId)
                .lte('valid_from', periodEndDate)
                .or(`valid_until.is.null,valid_until.gte.${periodStartDate}`)
                .order('valid_from', { ascending: false })
                .limit(1)
                .single();
        
                // Get sessions for this period
                const { data: sessions, error: sessionsError } = await supabase
                .from('session_attendance')
                .select(`
                    *,
                    session:sessions (
                    client:clients (
                        name
                    )
                    )
                `)
                .eq('therapist_id', therapistId)
                .eq('status', 'present')
                .gte('attendance_date', periodStartDate)
                .lte('attendance_date', periodEndDate);
        
                if (!sessions?.length) return null;
        
                // Add service fee to each session
                const processedSessions = sessions.map(session => ({
                ...session,
                service_fee: session.session_type === 'IE' ? 2200 : (serviceFee?.amount || 0)
                }));
        
                // Check benefits eligibility
                const periodDate = new Date(periodStartDate);
                const { isEligible, totalHours } = await checkBenefitsEligibility(
                therapistId,
                periodDate.getMonth() + 1,
                periodDate.getFullYear()
                );
        
                // Calculate total earnings
                const totalAmount = processedSessions.reduce((sum, session) => 
                sum + session.service_fee, 0
                );
        
                // Modify the benefits calculation
                const shouldAddBenefits = isEligible && shouldShowBenefitsForPeriod(periodStartDate);
                const totalWithBenefits = shouldAddBenefits ? totalAmount + 2500 : totalAmount;
        
                return {
                startDate: periodStartDate,
                endDate: periodEndDate,
                sessions: processedSessions,  // Use the processed sessions with service fees
                totalAmount,
                totalWithBenefits,
                isEligibleForBenefits: isEligible,
                shouldShowBenefits: shouldAddBenefits, // New property
                totalHours,
                isPaid: !!paymentRecord,
                paymentId: paymentRecord?.id
                };
            })
        );
  
      // Filter out null periods (no sessions) and sort by date
      setPayslips(payslipsData
        .filter(period => period !== null)
        .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
      );
    } catch (error) {
      toast({
        title: 'Error fetching payslips',
        description: error.message,
        status: 'error',
        duration: 5000
      });
    } finally {
      setIsLoading(false);
    }
  };

  const checkBenefitsEligibility = async (therapistId, month, year) => {
    try {
      let startYear = year;
      let startMonth = month - 1;
      
      // Handle December to January transition
      if (startMonth === 0) { // January
        startMonth = 12; // December
        startYear = year - 1;
      }
  
      const startDate = new Date(startYear, startMonth - 1, 27); // Previous month's 27th
      const endDate = new Date(year, month - 1, 26);  // Current month's 26th
  
      console.log('Checking benefits from:', startDate, 'to:', endDate);
  
      const { data: sessions, error } = await supabase
        .from('session_attendance')
        .select('*')
        .eq('therapist_id', therapistId)
        .eq('status', 'present')
        .gte('attendance_date', startDate.toISOString().split('T')[0])
        .lte('attendance_date', endDate.toISOString().split('T')[0]);
  
      if (error) throw error;
  
      const totalHours = sessions?.length || 0;
      return { isEligible: totalHours >= 72, totalHours };
    } catch (error) {
      console.error('Error checking benefits eligibility:', error);
      return { isEligible: false, totalHours: 0 };
    }
};

    const generatePayslipPDF = async (payslip) => {
        try {
            const doc = new jsPDF({
                unit: 'mm',
                format: 'a4',
                putOnlyUsedFonts: true
            });

            const drawHeader = () => {
                const pageWidth = doc.internal.pageSize.width;
                doc.setFillColor(52, 180, 226);
                doc.rect(0, 0, pageWidth, 25, 'F');
                doc.setTextColor(255, 255, 255);
                doc.setFontSize(20);
                doc.text('Tiny Triumphs', pageWidth/2, 12, { align: 'center' });
                doc.setFontSize(14);
                doc.text('PAYSLIP', pageWidth/2, 20, { align: 'center' });
                doc.setTextColor(0, 0, 0);
            };

            const pageWidth = doc.internal.pageSize.width;
            const pageHeight = doc.internal.pageSize.height;
            drawHeader();

            // Payroll Period
            doc.setFontSize(12);
            doc.text(`Payroll Period: ${formatDateRange(payslip.startDate, payslip.endDate)}`, pageWidth/2, 35, { align: 'center' });

            // Therapist Info
            const { data: therapist } = await supabase
                .from('therapists')
                .select('*')
                .eq('id', therapistId)
                .single();

            doc.text(`Therapist: ${therapist?.name || ''}`, 20, 45);
            doc.text(`Specialization: ${therapist?.specialization || ''}`, 20, 52);

            // Session Details Table
            let tableData = payslip.sessions.map(session => [
                new Date(session.attendance_date).toLocaleDateString(),
                session.session.client.name,
                session.session_type,
                formatCurrencyPDF(session.service_fee)
            ]);

            tableData.push([
                { content: 'Total', colSpan: 3, styles: { fontStyle: 'bold' } },
                { content: formatCurrencyPDF(payslip.totalAmount), styles: { fontStyle: 'bold' } }
            ]);

            // Calculate space needed for summary and benefits
            const sessionTypeCounts = payslip.sessions.reduce((acc, session) => {
                acc[session.session_type] = (acc[session.session_type] || 0) + 1;
                return acc;
            }, {});
            
            const summaryHeight = Object.keys(sessionTypeCounts).length * 7 + 20;
            const benefitsHeight = payslip.shouldShowBenefits ? 60 : 0;
            const signatureHeight = 40;
            const totalExtraHeight = summaryHeight + benefitsHeight + signatureHeight;

            // Render table with reserved space
            doc.autoTable({
                startY: 60,
                head: [['Date', 'Client', 'Session Type', 'Service Fee']],
                body: tableData,
                theme: 'grid',
                headStyles: { fillColor: [52, 180, 226], textColor: [255, 255, 255] },
                styles: { fontSize: 10 },
                alternateRowStyles: { fillColor: [240, 240, 240] },
                willDrawPage: drawHeader,
                margin: { bottom: totalExtraHeight }
            });

            const finalY = doc.previousAutoTable.finalY;

            // If we're too close to bottom, add new page
            if (finalY > pageHeight - totalExtraHeight) {
                doc.addPage();
                drawHeader();
                doc.previousAutoTable.finalY = 60;
            }

            // Summary section
            const summaryY = doc.previousAutoTable.finalY + 15;
            doc.text('Summary:', 20, summaryY);
            Object.entries(sessionTypeCounts).forEach(([type, count], index) => {
                const text = type === 'Regular' ? `${count} ${type} Sessions` : `${count} ${type}`;
                doc.text(text, 30, summaryY + 10 + (index * 7));
            });

            // Benefits section
            let currentY = summaryY + summaryHeight;
            if (payslip.shouldShowBenefits) {
                doc.text('Monthly Benefits:', 20, currentY);
                doc.text('Government Contributions Allowance: PHP 2,000.00', 30, currentY + 7);
                doc.text('Educational Subsidy: PHP 500.00', 30, currentY + 14);
                doc.text(`Total Amount (with benefits): ${formatCurrencyPDF(payslip.totalWithBenefits)}`, 20, currentY + 28);
                currentY += benefitsHeight;
            }

            // Signatures section
            try {
                const signatureResponse = await fetch('roberto-signature.png');
                if (!signatureResponse.ok) throw new Error('Failed to load signature');
                
                const signatureBlob = await signatureResponse.blob();
                const signatureBase64 = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onloadend = () => resolve(reader.result);
                    reader.readAsDataURL(signatureBlob);
                });

                doc.addImage(signatureBase64, 'PNG', pageWidth - 80, currentY + 2, 60, 15);
            } catch (signatureError) {
                console.error('Signature error:', signatureError);
            }

            doc.setFontSize(10);
            doc.text('Received by:', 20, currentY);
            doc.line(20, currentY + 15, 80, currentY + 15);
            doc.text(therapist?.name || '', 20, currentY + 20);

            doc.text('Prepared by:', pageWidth - 80, currentY);
            doc.line(pageWidth - 80, currentY + 15, pageWidth - 20, currentY + 15);
            doc.text('Roberto A. Domingo', pageWidth - 80, currentY + 20);
            doc.text('Administrative Head', pageWidth - 80, currentY + 25);

            // Save PDF
            const startDate = new Date(payslip.startDate);
            const monthName = startDate.toLocaleString('default', { month: 'short' });
            const year = startDate.getFullYear();
            const periodRange = startDate.getDate() === 13 ? '13-26' : '27-12';
            
            doc.save(`${therapist?.name}-Payslip-${monthName}-${periodRange}-${year}.pdf`);

        } catch (error) {
            console.error('PDF Generation Error:', error);
            toast({
                title: 'Error generating PDF',
                description: error.message,
                status: 'error',
                duration: 5000
            });
        }
    };

    // Special function for PDF currency formatting
    const formatCurrencyPDF = (amount) => {
        if (amount === undefined || amount === null) return 'PHP 0.00';
        return `PHP ${Number(amount).toLocaleString('en-PH', { minimumFractionDigits: 2 })}`;
    };

    // Keep the original formatCurrency for web display
    const formatCurrency = (amount) => {
        if (amount === undefined || amount === null) return '₱0.00';
        return `₱${Number(amount).toLocaleString('en-PH', { minimumFractionDigits: 2 })}`;
    };

  const formatDateRange = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return `${start.toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric' 
    })} - ${end.toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric' 
    })}`;
  };

  if (isLoading) {
    return (
      <Center py={8}>
        <Spinner />
      </Center>
    );
  }

  return (
    <VStack spacing={4} align="stretch">
      {payslips.map((payslip) => (
        <Card key={payslip.startDate}>
          <CardBody>
          <HStack justify="space-between" 
            onClick={() => setExpandedPayslip(
                expandedPayslip === payslip.startDate ? null : payslip.startDate
            )}
            cursor="pointer"
            >
            <VStack align="start" spacing={0}>
                <Text fontWeight="bold">
                {formatDateRange(payslip.startDate, payslip.endDate)}
                </Text>
                <Badge colorScheme={payslip.isPaid ? 'green' : 'yellow'}>
                {payslip.isPaid ? 'Paid' : 'Pending'}
                </Badge>
            </VStack>
            <HStack>
                <Text fontWeight="bold">
                    {formatCurrency(payslip.isEligibleForBenefits ? payslip.totalWithBenefits : payslip.totalAmount)}
                </Text>
                <IconButton
                    icon={<DownloadIcon />}
                    aria-label="Download Payslip"
                    onClick={(e) => {
                    e.stopPropagation();
                    generatePayslipPDF(payslip);
                    }}
                    isDisabled={!payslip.isPaid}
                />
            </HStack>
          </HStack>

          <Collapse in={expandedPayslip === payslip.startDate}>
            <Box mt={4}>
                <Table size="sm">
                <Thead>
                    <Tr>
                    <Th>Date</Th>
                    <Th>Client</Th>
                    <Th>Session Type</Th>
                    <Th isNumeric>Service Fee</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {payslip.sessions.map((session) => (
                    <Tr key={session.id}>
                        <Td>{new Date(session.attendance_date).toLocaleDateString()}</Td>
                        <Td>{session.session.client.name}</Td>
                        <Td>{session.session_type}</Td>
                        <Td isNumeric>{formatCurrency(session.service_fee)}</Td>
                    </Tr>
                    ))}
                    <Tr fontWeight="bold">
                    <Td colSpan={3} textAlign="right">Total:</Td>
                    <Td isNumeric>{formatCurrency(payslip.totalAmount)}</Td>
                    </Tr>
                    {payslip.shouldShowBenefits && (
                    <>
                        <Tr>
                        <Td colSpan={3} textAlign="right">Government Contributions Allowance:</Td>
                        <Td isNumeric>{formatCurrency(2000)}</Td>
                        </Tr>
                        <Tr>
                        <Td colSpan={3} textAlign="right">Educational Subsidy:</Td>
                        <Td isNumeric>{formatCurrency(500)}</Td>
                        </Tr>
                        <Tr fontWeight="bold">
                        <Td colSpan={3} textAlign="right">Total with Benefits:</Td>
                        <Td isNumeric>{formatCurrency(payslip.totalWithBenefits)}</Td>
                        </Tr>
                    </>
                    )}
                </Tbody>
                </Table>
                {payslip.shouldShowBenefits && (
                <Text fontSize="sm" color="green.500" mt={2}>
                    Eligible for benefits ({payslip.totalHours} hours rendered)
                </Text>
                )}
            </Box>
          </Collapse>
          </CardBody>
        </Card>
      ))}
    </VStack>
  );
};

export default TherapistPayslips;