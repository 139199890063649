import React, { useState, useEffect } from "react";
import { Box, HStack, Heading, Button, Table, Thead, Tr, Th, Tbody, Modal, ModalOverlay, 
  ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useToast, 
  Center, Spinner, Td, VStack, FormControl, FormLabel, Input, Select, NumberInput, 
  NumberInputField, Textarea, IconButton, Text } from "@chakra-ui/react";
import { AddIcon, EditIcon } from "@chakra-ui/icons";
import supabase from "../supabaseClient";

const ExpensesManagement = () => {
  const [expenses, setExpenses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [receiptTypes, setReceiptTypes] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [totalExpenses, setTotalExpenses] = useState(0);
  const toast = useToast();

  const [formData, setFormData] = useState({
    purchase_date: new Date().toISOString().split('T')[0],
    expense_name: '',
    expense_type: '',
    amount: '',
    receipt_type: '',
    notes: ''
  });

  // Generate years (current year - 2 to current year + 1)
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 4 }, (_, i) => currentYear - 2 + i);

  // Generate periods
  const periods = [
    { label: "Dec 27 - Jan 26", startDay: 27, startMonth: 11, endDay: 26, endMonth: 0 },
    { label: "Jan 27 - Feb 26", startDay: 27, startMonth: 0, endDay: 26, endMonth: 1 },
    { label: "Feb 27 - Mar 26", startDay: 27, startMonth: 1, endDay: 26, endMonth: 2 },
    { label: "Mar 27 - Apr 26", startDay: 27, startMonth: 2, endDay: 26, endMonth: 3 },
    { label: "Apr 27 - May 26", startDay: 27, startMonth: 3, endDay: 26, endMonth: 4 },
    { label: "May 27 - Jun 26", startDay: 27, startMonth: 4, endDay: 26, endMonth: 5 },
    { label: "Jun 27 - Jul 26", startDay: 27, startMonth: 5, endDay: 26, endMonth: 6 },
    { label: "Jul 27 - Aug 26", startDay: 27, startMonth: 6, endDay: 26, endMonth: 7 },
    { label: "Aug 27 - Sep 26", startDay: 27, startMonth: 7, endDay: 26, endMonth: 8 },
    { label: "Sep 27 - Oct 26", startDay: 27, startMonth: 8, endDay: 26, endMonth: 9 },
    { label: "Oct 27 - Nov 26", startDay: 27, startMonth: 9, endDay: 26, endMonth: 10 },
    { label: "Nov 27 - Dec 26", startDay: 27, startMonth: 10, endDay: 26, endMonth: 11 }
  ];

  // Function to determine current period
  const getCurrentPeriod = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth();
    
    // If we're between the 27th and end of month, we're in next month's period
    if (day >= 27) {
      return month === 11 ? 0 : month + 1;
    }
    // If we're between 1st and 26th, we're in current month's period
    return month;
  };

  // Function to determine period year
  const getPeriodYear = (periodIndex) => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth();

    // If we're in December after the 27th, the period belongs to next year
    if (month === 11 && day >= 27) {
      return today.getFullYear() + 1;
    }
    return today.getFullYear();
  };

  useEffect(() => {
    const currentPeriodIndex = getCurrentPeriod();
    const periodYear = getPeriodYear(currentPeriodIndex);
    
    setSelectedYear(periodYear.toString());
    setSelectedPeriod(currentPeriodIndex.toString());
    
    fetchExpenseTypes();
    fetchReceiptTypes();
  }, []);

  useEffect(() => {
    if (selectedYear && selectedPeriod !== "") {
      fetchExpenses();
    }
  }, [selectedYear, selectedPeriod]);

  const fetchExpenses = async () => {
    try {
      setIsLoading(true);
      
      const period = periods[parseInt(selectedPeriod)];
      let startDate, endDate;

      // Handle December to January period
      if (period.startMonth === 11) { // December
        startDate = `${parseInt(selectedYear) - 1}-12-27`;
        endDate = `${selectedYear}-01-26`;
      } else {
        startDate = `${selectedYear}-${(period.startMonth + 1).toString().padStart(2, '0')}-${period.startDay}`;
        endDate = `${selectedYear}-${(period.endMonth + 1).toString().padStart(2, '0')}-${period.endDay}`;
      }

      const { data, error } = await supabase
        .from('expenses')
        .select('*')
        .gte('purchase_date', startDate)
        .lte('purchase_date', endDate)
        .order('purchase_date', { ascending: false });

      if (error) throw error;
      
      setExpenses(data);
      // Calculate total expenses
      const total = data.reduce((sum, expense) => sum + parseFloat(expense.amount), 0);
      setTotalExpenses(total);
      
    } catch (error) {
      toast({
        title: 'Error fetching expenses',
        description: error.message,
        status: 'error',
        duration: 5000
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchExpenseTypes = async () => {
    try {
      const { data, error } = await supabase
        .from('expense_types')
        .select('name')
        .order('name');

      if (error) throw error;
      setExpenseTypes(data.map(type => type.name));
    } catch (error) {
      console.error('Error fetching expense types:', error);
    }
  };

  const fetchReceiptTypes = async () => {
    try {
      const { data, error } = await supabase
        .from('receipt_types')
        .select('name')
        .order('name');

      if (error) throw error;
      setReceiptTypes(data.map(type => type.name));
    } catch (error) {
      console.error('Error fetching receipt types:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      if (selectedExpense) {
        const { error } = await supabase
          .from('expenses')
          .update(formData)
          .eq('id', selectedExpense.id);

        if (error) throw error;

        toast({
          title: 'Expense updated',
          status: 'success',
          duration: 3000
        });
      } else {
        const { error } = await supabase
          .from('expenses')
          .insert([formData]);

        if (error) throw error;

        toast({
          title: 'Expense added',
          status: 'success',
          duration: 3000
        });
      }

      setIsModalOpen(false);
      setSelectedExpense(null);
      setFormData({
        purchase_date: new Date().toISOString().split('T')[0],
        expense_name: '',
        expense_type: '',
        amount: '',
        receipt_type: '',
        notes: ''
      });
      fetchExpenses();
    } catch (error) {
      toast({
        title: 'Error saving expense',
        description: error.message,
        status: 'error',
        duration: 5000
      });
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-PH', {
      style: 'currency',
      currency: 'PHP'
    }).format(amount);
  };

  return (
    <Box>
      <VStack spacing={6} align="stretch">
        <HStack justify="space-between">
          <Heading size="md">Expenses</Heading>
          <Button 
            leftIcon={<AddIcon />} 
            colorScheme="blue" 
            onClick={() => {
              setSelectedExpense(null);
              setFormData({
                purchase_date: new Date().toISOString().split('T')[0],
                expense_name: '',
                expense_type: '',
                amount: '',
                receipt_type: '',
                notes: ''
              });
              setIsModalOpen(true);
            }}
          >
            Add Expense
          </Button>
        </HStack>

        <HStack spacing={4} align="flex-end">
          <FormControl width="200px">
            <FormLabel>Year</FormLabel>
            <Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              {years.map(year => (
                <option key={year} value={year}>{year}</option>
              ))}
            </Select>
          </FormControl>

          <FormControl width="200px">
            <FormLabel>Period</FormLabel>
            <Select
              value={selectedPeriod}
              onChange={(e) => setSelectedPeriod(e.target.value)}
            >
              {periods.map((period, index) => (
                <option key={index} value={index}>{period.label}</option>
              ))}
            </Select>
          </FormControl>

          <Text fontSize="lg" fontWeight="bold">
            Total: {formatCurrency(totalExpenses)}
          </Text>
        </HStack>

        {isLoading ? (
          <Center py={8}>
            <Spinner />
          </Center>
        ) : (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Name</Th>
                <Th>Type</Th>
                <Th isNumeric>Amount</Th>
                <Th>Receipt Type</Th>
                <Th>Notes</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {expenses.map(expense => (
                <Tr key={expense.id}>
                  <Td>{new Date(expense.purchase_date).toLocaleDateString()}</Td>
                  <Td>{expense.expense_name}</Td>
                  <Td>{expense.expense_type}</Td>
                  <Td isNumeric>{formatCurrency(expense.amount)}</Td>
                  <Td>{expense.receipt_type}</Td>
                  <Td>{expense.notes}</Td>
                  <Td>
                    <IconButton
                      icon={<EditIcon />}
                      size="sm"
                      onClick={() => {
                        setSelectedExpense(expense);
                        setFormData({
                          purchase_date: expense.purchase_date,
                          expense_name: expense.expense_name,
                          expense_type: expense.expense_type,
                          amount: expense.amount,
                          receipt_type: expense.receipt_type,
                          notes: expense.notes || ''
                        });
                        setIsModalOpen(true);
                      }}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </VStack>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedExpense ? 'Edit Expense' : 'Add Expense'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Date</FormLabel>
                <Input
                  type="date"
                  value={formData.purchase_date}
                  onChange={(e) => setFormData({ ...formData, purchase_date: e.target.value })}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  value={formData.expense_name}
                  onChange={(e) => setFormData({ ...formData, expense_name: e.target.value })}
                />
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Type</FormLabel>
                <Select
                  value={formData.expense_type}
                  onChange={(e) => setFormData({ ...formData, expense_type: e.target.value })}
                >
                  <option value="">Select Type</option>
                  {expenseTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Amount</FormLabel>
                <NumberInput
                  value={formData.amount}
                  onChange={(valueString) => setFormData({ ...formData, amount: valueString })}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Receipt Type</FormLabel>
                <Select
                  value={formData.receipt_type}
                  onChange={(e) => setFormData({ ...formData, receipt_type: e.target.value })}
                >
                  <option value="">Select Receipt Type</option>
                  {receiptTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Notes</FormLabel>
                <Textarea
                  value={formData.notes}
                  onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              {selectedExpense ? 'Update' : 'Save'}
            </Button>
            <Button variant="ghost" onClick={() => setIsModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ExpensesManagement;