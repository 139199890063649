import React, { useState } from 'react';
import { 
  Box, 
  Container, 
  Tab, 
  TabList, 
  TabPanel, 
  TabPanels, 
  Tabs,
  Spinner,
  Center,
  VStack,
  Text
} from '@chakra-ui/react';
import WeeklySchedule from '../components/WeeklySchedule';
import ClientManagement from '../components/ClientManagement';
import DailySchedule from '../components/DailySchedule';
import TherapistManagement from '../components/TherapistManagement';
import FinancialManagement from '../components/FinancialManagement';

const LoadingOverlay = () => (
  <Center height="100%" width="100%">
    <VStack spacing={4}>
      <Spinner 
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
      <Text color="gray.500">Loading...</Text>
    </VStack>
  </Center>
);

const OwnerDashboard = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabChange = (index) => {
    setSelectedTabIndex(index);
  };

  return (
    <Container maxW="1200px" py={8}>
      <Tabs 
        isFitted 
        variant="enclosed" 
        index={selectedTabIndex} 
        onChange={handleTabChange}
      >
        <TabList mb="1em">
          <Tab>Daily Schedule</Tab>
          <Tab>Weekly Schedule</Tab>
          <Tab>Client Management</Tab>
          <Tab>Therapist Management</Tab>
          <Tab>Financial Management</Tab>
        </TabList>

        <TabPanels>
          <TabPanel position="relative" minHeight="500px">
            <DailySchedule 
              key={`daily-schedule-${selectedTabIndex}`}
            />
          </TabPanel>
          <TabPanel position="relative" minHeight="500px">
            <WeeklySchedule 
              key={`weekly-schedule-${selectedTabIndex}`}
              LoadingComponent={LoadingOverlay}
            />
          </TabPanel>
          <TabPanel position="relative" minHeight="500px">
            <ClientManagement 
              key={`client-management-${selectedTabIndex}`}
              LoadingComponent={LoadingOverlay}
            />
          </TabPanel>
          <TabPanel position="relative" minHeight="500px">
          <TherapistManagement 
            key={`therapist-management-${selectedTabIndex}`}
            LoadingComponent={LoadingOverlay}
          />
          </TabPanel>
          <TabPanel position="relative" minHeight="500px">
            <FinancialManagement 
              key={`financial-management-${selectedTabIndex}`}
              LoadingComponent={LoadingOverlay}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};

export default OwnerDashboard;